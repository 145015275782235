import axios from "axios";
import {
    createNewDocument,
    deleteDocument, getCategoryDocuments, getDocument,
    getDocumentByMonth,
    updateDocument
} from "../utils/ConstStrings";
import {MyCommunityCategories} from "../utils/MyCommunityCategories";

export const getDocumentsAPI = async (category:string) => {

    const token = localStorage.getItem('authToken')

    if (!token) return console.error('Token not found')

    const headers = {
        't-ownerstally': token ?? ''
    }

    try {
        const res = await axios.get(getCategoryDocuments + category + '&limit=12', {headers})
        return res.data
    } catch (e: any) {
        console.error(e)
    }
}

export const getDocumentAPI = async (documentID: string) => {

    const token = localStorage.getItem('authToken')

    if (!token) return console.error('Token not found')

    const headers = {
        't-ownerstally': token ?? ''
    }

    try {
        const res = await axios.get(getDocument + documentID, {headers})
        return res.data
    } catch (e: any) {
        console.error(e)
    }
}

export const createNewDocumentAPI = async (title: string, category: MyCommunityCategories, json: any) => {

    // Create new document record on db
    try {

        const token = localStorage.getItem('authToken')

        const headers = {
            't-ownerstally': token ?? ''
        }

        const documentData = {
            'title': title,
            'category': category,
            'json': json,
        }

        const res = await axios.post(createNewDocument, documentData, {headers})

        return res.data

    } catch (e: any) {
        return console.error(e)
    }
}

export const updateDocumentAPI = async (documentID: number, title: string, json: any, fileNames: string) => {

    // Update document on db
    try {

        const token = localStorage.getItem('authToken')

        const headers = {
            't-ownerstally': token ?? ''
        }

        const documentData = {
            'title': title,
            'json': json,
            'filename': fileNames ?? '',
        }

        const res = await axios.put(updateDocument + documentID, documentData, {headers})
        return res.data

    } catch (e: any) {
        console.error(e)
    }

}

export const deleteDocumentAPI = async (documentID: number) => {

    const token = localStorage.getItem('authToken')

    const headers = {
        't-ownerstally': token ?? ''
    }

    try {
        const res = await axios.delete(deleteDocument + documentID, {headers})
        return res.data

    } catch (e: any) {
        console.error(e)
    }

}

export const getDocumentsByMonth = async (month:string, page:number = 1) =>{

    const token = localStorage.getItem('authToken')

    const headers = {
        't-ownerstally': token ?? ''
    }

    try {
        const res = await axios.get(getDocumentByMonth + month + "&page=" + page + "&limit=30" , {headers})
        return res.data

    } catch (e: any) {
        console.error(e)
    }

}