import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface MenuState {
    value: number
}

const initialState: MenuState = {
    value: 1,
}

export const BottomBarMenuSlice = createSlice({
    name: 'current_menu_index',
    initialState,
    reducers: {
        selectCurrentIndex: (state, action: PayloadAction<number>) => {
            state.value = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { selectCurrentIndex} = BottomBarMenuSlice.actions

export default BottomBarMenuSlice.reducer