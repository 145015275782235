import React from 'react';
import {useNavigate} from "react-router-dom";

function NewVehicle() {

    const navigate = useNavigate()

    const handleOnSaveClick = () => {
        console.log("onSaveClick")
    }

    return (
        <div>

            {/*MOBILE*/}
            <div className="block lg:hidden px-5 mt-5">

                {/*TOOLBAR*/}
                <div className="flex justify-between items-center">
                    <button onClick={() => navigate(-1)} className="font-medium text-[#A8A8A8]">Cancel</button>
                    <h2 className="font-semibold">new vehicle</h2>
                    <button onClick={ ()=> handleOnSaveClick() } className="text-[#1D47B1] font-medium">Save</button>
                </div>

                {/*CONTENT*/}
                <div className="text-start">
                    <div className=" mt-5 flex flex-col space-y-2">
                        <label className="text-sm">Tag</label>

                        <input type="text"
                               className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                               placeholder="Vehicle licence plate"/>
                        <span className="text-sm text-gray-400">*If you exceed registration for the community, you are in violation and vehicle may be immobilized </span>
                    </div>

                    <div className="mt-5 flex flex-col space-y-2">
                        <label className="text-sm">Color</label>

                        <input type="text"
                               className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                               placeholder="Vehicle color"/>
                    </div>

                    <div className="mt-5 flex flex-col space-y-2">
                        <label className="text-sm">Make</label>

                        <input type="text"
                               className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                               placeholder="Vehicle make"/>
                    </div>


                    <div className="mt-5 flex flex-col space-y-2">
                        <label className="text-sm">Model</label>

                        <input type="text"
                               className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                               placeholder="Vehicle model"/>
                    </div>

                </div>

            </div>

            {/*DESKTOP*/}
            <div className="hidden lg:block 2xl:container mx-auto">
                <div className="w-1/2 mx-auto border rounded-lg my-5 p-10">
                    {/*CONTENT*/}
                    <div className="text-start">
                        <div className=" flex flex-col space-y-2">
                            <label className="text-sm">Tag</label>

                            <input type="text"
                                   className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                   placeholder="Vehicle licence plate"/>
                            <span className="text-sm text-gray-400">*If you exceed registration for the community, you are in violation and vehicle may be immobilized </span>
                        </div>

                        <div className="mt-5 flex flex-col space-y-2">
                            <label className="text-sm">Color</label>

                            <input type="text"
                                   className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                   placeholder="Vehicle color"/>
                        </div>

                        <div className="mt-5 flex flex-col space-y-2">
                            <label className="text-sm">Make</label>

                            <input type="text"
                                   className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                   placeholder="Vehicle make"/>
                        </div>


                        <div className="mt-5 flex flex-col space-y-2">
                            <label className="text-sm">Model</label>

                            <input type="text"
                                   className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                   placeholder="Vehicle model"/>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default NewVehicle;