import axios from "axios";

type Coordinates = {
    lat: number;
    lng: number;
};



export const fetchCommunityCoordinates = async (address: string) => {

    try {
        const formattedAddress = encodeURI(address)
        return  await axios.get("https://api.mapbox.com/geocoding/v5/mapbox.places/" + formattedAddress + ".json?proximity=ip&access_token=" + process.env.REACT_APP_MAPS_BOX_KEY)

    } catch (error) {
        console.error('Error al obtener las coordenadas:', error);
        return null;
    }
};