import React, {useContext} from 'react';
import logo from "../../assets/icons/logo_colored.svg";
import {Link, useNavigate} from "react-router-dom";
import AuthContextProvider,{ UserContextType} from "../../providers/AuthContextProvider";


function Navbar() {

    const navigate = useNavigate()
    const {auth} = useContext(AuthContextProvider) as UserContextType

    return (
        <div className="hidden lg:block bg-white rounded-b-[30px] h-16 z-50 relative">

            <div className="w-full h-full px-10 flex flex-row justify-between items-center">

               <div className="flex flex-row justify-evenly space-x-10 items-center">

                   <picture className="flex justify-start items-start">
                       <img src={logo} className="w-36" alt="OwnersTally"/>
                   </picture>

                   <div className="flex flex-row lg:space-x-5 xl:space-x-10 pt-3">

                       <Link to={"/main/announcements"} className="text-[#1D47B1] uppercase">announcement</Link>

                       <Link to={"/main/my-community"} className="text-[#1D47B1] uppercase">my community</Link>

                       <Link to={"/main/my-home"} className="text-[#1D47B1] uppercase">my home</Link>

                       <Link to={"/main/mail"} className="text-[#1D47B1] uppercase">mail</Link>

                   </div>

               </div>

                <div className="flex flex-row space-x-10">

                    <input className="rounded-full lg:w-60 xl:w-96 bg-[#F3F3F3] px-5 py-2 text-[#A8A8A8]"
                            placeholder="Search..."
                    />

                    <button onClick={ ()=> navigate("/edit-profile/" + "john-doe") }>
                        <img src={auth.avatar}
                             className="h-10 w-10 rounded-full"
                             alt={auth.name}/>
                    </button>

                </div>

            </div>
        </div>
    );
}

export default Navbar;