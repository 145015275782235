import React, {useEffect, useState} from 'react';
import Navbar from "../../../components/navbar/Navbar";
import {useNavigate} from "react-router-dom";

function NewAnnouncement() {

    const navigate = useNavigate()

    const [rows, setRows] = useState(10);
    const [title, setTitle] = useState("");
    const [announcements, setAnnouncements] = useState("")

    useEffect(() => {

        const rowLength = announcements.split("\n");

        if (rowLength.length > 10) {
            setRows(rowLength.length);
        } else {
            setRows(10);
        }
    }, [announcements]);

    return (
        <div className="pb-10 2xl:container mx-auto">

            <Navbar/>

            <div className="w-full lg:w-1/2 mx-auto lg:border lg:rounded-lg lg:mt-5">

                <div className="block lg:hidden bg-white h-14 flex flex-row justify-between px-5">
                    <button onClick={()=> navigate(-1)} className="text-[#A8A8A8]">Cancel</button>
                    <button className="text-[#1D47B1]">Post</button>
                </div>

                <div className="flex flex-row justify-between items-center px-5 py-3">

                    <div className="flex flex-row space-x-4">

                        <img src="https://i.pravatar.cc/150?img=68"
                             className="w-12 h-12 rounded-full"
                             alt="User Name"/>

                        <div className="flex flex-col text-start">
                            <h5 className="text-[#3C3C43] font-semibold">Frank Thompson</h5>
                            <span className="text-[#3C3C43] text-sm">Position</span>
                        </div>

                    </div>

                    <div className="flex flex-row items-center space-x-8">

                        <button>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6 text-[#1D47B1]">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"/>
                            </svg>
                        </button>

                        <button className="font-semibold text-[#A8A8A8] hidden lg:block">
                            Cancel
                        </button>

                        <button className="font-semibold text-[#1D47B1] hidden lg:block">
                            Post
                        </button>

                    </div>

                </div>

                <div className='flex flex-grow bg-gray-100 h-0.5 my-2 mt-5'/>

                <div>
                    <input type="text" className="w-full px-5 py-1 outline-none text-[#3C3C43]"
                           value={title}
                           onChange={(e: any) => setTitle(e.target.value)}
                           placeholder="Write a title for your announcement"
                           maxLength={65}/>
                </div>

                <div className='flex flex-grow bg-gray-100 h-0.5 my-2 mt-5'/>

                <textarea
                    className="px-5 py-1 w-full outline-none font-normal text-[#3C3C43] resize-none h-full"
                    value={announcements}
                    placeholder="Write an announcement"
                    onChange={(e: any) => setAnnouncements(e.target.value)}
                    rows={rows}
                ></textarea>

            </div>

        </div>

    );
}

export default NewAnnouncement;