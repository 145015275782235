import React from 'react';

function Linkedin(
) {
    return (
        <>
            <svg width="53" height="53" className="rounded-lg" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0V52.39H52.39V0H0ZM16.94 41.31H10.4V20.26H16.94V41.31ZM13.63 17.68C11.56 17.68 10.19 16.22 10.23 14.41C10.19 12.52 11.56 11.1 13.67 11.1C15.78 11.1 17.11 12.52 17.16 14.41C17.16 16.22 15.78 17.68 13.63 17.68ZM42.17 41.31H35.63V29.64C35.63 26.93 34.68 25.08 32.32 25.08C30.51 25.08 29.44 26.33 29.01 27.53C28.84 27.92 28.75 28.56 28.75 29.17V41.31H22.21V26.97C22.21 24.34 22.12 22.11 22.04 20.25H27.72L28.02 23.13H28.15C29.01 21.8 31.16 19.77 34.65 19.77C38.95 19.77 42.18 22.61 42.18 28.81V41.29L42.17 41.31Z" fill="#D9D9D9"/>
                <path d="M0 0V52.39H52.39V0H0ZM16.94 41.31H10.4V20.26H16.94V41.31ZM13.63 17.68C11.56 17.68 10.19 16.22 10.23 14.41C10.19 12.52 11.56 11.1 13.67 11.1C15.78 11.1 17.11 12.52 17.16 14.41C17.16 16.22 15.78 17.68 13.63 17.68ZM42.17 41.31H35.63V29.64C35.63 26.93 34.68 25.08 32.32 25.08C30.51 25.08 29.44 26.33 29.01 27.53C28.84 27.92 28.75 28.56 28.75 29.17V41.31H22.21V26.97C22.21 24.34 22.12 22.11 22.04 20.25H27.72L28.02 23.13H28.15C29.01 21.8 31.16 19.77 34.65 19.77C38.95 19.77 42.18 22.61 42.18 28.81V41.29L42.17 41.31Z" fill="#1D47B1"/>
            </svg>

        </>
    );
}

export default Linkedin;