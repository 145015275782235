import React from 'react';
import {useNavigate} from "react-router-dom";

function AnnouncementItemMobile() {

    const navigate = useNavigate()

    const handleOnAnnouncementClick = () => {
        navigate("/announcement/asd")
    }

    return (
        <button onClick={() => handleOnAnnouncementClick() } className="block lg:hidden flex w-full flex-row space-x-4 py-4">

            <img
                src="https://i.pravatar.cc/150?img=68"
                className="w-12 h-12 rounded-full"
                alt="User Name"/>

            <div className="flex w-full flex-col items-start text-start">

                <div className="flex flex-row w-full justify-between items-center">
                    <h5 className="text-sm font-light">John Doe</h5>
                    <span className="text-sm font-light">6:30 a.m</span>
                </div>

                <p className="text-sm font-light">Subject of the email</p>
                <p className="text-[#707070] text-sm font-light">Lorem ipsum dolor sit amet, consr elit...</p>
            </div>

        </button>
    );
}

export default AnnouncementItemMobile;