import React from 'react';
import {useNavigate} from "react-router-dom";

type Props = {
    route: string
}

function MeetingTemplateItem(props: Props) {

    const navigate  = useNavigate()

    return (
        <button onClick={()=>navigate(props.route)} className="w-auto p-3 border rounded-lg hover:bg-gray-100 hover:cursor-pointer">

            <div className="w-auto flex flex-col items-center">

                <div className="flex flex-col space-y-0.5 items-center">

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-[#1D47B1]">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"/>
                    </svg>

                    <span className="font-semibold text-sm">New</span>

                </div>

                <div className="h-0.5 border w-full"/>

                <span className="text-sm text-[#3C3C43]">create doc</span>

            </div>

        </button>
    );
}

export default MeetingTemplateItem;