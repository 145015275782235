import React, {useContext} from 'react';
import LogoSmall from '../../assets/icons/logo_small.svg'
import {useNavigate} from "react-router-dom";
import AuthContextProvider,{ UserContextType} from "../../providers/AuthContextProvider";

interface ToolbarTypes{
    title:string,
}

function Toolbar(props:ToolbarTypes) {

    const navigate = useNavigate()

    const {auth} = useContext(AuthContextProvider) as UserContextType

    return (
        <div className="block lg:hidden fixed inset-x-0 z-40 top-0 bg-white h-16 rounded-b-[10px] flex flex-row  justify-center px-3 items-center">


            <div className="absolute left-0 px-3">
                <img
                    src={LogoSmall}
                    className="w-12 h-12 rounded-full object-cover object-center"
                    alt="Owners Tally"/>
            </div>

            <div className="flex justify-center items-center flex-1">
                <h1 className="text-lg font-semibold text-[#1D47B1]">{props.title}</h1>
            </div>

            <button onClick={ ()=> navigate("/edit-profile/" + "john-doe") } className="absolute right-0 px-3">
                <img
                    src={auth.avatar}
                    className="w-10 h-10 rounded-full object-cover object-center"
                    alt={auth.name}/>
            </button>

        </div>
    );
}

export default Toolbar;