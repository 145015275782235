import React from 'react';
import {useNavigate} from "react-router-dom";

type DocumentProps = {
    id: number,
    title: string,
    created_at: string,
    route: string,
    monthName?:string,
}

function DocumentItem(props: DocumentProps) {

    const navigate = useNavigate()

    const openDocument = () => {
        navigate(props.route + props.id, {
            state: {
                documentID: props.id,
                monthName:props.monthName
            }
        })
    }

    return (
        <button onClick={() => openDocument()}
                className="w-full border rounded-lg space-x-3 flex flex-row  items-center text-start hover:bg-gray-100 hover:cursor-pointer">

            <div className="border rounded-lg p-5">

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="w-8 h-8 text-[#1D47B1]">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"/>
                </svg>

            </div>

            <div className="flex flex-col ">
                <h5 className="text-sm font-semibold line-clamp-1 mr-3">{props.title}</h5>
                <span className="text-sm text-[#3C3C43]">{props.created_at}</span>
            </div>

        </button>
    );
}

export default DocumentItem;