import React from 'react';
import Toolbar from "../../../components/toolbar/Toolbar";
import AnnouncementItemMobile from "../../../components/main-pages/announcements/AnnouncementItemMobile";
import AnnouncementItem from "../../../components/main-pages/announcements/AnnouncementItem";
import {useNavigate} from "react-router-dom";
import PlusCircleIcon from "../../../assets/svg/announcements/PlusCircleIcon";

function AnnouncementsPage() {

    const navigate = useNavigate()

    const handleNewAnnouncement = () => {
        navigate("/new-announcement")
    }

    return (
        <div className="2xl:container mx-auto mx-auto w-full lg:w-4/5">

            <Toolbar title="Announcement"/>

            <div className="lg:mt-16 rounded-t bg-[#EFEFEF] pt-5 lg:pt-0 flex flex-col lg:flex-row justify-between">

                <div className="flex justify-center items-center lg:invisible">
                    <input type="text" placeholder="Search..."
                           className="w-full mx-5 px-5 py-2 rounded-full bg-[#FFFFFF] outline-none"/>
                </div>

                <div className="px-5 pt-3 lg:pt-5 space-x-5 flex flex-row ">

                    <div className="relative -bottom-1 px-2 border-b-4 border-b-amber-600 rounded-b-[4px] hover:cursor-pointer">
                        <span className="text-[#707070] text-sm">All</span>
                    </div>

                    <div
                        className="relative -bottom-1 px-2 border-b-4  border-b-transparent hover:border-b-amber-600  hover:cursor-pointer rounded-b-[4px]">
                        <span className="text-[#707070] text-sm">Unread</span>
                    </div>

                </div>
            </div>

            <div className="w-full bg-[#CCDDE2] text-start hidden lg:block">


                <button onClick={() => handleNewAnnouncement()} className="py-2.5 text-[#1D47B1] px-12 font-semibold text-sm">
                    <div className="flex flex-row space-x-2">
                        <PlusCircleIcon/>
                        <span>Create Ad</span>
                    </div>
                </button>

            </div>

            <div className="bg-white lg:px-10 min-h-screen">

                <div className="pt-2 flex w-full flex-col px-4 lg:px-0">

                    <AnnouncementItemMobile/>

                    <AnnouncementItemMobile/>

                    <AnnouncementItemMobile/>


                    <AnnouncementItem/>

                    <AnnouncementItem/>

                    <AnnouncementItem/>

                </div>


            </div>


        </div>
    );
}

export default AnnouncementsPage;