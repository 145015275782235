interface JsonItem {
    id: string;
    type: string;
    data: {
        [key: string]: any;
    };
}

export const extractFilenamesFromJson = (json: JsonItem[]): string[] => {

    const filenames: string[] = [];

    for (const item of json) {
        if (
            item.data &&
            item.data.file &&
            item.data.file.url
        ) {
            const url = item.data.file.url;
            const extension = url.split('.').pop() || '';
            const name = url.split('/').slice(-1)[0].split('.')[0];
            filenames.push(name + "." + extension);
        }
    }

    return filenames;
};