import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {HiMiniBars3} from "react-icons/hi2";
import {HiOutlineHome} from "react-icons/hi";

function AdminCommunityNavBar() {

    const navigate = useNavigate()

    const [showSidebar, setShowSidebar] = useState<boolean>(false)
    const sideBarRef = useRef<any>(null)


    useEffect(() => {
        // only add the event listener when the dropdown is opened
        if (!showSidebar) return;


        function handleClick(event: any) {

            if (event.target.id === 'drawer-navigation' || event.target.id === 'drawer-navigation-label' || event.target.id === 'navigation-item') {
                return
            }

            if (sideBarRef.current && !sideBarRef.current.contains(event.target)) {
                handleSidebarStatus()
            }
        }

        window.addEventListener("click", handleClick);
        // clean up
        return () => window.removeEventListener("click", handleClick);
    }, [showSidebar]);

    const handleSidebarStatus = () => {
        setShowSidebar(!showSidebar)
    }

    return (
        <div>

            <div className="bg-dark-blue h-12 w-full overflow-x-auto flex flex-row space-x-2 lg:space-x-4">

                <button ref={sideBarRef} onClick={() => handleSidebarStatus()} className="flex flex-row space-x-2 px-3 py-2 hover:bg-primary/30 transition text-white items-center">
                    <HiMiniBars3 size={25}/>
                    <span className="text-sm font-normal lg:font-semibold">Tools</span>
                </button>

                <button onClick={() => navigate('/main/my-community')} className="flex flex-row space-x-2 px-3 py-2 hover:bg-primary/30 transition text-white items-center">
                    <HiOutlineHome size={25}/>
                    <span className="text-sm font-normal lg:font-semibold">Go My Community</span>
                </button>

            </div>

            {/*   <!-- drawer component --> */}
            <div id="drawer-navigation"
                 className={showSidebar ? "fixed top-0 left-0 z-40 w-full lg:w-96 h-screen p-4 overflow-y-auto transition-transform bg-dark-blue" : "fixed top-0 left-0 z-40 w-full lg:w-96 h-screen p-4 overflow-y-auto transition-transform bg-dark-blue -translate-x-full"}
                 tabIndex={-1} aria-labelledby="drawer-navigation-label">

                <h5 id="drawer-navigation-label" className="text-base uppercase text-gray-400">MANAGEMENT TOOLS</h5>

                <button onClick={() => handleSidebarStatus()} type="button" data-drawer-hide="drawer-navigation"
                        aria-controls="drawer-navigation"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"></path>
                    </svg>
                    <span className="sr-only">Close menu</span>
                </button>

                <div id="navigation-item" className="mt-5 w-full bg-secondary h-0.5"/>

                <div className="py-4 overflow-y-auto text-sm">

                    <ul className="space-y-2">

                        <li>
                            <button onClick={() => navigate("members")} className="flex items-center p-2 rounded hover:bg-gradiant-start w-full text-start text-gray-300 group">
                                <span className="flex-1 whitespace-nowrap">Members</span>
                            </button>
                        </li>

                    </ul>

                </div>

            </div>


            {showSidebar && <div className="fixed top-0 left-0 z-10 w-full h-screen overflow-hidden bg-black/60"/>}

        </div>
    );
}

export default AdminCommunityNavBar;