import React from 'react';
import Navbar from "../../../components/navbar/Navbar";
import FileAttachment from "../../../components/attachment/FileAttachment";
import {useNavigate} from "react-router-dom";

function AnnouncementDetails() {

    const navigate = useNavigate()

    return (
        <div className="pb-10 2xl:container mx-auto">

            <Navbar/>

            <div className="w-full lg:w-1/2 mx-auto lg:border lg:rounded-lg lg:mt-5">

                <div className="block lg:hidden bg-white h-14 flex flex-row justify-between px-5">
                    <button onClick={() => navigate(-1)} className="text-[#A8A8A8]">Cancel</button>
                    <button className="text-[#1D47B1]">Edit</button>
                </div>

                <div className="flex flex-row justify-between items-center px-5 py-3">

                    <div className="flex flex-row space-x-4">

                        <img src="https://i.pravatar.cc/150?img=68"
                             className="w-12 h-12 rounded-full"
                             alt="User Name"/>

                        <div className="flex flex-col text-start">
                            <h5 className="text-[#3C3C43] font-semibold">Frank Thompson</h5>
                            <span className="text-[#3C3C43] text-sm">Position</span>
                        </div>

                    </div>

                    <div className="flex flex-row items-center space-x-8 hidden lg:block">

                        <button className="font-semibold text-[#1D47B1]">
                            Edit
                        </button>

                    </div>

                </div>

                <div className='w-full border mb-2'/>

                <div>
                    <h2 className="text-start px-5 font-semibold text-lg text-[#3C3C43]">
                        Elon Musk, Plus a circle of confidents, Tightens over Twitter
                    </h2>
                </div>


                <p className="text-start text-sm px-5 py-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliqui it in Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ujij ojojo njn
                    iijoi onjnk exercitation ullamco laboris nisi ut aliqui it in Lorem ipsum dolor, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui it
                    in Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ujij ojojo njn iijoi onjnk
                    exercitation ullamco laboris nisi ut aliqui it in Lorem ipsum dolor, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>

                <div className="grid grid-cols-1 gap-4 px-5 py-5">
                    <img className="rounded-lg object-center object-cover"
                         src="https://images.unsplash.com/photo-1570129477492-45c003edd2be?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNhJTIwaG91c2V8ZW58MHx8MHx8&w=1000&q=80"
                         alt="House USA Picture"
                    />
                </div>

                <div className="px-5 pb-5">

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <FileAttachment/>
                        <FileAttachment/>
                        <FileAttachment/>
                        <FileAttachment/>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default AnnouncementDetails;