import React from 'react';
import logoColored from '../../assets/icons/logo_colored.svg';

function Footer() {
    return (
        <footer>
            <div className="bg-white py-10">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5">

                    <div className="flex flex-col justify-center items-center gap-y-2">

                        <img className=" w-[190px]" src={logoColored} alt="OwnersTally Logo"/>

                        <p className="text-sm font-light text-dark-blue">© All rights reserved</p>

                    </div>

                    <div className="flex flex-col gap-y-2">
                        <a href="#" className="text-dark-blue hover:underline">About us</a>
                        <a href="#" className="text-dark-blue hover:underline">Careers</a>
                    </div>

                    <div className="flex flex-col gap-y-2">
                        <a href="#" className="text-dark-blue hover:underline">Privacy policy</a>
                        <a href="#" className="text-dark-blue hover:underline">Site map</a>
                    </div>

                    <div className="flex flex-col gap-y-2">
                        <a href="#" className="text-dark-blue hover:underline">Terms of use</a>
                        <a href="#" className="text-dark-blue hover:underline">Security</a>
                    </div>

                    <div className="flex flex-col gap-y-2">
                        <a href="#" className="text-dark-blue hover:underline">Website Terms of Use</a>
                        <a href="#" className="text-dark-blue hover:underline">Community Software</a>
                    </div>


                </div>
            </div>
        </footer>
    );
}

export default Footer;