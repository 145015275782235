import React, {useEffect, useState} from 'react';
import {CgSpinner} from "react-icons/cg";
import CommunityIcon from '../../../assets/svg/committees/committees.svg'
import {useLocation} from "react-router-dom";

function CreateCommunityPage() {

    const location = useLocation()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [communityName, setCommunityName] = useState<string>('')


    useEffect(()=>{
        if (location.state == undefined) return
        setCommunityName(location.state.communityName)
    },[])

    const handleCreateCommunity = async (e: any) => {
        e.preventDefault()

        //TODO: VALIDATE ALL INPUTS AND CREATE COMMUNITY
        console.log("Creating community")
    }

    return (
        <div className="w-full mx-auto px-5 py-5 lg:w-1/2">


            <div className="flex justify-center items-center">
                <div className="w-28 h-28 flex justify-center items-center rounded-full bg-gray-100">
                    <img className="w-14 h-14" src={CommunityIcon} alt="Community Icon"/>
                </div>
            </div>

            <h1 className="mt-5 text-3xl font-semibold">Create new community</h1>

            <div className="mt-10">

                <form className="flex flex-col space-y-3" onSubmit={(e) => e.preventDefault()}>
                    <div className="w-full">
                        <label className="sr-only">Community Name</label>
                        <input type="text" className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="Community name"
                               value={communityName}
                               disabled={isLoading}
                               onChange={(e: any) => setCommunityName(e.target.value)}
                        />
                    </div>


                    <div className="w-full">
                        <label className="sr-only">Licence Type</label>
                        <input type="text" className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="Licence Type"
                               value={communityName}
                               disabled={isLoading}
                               onChange={(e: any) => setCommunityName(e.target.value)}
                        />
                    </div>

                    <div className="w-full">
                        <label className="sr-only">Licence Number</label>
                        <input type="text" className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="Licence Number"
                               value={communityName}
                               disabled={isLoading}
                               onChange={(e: any) => setCommunityName(e.target.value)}
                        />
                    </div>

                    <div className="w-full">
                        <label className="sr-only">FEI Number</label>
                        <input type="text" className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="FEI Number"
                               value={communityName}
                               disabled={isLoading}
                               onChange={(e: any) => setCommunityName(e.target.value)}
                        />
                    </div>

                    <div className="w-full">
                        <label className="sr-only">Corporation Legal Name</label>
                        <input type="text" className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="Corporation Legal Name"
                               value={communityName}
                               disabled={isLoading}
                               onChange={(e: any) => setCommunityName(e.target.value)}
                        />
                    </div>


                    <div className="w-full">
                        <label className="sr-only">Address</label>
                        <input type="text" className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="Address"
                               value={communityName}
                               disabled={isLoading}
                               onChange={(e: any) => setCommunityName(e.target.value)}
                        />
                    </div>

                    <div className="w-full">
                        <label className="sr-only">Address Alt</label>
                        <input type="text" className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="Address Alt"
                               value={communityName}
                               disabled={isLoading}
                               onChange={(e: any) => setCommunityName(e.target.value)}
                        />
                    </div>

                    <div className="w-full">
                        <label className="sr-only">Zip Code</label>
                        <input type="text" className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="Zip Code"
                               value={communityName}
                               disabled={isLoading}
                               onChange={(e: any) => setCommunityName(e.target.value)}
                        />
                    </div>

                    <div className="w-full">
                        <label className="sr-only">City</label>
                        <input type="text" className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="City"
                               value={communityName}
                               disabled={isLoading}
                               onChange={(e: any) => setCommunityName(e.target.value)}
                        />
                    </div>

                    <div className="w-full">
                        <label className="sr-only">Country</label>
                        <input type="text" className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="Country"
                               value={communityName}
                               disabled={isLoading}
                               onChange={(e: any) => setCommunityName(e.target.value)}
                        />
                    </div>

                    <div className="w-full">
                        <label className="sr-only">State</label>
                        <input type="text" className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="State"
                               value={communityName}
                               disabled={isLoading}
                               onChange={(e: any) => setCommunityName(e.target.value)}
                        />
                    </div>

                    <div className="w-full">
                        <label className="sr-only">Latitude</label>
                        <input type="text" className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="Latitude"
                               value={communityName}
                               disabled={isLoading}
                               onChange={(e: any) => setCommunityName(e.target.value)}
                        />
                    </div>

                    <button onClick={(e: any) => handleCreateCommunity(e)} disabled={isLoading}
                            className={isLoading ? 'w-full  mt-5 py-2.5 rounded-full cursor-not-allowed bg-[#1D47B1]/60 text-white font-semibold flex justify-center items-center' : 'w-full  mt-5 py-2.5 rounded-full bg-[#1D47B1] text-white font-semibold flex justify-center items-center'}>
                        {isLoading ? <CgSpinner className="animate-spin w-6 h-6 text-white"/> :
                            <span>Create Community</span>}
                    </button>

                </form>

            </div>

        </div>
    );
}

export default CreateCommunityPage;