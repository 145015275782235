import axios from "axios";
import {communities, communityAddress} from "../utils/ConstStrings";
import {NewMemberInviteType} from "../types/NewMemberInviteType";

export const fetchCommunities = async (queryCommunity: string) => {
    try {
        if (queryCommunity.trim() === '') return
        // const encodeQuery = encodeURI()

        const response = await axios.get(communities + queryCommunity);
        return response.data;


    } catch (error: any) {
        throw new Error('Error fetching communities');
    }

}

export const updateCommunityCoordinates = async (addressID: number, addressLatitude: number, addressLongitude: number) => {

    try {

        if (addressID === 0 || addressLatitude === 0 || addressLongitude === 0) return console.log("Data to update the coordinates are incorrect")

        const newCommunityCoordinates = {
            latitude: addressLatitude,
            longitude: addressLongitude,
        }

        const response = await axios.put(communityAddress + addressID + '/edit', newCommunityCoordinates);

        return response.data;

    } catch (error: any) {
        throw new Error('Error updating ManageCommunity coordinates', error);
    }

}

export const fetchCommunityAddress = async (communityAddressID: number) => {
    try {

        const response = await axios.get(communityAddress + communityAddressID);

        return response.data;

    } catch (error: any) {
        throw new Error('Error updating ManageCommunity coordinates', error);
    }
}

export const inviteMembersToCommunityAPI = async (membersData: NewMemberInviteType[]) => {

    ///api/v1/user/addbulk

    const token = localStorage.getItem("authToken")

    const headers = {
        'Authorization': 'Bearer ' + token
    }
    const membersInviteData = {
        'data': membersData,
    }

    try {
        const res = await axios.post("", membersInviteData, {headers})
        return res.data

    } catch (error: any) {
        console.error(error)
    }
}