import React from 'react';
import Navbar from "../../../components/navbar/Navbar";
import AdminCommunityNavBar from "../../../components/community/AdminCommunityNavBar";
import {Route, Routes} from "react-router-dom";
import AnnouncementsPage from "../../main/pages/AnnouncementsPage";
import ManageCommunityMembersPage from "./members/ManageCommunityMembersPage";

function CommunityManagePage() {
    return (
        <div>
            <AdminCommunityNavBar/>

            <div className="w-full h-full container px-5 mt-5 mx-auto">
                <Routes>
                    <Route path="members" element={<ManageCommunityMembersPage/>}/>
                </Routes>
            </div>

        </div>
    );
}

export default CommunityManagePage;