export interface UserRolType {
    id_rol: number | null,
    description: string,
}

export enum UserRol {
    OWNER = 'Owner',
    User = 'User',
    Moderator = 'Moderator',
    // Puedes agregar más roles aquí...
}