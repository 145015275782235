import React, {useRef, useState} from 'react';
import InviteNewUserForm from '../../../../components/community/InviteNewUserForm';
import {NewMemberInviteType} from '../../../../types/NewMemberInviteType';
import logo from "../../../../assets/icons/logo_colored.svg";
import {useNavigate} from "react-router-dom";
import * as XLSX from 'xlsx';


function ImportMemberFromCsv() {

    const navigator = useNavigate()
    const btnLoadExcelRef = useRef<HTMLInputElement>(null)

    const [membersData, setMembersData] = useState<NewMemberInviteType[]>([]);

    const handleFileChange = (e:any) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = e.target?.result;
            const workbook = XLSX.read(data, { type: 'binary' });

            // Puedes especificar la hoja que deseas leer, por ejemplo, la primera hoja (worksheet)
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            // Convierte los datos de la hoja en un objeto JSON
            const jsonData:NewMemberInviteType[] = XLSX.utils.sheet_to_json(worksheet);

            setMembersData(jsonData);
        };

        reader.readAsBinaryString(file);
    };

    const updateMemberField = (id: number, fieldName: string, newValue: any) => {
        setMembersData((prevMembersData) => {
            return prevMembersData.map((member) => {
                if (member.id === id) {
                    // Clonamos el miembro existente y actualizamos el campo deseado
                    return {
                        ...member,
                        [fieldName]: newValue,
                    };
                } else {
                    return member; // Mantenemos los demás miembros sin cambios
                }
            });
        });
    };

    const handleLoadCSVOnClick = () => {
        if (btnLoadExcelRef.current) {
            btnLoadExcelRef.current.click();
        }
    };

    const handleBack = () => {
        navigator('/community-manage/members')
    }

    const inviteMembersBulkFn = () => {
        if (membersData.length == 0) return

    }

    return (
        <div className="container mx-auto py-5 px-5">

            {/*TOOLBAR*/}
            <div className="bg-white flex flex-row justify-between items-center">

                <button onClick={() => handleBack()} className="text-[#A8A8A8] font-semibold">
                    Cancel
                </button>

                <img src={logo} className="w-32" alt="OwnersTally"/>

                <button onClick={() => inviteMembersBulkFn()} className="text-[#1D47B1] font-semibold">
                    Invite now
                </button>

            </div>

            <div className="mt-5">

                <input type="file" id="inputExcel" ref={btnLoadExcelRef} className="hidden"
                       accept=".xlsx"
                       onChange={handleFileChange}/>

                <div className="w-full border rounded-lg py-2.5 px-5 text-sm text-start">
                    <p className="font-semibold text-gray-800">Import excel file with new members list</p>
                    <p className="mt-2 text-gray-600">Note: The excel file must be in the following format in the
                        header and (id, firstname, lastname, email_address) are required, the others are
                        optional</p>
                    <ol className="list-decimal px-3 mt-3">
                        <li>
                            id
                        </li>

                        <li>
                            firstname
                        </li>

                        <li>
                            lastname
                        </li>

                        <li>
                            email_address
                        </li>

                        <li>
                            community_name
                        </li>

                        <li>
                            property_address
                        </li>

                        <li>
                            property_address_alt
                        </li>

                        <li>
                            property_city
                        </li>

                        <li>
                            property_state
                        </li>

                        <li>
                            property_zip
                        </li>
                    </ol>
                </div>

                <div className="mt-5 flex flex-row justify-start items-start">
                    <button onClick={() => handleLoadCSVOnClick()}
                            className="bg-accent hover:bg-accent/60 transition rounded-lg text-white px-5 py-2">
                        Select file
                    </button>
                </div>
            </div>

            <div className="grid grid-cols-1 gap-4 py-5">
                {membersData.map((item, index) => {
                    return (
                        <InviteNewUserForm
                            key={index}
                            id={item.id}
                            firstname={item.firstname}
                            lastname={item.lastname}
                            email_address={item.email_address}
                            property_address_alt={item.property_address_alt}
                            community_name={item.community_name}
                            property_address={item.property_address}
                            property_city={item.property_city}
                            property_state={item.property_state}
                            property_zip={item.property_zip}
                            updateField={updateMemberField}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default ImportMemberFromCsv;
