import React, {useEffect, useState} from 'react';
import BackArrow from "../../../../components/svg/backArrow";
import Slider from "react-slick";
import MeetingTemplateItem from "../../../../components/meetings/MeetingTemplateItem";
import {useNavigate} from "react-router-dom";
import {documentsSliderSettings} from "../../../../utils/DocumentsSliderSettings";
import DocumentItemPlaceholder from "../../../../components/document/DocumentItemPlaceholder";
import {DocumentsType, DocumentsYearType} from "../../../../types/DocumentsType";
import DocumentItem from "../../../../components/document/DocumentItem";
import {formatDocumentDate} from "../../../../utils/TextUtils";
import {getDocumentsAPI} from "../../../../api/budgetController";
import {MyCommunityCategories} from "../../../../utils/MyCommunityCategories";

function Requests() {

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [resentDocuments, setResentDocuments] = useState<DocumentsType[]>([])
    const [documents, setDocuments] = useState<DocumentsYearType[]>([])

    useEffect(() => {
        getDocumentsData()
    }, [])

    const getDocumentsData = async () => {

        setIsLoading(true)

        try {

            const data = await getDocumentsAPI(MyCommunityCategories.Requests)

            setResentDocuments(data.dataJSON)
            setDocuments(data.dataByMonthJSON)

            setIsLoading(false)

        } catch (e: any) {

            //TODO: REMOVE LATER ONLY FOR TESTING
            console.error(e)
        }
    }

    const getAllDocumentsByMonth = (month:string) =>{
        navigate('/requests/month/' + month,{
            state:{
                monthName:month
            }
        })
    }

    const handleBack = () =>{
        navigate("/main/my-community")
    }

    return (
        <div className="2xl:container w-full mx-auto pb-5 px-5 lg:px-10">

            <div className="bg-white h-14 flex flex-row justify-between items-center">

                <button onClick={() => handleBack() } className="text-[#1D47B1] block lg:hidden">
                    <BackArrow/>
                </button>

               <div className='flex flex-row justify-center items-center'>
                   <button onClick={() => handleBack() }
                           className="hidden border rounded-full p-2 h-8 w-8 mr-2 lg:flex justify-center items-center">
                       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                            stroke="currentColor" className="w-6 h-6">
                           <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
                       </svg>
                   </button>

                   <h2 className="font-semibold lg:text-2xl text-[#1D47B1]">Requests</h2>

               </div>

            </div>

            <div className="w-full flex flex-row items-start pt-5">
                <h4 className="font-semibold text-lg">Templates</h4>
            </div>

            <div className="mt-2">
                <Slider {...documentsSliderSettings}>
                    <MeetingTemplateItem route={"/new-request"}/>
                </Slider>
            </div>

            <div className="w-full flex flex-col items-start pt-5">
                <h4 className="font-semibold text-lg">Recent Files</h4>
                {!isLoading && resentDocuments && resentDocuments.length <= 0 &&
                    <p className="text-gray-600 text-sm">There are no documents to display, your recent documents will
                        appear here</p>}
            </div>

            <div className="mt-2 grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4">

                {
                    isLoading && <>
                        <DocumentItemPlaceholder/>

                        <DocumentItemPlaceholder/>

                        <DocumentItemPlaceholder/>

                        <DocumentItemPlaceholder/>

                        <DocumentItemPlaceholder/>

                        <DocumentItemPlaceholder/>

                        <DocumentItemPlaceholder/>

                        <DocumentItemPlaceholder/>

                    </>

                }

                {
                    resentDocuments.map((documentItem: DocumentsType) => {
                        return (
                            <DocumentItem
                                key={parseInt(documentItem.id_data)}
                                id={parseInt(documentItem.id_data)}
                                title={documentItem.title}
                                created_at={formatDocumentDate(documentItem.createdAt)}
                                route={"/request/"}
                            />
                        )
                    })
                }
            </div>


            <div className="w-full flex flex-col items-start pt-5">
                <h4 className="font-semibold text-lg">Saved documents</h4>
                {!isLoading && documents && documents.length <= 0 &&
                    <p className="text-gray-600 text-sm">There are no documents to show, here they will appear organized
                        by month, the documents of the current year</p>}
            </div>

            <div className="flex flex-col space-y-3">

                <div>
                    {
                        documents.map((documentMonth, index: number) => {
                            return (
                                <div key={index}>
                                    <div className="flex flex-col items-start justify-start">
                                        <div className="flex w-full justify-between">

                                            <h5 className=" font-semibold text-sm text-[#3C3C43]">{documentMonth.month}</h5>

                                            <button onClick={()=> getAllDocumentsByMonth(documentMonth.month) } className="flex flex-row space-x-1 justify-center items-center hover:text-dark-blue text-sm text-gray-600">

                                                <span>view more</span>

                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M8.25 4.5l7.5 7.5-7.5 7.5"/>
                                                </svg>

                                            </button>

                                        </div>
                                    </div>

                                    <div className="mt-2 grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4">

                                        {
                                            documentMonth.data.map((documentItem) => {
                                                return (
                                                    <DocumentItem
                                                        key={parseInt(documentItem.id_data)}
                                                        id={parseInt(documentItem.id_data)}
                                                        title={documentItem.title}
                                                        created_at={formatDocumentDate(documentItem.createdAt)}
                                                        route={"/request/"}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </div>

        </div>
    );
}

export default Requests;