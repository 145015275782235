import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import PersonIllustration from '../../assets/svg/home/home_1.svg'
import logo from "../../assets/icons/logo_colored.svg";
import Home2Illustration from '../../assets/svg/home/home_2.svg'
import HomePlant from '../../assets/svg/home/home_plant.svg'
import HomeTemplate from '../../assets/svg/home/home_template.svg'
import HomeTopPlane from '../../assets/svg/home/home_plane_top.svg'
import Home3Illustration from '../../assets/svg/home/home_3.svg'
import Footer from "../../components/footer/Footer";
import {fetchCommunities} from "../../api/communitiesController";
import CommunityIcon from '../../assets/svg/committees/committees.svg'
import {Community} from "../../types/CommunityType";
import {SearchCommunityType} from "../../types/SearchCommunityType";
import AuthContextProvider,{ UserContextType} from "../../providers/AuthContextProvider";

function WelcomePage() {

    const navigate = useNavigate();


    const [inputCommunity, setInputCommunity] = useState('');
    const [suggestedCommunities, setSuggestedCommunities] = useState<SearchCommunityType[]>([]);
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [communitySelected, setCommunitySelected] = useState<SearchCommunityType>()
    const [btnNextState, setBtnNextState] = useState<boolean>(false)

    const {auth} = useContext(AuthContextProvider) as UserContextType

    useEffect(()=>{
        if (!auth) return

        if (auth.isAuth)
            navigate('/main/announcements')
    },[])

    useEffect(() => {

        if (showSuggestions) {
            getSuggestedCommunities();
        }

        if (inputCommunity.trim().length === 0) {
            setShowSuggestions(false)
            setBtnNextState(false)
        }

    }, [inputCommunity]);

    const handleSetCommunity = (e: any, community: SearchCommunityType) => {
        e.preventDefault();
        setInputCommunity(community.name);
        setCommunitySelected(community)
        setShowSuggestions(false);
        setBtnNextState(true)
    };

    const handleCreateCommunity = (e:any) =>{
        navigate('/community/create',{
            state:{
                communityName: inputCommunity
            }
        })
    }

    const getSuggestedCommunities = async () => {
        if (inputCommunity.length < 2) return
        setIsLoading(true)

        try {

            if (isLoading) return

            const res = await fetchCommunities(inputCommunity);
            setSuggestedCommunities(res.rows);
            setIsLoading(false)
        } catch (e: any) {
            setIsLoading(false)
        }
    };

    const handleSetupCommunity = () => {
        if (inputCommunity.trim() === '') return;
        navigate('/signup-community', {
            state: {
                'communityID': communitySelected?.id_community,
                'communityName': communitySelected?.name,
                'addressID': communitySelected?.address_id ?? 0,
            }
        });
    };

    return (
        <div className="2xl:container mx-auto">

            <div className="w-full flex flex-col lg:flex-row mx-auto">

                <div className="h-full w-full">

                    <picture className="flex justify-center items-center py-5 lg:hidden">
                        <img src={logo} className="w-44" alt="OwnersTally"/>
                    </picture>

                    <h1 className="text-[#A8A8A8] text-2xl md:text-3xl font-bold block lg:hidden">Let's start by <br/>registering
                        your <span
                            className="text-[#1D47B1]"><br/>community.</span>
                    </h1>

                    <picture className="relative">
                        <img className="w-full h-[350px] lg:h-[37rem] z-50 pt-10" src={PersonIllustration}
                             alt="OwnersTally"/>
                        <img className="hidden lg:block  w-auto h-auto absolute top-0 -z-10 " src={HomeTopPlane}
                             alt=""/>
                    </picture>

                </div>

                <div className="px-10 lg:px-20 w-full flex flex-col justify-center items-center space-y-5 mb-5">

                    <picture className="lg:flex justify-center items-center hidden ">
                        <img src={logo} className="w-[14rem]" alt="OwnersTally"/>
                    </picture>

                    <h1 className="text-[#A8A8A8] text-2xl md:text-3xl font-bold hidden lg:block">Let's start by <br/>registering
                        your <span className="text-[#1D47B1]"><br/>community.</span></h1>

                    <div className="relative w-full flex justify-center">
                        <input type="text"
                               value={inputCommunity}
                               onChange={(e: any) => {
                                   setInputCommunity(e.target.value);
                                   setShowSuggestions(true);
                               }}
                               className=" border border-[#E0E6F8] bg-white w-full lg:w-2/3 px-5 py-2.5 rounded-lg"
                               placeholder="Community name"/>

                        {suggestedCommunities.length > 0 && showSuggestions &&
                            <div className="absolute w-full lg:w-2/3 mt-14 text-sm text-start bg-white rounded-lg shadow z-50">
                                {
                                    suggestedCommunities.map((result: SearchCommunityType, index: number) => {
                                        return (
                                            <button key={index}
                                                    onClick={(e: any) => handleSetCommunity(e, result)}
                                                    className="w-full border-l-2 border-l-transparent hover:border-l-dark-blue flex flex-row justify-start items-center text-start space-x-3 py-2 hover:bg-gray-100 rounded">
                                                <img src={CommunityIcon}
                                                     className="ml-4 w-5 h-5 object-cover object-center"
                                                     alt="community icon"/>
                                                <span>{result.name}</span>
                                            </button>
                                        )
                                    })

                                }

                                <button
                                        onClick={(e: any) => handleCreateCommunity(e)}
                                        className="w-full border-l-2 border-l-transparent hover:border-l-dark-blue flex flex-row justify-start items-center text-start space-x-3 py-2 hover:bg-gray-100 rounded">
                                    <img src={CommunityIcon}
                                         className="ml-4 w-5 h-5 object-cover object-center"
                                         alt="community icon"/>
                                    <span>{inputCommunity}</span>
                                </button>

                            </div>
                        }
                    </div>

                    <button disabled={!btnNextState} onClick={() => handleSetupCommunity()}
                            className={btnNextState ? "w-full lg:w-2/3 mt-5 py-2.5 rounded-full bg-[#1D47B1] text-white font-semibold" : "w-full lg:w-2/3  mt-5 py-2.5 rounded-full bg-[#1D47B1]/60 hover:cursor-not-allowed text-white font-semibold"}>NEXT
                    </button>

                    <div className="flex flex-row items-center space-x-2 ">
                        <p className="text-gray-700">Already you have an account?</p>
                        <button className="font-semibold" onClick={()=> navigate('/signin')}>Log in</button>
                    </div>

                </div>

            </div>

            <div className="relative -top-24 hidden lg:block">

                <picture className="relative flex items-center justify-center">

                    <img src={Home2Illustration} className="w-auto h-auto" alt=""/>

                </picture>
            </div>

            <div className="px-20 relative -top-44 flex-row justify-between items-center hidden lg:flex">

                <picture>
                    <img src={HomePlant} className="w-auto h-32" alt=""/>
                </picture>

                <h2 className="text-[#A8A8A8] text-start font-semibold  text-3xl">Create and order your <br/><span
                    className="text-[#FE8F30]">templates and groups</span></h2>

                <picture>
                    <img src={HomeTemplate} className="w-auto h-32" alt=""/>
                </picture>

            </div>

            <div className="relative -top-36 hidden lg:block">
                <picture className="relative">

                    <img src={Home3Illustration} className="w-auto h-auto" alt=""/>

                </picture>
            </div>

            <div className="hidden lg:block px-8">
                <Footer/>
            </div>

        </div>
    );
}

export default WelcomePage;

