import React from 'react';
import GroupIcon from "../../../../assets/svg/committees/committees.svg";

function CreateGroup() {
    return (
        <div>

            {/*MOBILE*/}
            <div className="block lg:hidden">

                {/*TOOLBAR*/}
                <div className="bg-white h-14 flex flex-row justify-between items-center px-5">

                    <button className="text-[#A8A8A8] font-semibold">
                        Cancel
                    </button>

                    <h2 className="font-semibold text-[#1D47B1]">Create group</h2>

                    <button className="text-[#1D47B1] font-semibold">
                        OK
                    </button>
                </div>

                {/*CONTENT*/}
                <form className="flex space-y-3 flex-col px-5 my-5">

                    <div className="text-start">
                        <label>Name*</label>
                        <input type="text" className="mt-1 border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="write name"/>
                    </div>

                    <div className="text-start">
                        <label>Description*</label>
                        <input type="text" className="mt-1 border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="write description"/>
                    </div>

                    <div className="text-start">
                        <label>Maximum number of members*</label>
                        <input type="number" className="mt-1 border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="write number"/>
                    </div>

                </form>

            </div>


            {/*DESKTOP*/}
            <div className="2xl:container mx-auto hidden lg:block">
                <div className="mt-5 w-1/2 mx-auto border rounded-lg p-5">

                    <div className="flex justify-between items-center">

                        <h2 className="font-bold text-[#3C3C43] text-2xl">Create Group</h2>

                        <div className="flex space-x-5 items-center">
                            <button className="font-medium text-[#A8A8A8]">Cancel</button>
                            <button className="font-medium text-[#1D47B1]">OK</button>
                        </div>

                    </div>

                    <div className='flex-grow h-0.5 bg-gray-100 my-3'/>


                    <div className="flex justify-between items-center space-x-10 px-5">

                        <div className="p-10 bg-[#D7E8F0] rounded-full">

                            <svg width="43" height="43" viewBox="0 0 43 43" className="w-32 h-32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M31.7191 9.3788C35.8397 12.2688 38.6829 16.8636 39.2598 22.1653M3.96683 22.2693C4.22586 19.7269 5.02193 17.2701 6.30138 15.0644C7.58083 12.8587 9.31395 10.9554 11.3839 9.48276M13.6502 40.0872C16.0402 41.3139 18.7598 42 21.6236 42C24.3844 42 26.9804 41.3763 29.3085 40.2328M21.6236 12.5598C23.1427 12.5598 24.5995 11.9509 25.6737 10.8669C26.7478 9.78299 27.3513 8.31285 27.3513 6.77992C27.3513 5.24699 26.7478 3.77684 25.6737 2.6929C24.5995 1.60895 23.1427 1 21.6236 1C20.1046 1 18.6477 1.60895 17.5736 2.6929C16.4994 3.77684 15.896 5.24699 15.896 6.77992C15.896 8.31285 16.4994 9.78299 17.5736 10.8669C18.6477 11.9509 20.1046 12.5598 21.6236 12.5598V12.5598ZM6.72764 37.9665C8.2467 37.9665 9.70355 37.3576 10.7777 36.2736C11.8518 35.1897 12.4553 33.7195 12.4553 32.1866C12.4553 30.6537 11.8518 29.1835 10.7777 28.0996C9.70355 27.0156 8.2467 26.4067 6.72764 26.4067C5.20857 26.4067 3.75173 27.0156 2.67759 28.0996C1.60345 29.1835 1 30.6537 1 32.1866C1 33.7195 1.60345 35.1897 2.67759 36.2736C3.75173 37.3576 5.20857 37.9665 6.72764 37.9665V37.9665ZM36.2724 37.9665C37.7914 37.9665 39.2483 37.3576 40.3224 36.2736C41.3966 35.1897 42 33.7195 42 32.1866C42 30.6537 41.3966 29.1835 40.3224 28.0996C39.2483 27.0156 37.7914 26.4067 36.2724 26.4067C34.7533 26.4067 33.2964 27.0156 32.2223 28.0996C31.1482 29.1835 30.5447 30.6537 30.5447 32.1866C30.5447 33.7195 31.1482 35.1897 32.2223 36.2736C33.2964 37.3576 34.7533 37.9665 36.2724 37.9665V37.9665Z" stroke="#1D47B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                        </div>

                        <div>
                            {/*CONTENT*/}
                            <form className=" flex space-y-3 flex-col px-5 my-5">

                                <div className="text-start">
                                    <label>Name*</label>
                                    <input type="text" className="mt-1 border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                                           placeholder="write name"/>
                                </div>

                                <div className="text-start">
                                    <label>Description*</label>
                                    <input type="text" className="mt-1 border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                                           placeholder="write description"/>
                                </div>

                                <div className="text-start">
                                    <label>Maximum number of members*</label>
                                    <input type="number" className="mt-1 border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                                           placeholder="write number"/>
                                </div>

                            </form>
                        </div>

                    </div>

                </div>
            </div>


        </div>
    );
}

export default CreateGroup;