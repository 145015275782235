import React from 'react';

function ItemMail() {
    return (
        <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 35.97 36.03"
        >
            <path
                d="M7.54 29.02h23.73c.49 0 .88-.4.88-.88V10.75a3.73 3.73 0 0 0-3.73-3.73H4.69c-.49 0-.88.4-.88.88v17.39a3.73 3.73 0 0 0 3.73 3.73Zm5.83-10.71-7.69 7.6c-.07-.2-.11-.41-.11-.63V10.04l7.8 8.27Zm-6.44 8.84 7.65-7.56 1.88 1.99c.4.43.94.66 1.52.66s1.12-.23 1.52-.66l1.88-1.99 7.75 7.66H7.54c-.21 0-.41-.04-.61-.11Zm23.36-17c.06.19.1.39.1.6v15.28l-7.8-7.71 7.7-8.16Zm-1.23-1.26L18.23 20.37c-.17.18-.31.18-.47 0L6.81 8.78h21.61c.22 0 .43.05.63.11Z"
                style={{
                    fill: "#a8a8a8",
                }}
            />
        </svg>
    );
}

export default ItemMail;