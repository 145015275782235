import React from 'react';

function Google() {
    return (
        <>
            <svg width="52" height="52" className="rounded-lg" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_847_3382)">
                    <path d="M0 0V52.39H52.39V0H0ZM41.07 35.71C38.42 40.39 34.26 42.9 29.01 43.51C22.29 44.29 16.69 42.04 12.58 36.64C4.53 26.08 11.13 10.51 24.32 8.88C29.58 8.23 34.28 9.63 38.21 13.24C36.51 14.93 34.82 16.6 33.11 18.3C33.04 18.25 32.88 18.17 32.75 18.06C27.6 13.6 18.68 15.93 16.67 23.81C15.64 27.87 17.36 32.31 20.87 34.77C24.22 37.12 29.06 37.19 32.4 34.91C34.2 33.68 35.35 31.98 35.83 29.75H26.54V22.72C26.81 22.71 27.06 22.68 27.3 22.68C32.26 22.68 37.22 22.69 42.18 22.66C42.84 22.66 43 22.89 43.09 23.5C43.67 27.78 43.23 31.9 41.07 35.71Z" fill="#D9D9D9"/>
                    <path d="M0 0V52.39H52.39V0H0ZM41.07 35.71C38.42 40.39 34.26 42.9 29.01 43.51C22.29 44.29 16.69 42.04 12.58 36.64C4.53 26.08 11.13 10.51 24.32 8.88C29.58 8.23 34.28 9.63 38.21 13.24C36.51 14.93 34.82 16.6 33.11 18.3C33.04 18.25 32.88 18.17 32.75 18.06C27.6 13.6 18.68 15.93 16.67 23.81C15.64 27.87 17.36 32.31 20.87 34.77C24.22 37.12 29.06 37.19 32.4 34.91C34.2 33.68 35.35 31.98 35.83 29.75H26.54V22.72C26.81 22.71 27.06 22.68 27.3 22.68C32.26 22.68 37.22 22.69 42.18 22.66C42.84 22.66 43 22.89 43.09 23.5C43.67 27.78 43.23 31.9 41.07 35.71Z" fill="#1D47B1"/>
                </g>
                <defs>
                    <clipPath id="clip0_847_3382">
                        <rect width="52.39" height="52.39" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

        </>
    );
}

export default Google;