import React from 'react';

function PlusCircleIcon() {
    return (
        <div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1610_916)">
                    <path d="M9.99935 18.3337C14.6017 18.3337 18.3327 14.6027 18.3327 10.0003C18.3327 5.39795 14.6017 1.66699 9.99935 1.66699C5.39698 1.66699 1.66602 5.39795 1.66602 10.0003C1.66602 14.6027 5.39698 18.3337 9.99935 18.3337Z" stroke="#1D47B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10 6.66699V13.3337" stroke="#1D47B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.66602 10H13.3327" stroke="#1D47B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_1610_916">
                        <rect width="20" height="20" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

        </div>
    );
}

export default PlusCircleIcon;