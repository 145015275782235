import React from 'react';
import Toolbar from "../../../components/toolbar/Toolbar";
import MyCommunityItem from "../../../components/main-pages/my-community/MyCommunityItem";
import Budget from "../../../components/svg/my-community/budget.svg"
import Membership from "../../../components/svg/my-community/membership.svg"
import Meetings from "../../../components/svg/my-community/meetings.svg"
import Manage from "../../../components/svg/my-community/manage.svg"
import Communication from "../../../components/svg/my-community/rules.svg"
import Requests from "../../../components/svg/my-community/architecture.svg"

import BgLines from "../../../components/svg/my-community/bg-lines.svg"
import HomePlant from "../../../assets/svg/home/home_plant.svg";
import HomeTemplate from "../../../assets/svg/home/home_template.svg";
import Home3Illustration from "../../../assets/svg/home/home_3.svg";

function MyCommunityPage() {
    return (
        <div className="w-full lg:bg-[#00113D] min-h-screen ">

            <Toolbar title="My Community"/>

            <div className="hidden lg:block ">

                <picture>
                    <img src={BgLines} className="w-full absolute z-0" alt=""/>
                </picture>

            </div>

            <div className="w-full pt-16 lg:flex flex-col justify-center  items-center relative z-10">

                <h1 className="text-white font-semibold text-2xl lg:mb-10">My Community</h1>

                <div className="block lg:hidden rounded-t bg-[#EFEFEF] py-5 pb-4">

                    <div className="flex justify-center items-center">
                        <input type="text" placeholder="Search..."
                               className="w-full mx-5 px-5 py-2 rounded-full bg-[#FFFFFF] outline-none"/>
                    </div>

                </div>

                <div className="pt-5 lg:px-20 grid grid-cols-1 md:grid-cols-3 gap-x-4 lg:gap-y-4">

                    <MyCommunityItem title={"Budget"} icon={Budget}/>

                    <MyCommunityItem title={"Committees"} icon={Membership}/>

                    <MyCommunityItem title={"Meetings"} icon={Meetings}/>

                    <MyCommunityItem title={"Communication"} icon={Communication}/>

                    <MyCommunityItem title={"Requests"} icon={Requests}/>

                    <MyCommunityItem title={"Manage"} icon={Manage}/>


                </div>

                <div className="bg-white mt-32 pb-10 block 2xl:hidden">
                    <div className="px-20 py-5 flex-row justify-between items-center hidden lg:flex">

                        <picture>
                            <img src={HomePlant} className="w-auto h-32" alt=""/>
                        </picture>

                        <h2 className="text-[#A8A8A8] text-start font-semibold  text-3xl">Create and order your <br/><span
                            className="text-[#FE8F30]">templates and groups</span></h2>

                        <picture>
                            <img src={HomeTemplate} className="w-auto h-32" alt=""/>
                        </picture>

                    </div>

                    <div className="hidden lg:block">
                        <picture className="relative">

                            <img src={Home3Illustration} className="w-auto h-auto" alt=""/>

                        </picture>
                    </div>

                </div>


            </div>

        </div>
    );
}

export default MyCommunityPage;