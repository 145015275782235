import React, {useContext, useEffect, useState} from 'react';
import logo from "../../assets/icons/logo_colored.svg";
import Google from "../../components/svg/google";
import Facebook from "../../components/svg/facebook";
import Linkedin from "../../components/svg/linkedin";
import LoginIllustration from "../../assets/svg/login/login.svg";
import PersonIllustration from "../../assets/svg/home/home_1.svg";
import HomeTopPlane from "../../assets/svg/home/home_plane_top.svg";
import Home2Illustration from "../../assets/svg/home/home_2.svg";
import HomePlant from "../../assets/svg/home/home_plant.svg";
import HomeTemplate from "../../assets/svg/home/home_template.svg";
import Home3Illustration from "../../assets/svg/home/home_3.svg";
import Footer from "../../components/footer/Footer";
import {validateEmail} from "../../utils/TextUtils";
import axios from "axios";
import {userData, userLogin} from "../../utils/ConstStrings";
import AuthContextProvider, {AuthUser, UserContextType} from "../../providers/AuthContextProvider";
import {UserRolType} from "../../types/UserRolType";
import {useNavigate} from "react-router-dom";
import {CgSpinner} from "react-icons/cg";

const ErrorEnum = {
    Name: 'name',
    LastName: 'lastname',
    Email: 'email',
    Password: 'password',
    Role: 'role'
};

interface ErrorType {
    type: string,
    message: string,
}

function OwnerLoginPage() {

    const navigate = useNavigate()

    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [showPassword, setShowPassword] = useState(false)

    const [errorMessage, setErrorMessage] = useState<ErrorType | null>(null);

    const {auth, setAuth} = useContext(AuthContextProvider) as UserContextType

    useEffect(()=>{
        if (!auth) return

        if (auth.isAuth)
            navigate('/main/announcements')
    },[])

    useEffect(() => {
        setErrorMessage(null)
    }, [email, password])

    const handelLoginUser = async (e: any) => {
        e.preventDefault()

        if (email.trim().length === 0) {
            setErrorMessage({type: ErrorEnum.Email, message: 'Email is required'});
            return
        }

        if (!validateEmail(email)) {
            setErrorMessage({type: ErrorEnum.Email, message: 'Email is invalid'});
            return
        }


        if (password.trim().length < 8) {
            setErrorMessage({type: ErrorEnum.Password, message: 'Password must be at least 8 characters'});
            return
        }

        setIsLoading(true)

        const authData = {
            email: email,
            password: password
        }

        try {
            const res = await axios.post(userLogin, authData)

            if (res.data.user) {

                localStorage.clear()
                localStorage.setItem("authToken", res.data.user.salt_token)

                //Get user info
                const userInfo =  await axios.get(userData + res.data.user.uuid)
                const {user} = userInfo.data

                const userAuthData: AuthUser = {
                    isAuth: true,
                    uuid: res.data.user.uuid,
                    id: res.data.user.id_user,
                    name: user.firstname + ' ' + user.lastname,
                    avatar: user.avatar ?? 'https://sbcf.fr/wp-content/uploads/2018/03/sbcf-default-avatar.png',
                    roles: user.userRolModel.rolModels,
                }

                setAuth(userAuthData)

                //TODO: VALIDATE IF ACCOUNT IS VERIFIED BY EMAIL CODE
                //navigate('/email-verification');

                navigate('/main/announcements')

            }else {

                const errorData :ErrorType ={
                    type:ErrorEnum.Email,
                    message:res.data.msg
                }

                setErrorMessage(errorData)

            }

            setIsLoading(false)

        } catch (e: any) {
            setIsLoading(false)

            const errorData :ErrorType ={
                type:ErrorEnum.Email,
                message:e.message,
            }
            setErrorMessage(errorData)

            console.log(e.message)
        }


    }

    return (
        <div className="2xl:container mx-auto">

            <div className="w-full flex flex-col lg:flex-row  mx-auto">

                <div className="h-full w-full">

                    <picture className="flex justify-center items-center py-5 lg:hidden">
                        <img src={logo} className="w-44" alt="OwnersTally"/>
                    </picture>

                    <h1 className="text-[#A8A8A8] text-2xl md:text-3xl font-bold block lg:hidden">Let's start by <br/>registering
                        your <span
                            className="text-[#1D47B1]"><br/>community.</span>
                    </h1>

                    <picture className="relative">
                        <img className="w-full h-[350px] lg:h-[37rem] z-50 pt-10" src={PersonIllustration}
                             alt="OwnersTally"/>
                        <img className="hidden lg:block  w-auto h-auto absolute top-0 -z-10 " src={HomeTopPlane}
                             alt=""/>
                    </picture>

                </div>

                <div className="px-10 lg:px-20 w-full flex flex-col justify-center items-center space-y-5 mb-5">

                    <picture className="lg:flex justify-center items-center hidden ">
                        <img src={logo} className="w-[14rem]" alt="OwnersTally"/>
                    </picture>

                    <div className="w-full">
                        <label className="sr-only">Email</label>
                        <input type="email" className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="Email"
                               value={email}
                               disabled={isLoading}
                               onChange={(e: any) => setEmail(e.target.value)}
                        />

                        {errorMessage?.type === ErrorEnum.Email && (
                            <div className="text-start w-full my-1">
                                <span className="text-red-600 text-sm">* {errorMessage.message}</span>
                            </div>
                        )}
                    </div>


                    <div className="relative w-full">

                        <label className="sr-only">Password</label>
                        <input placeholder="Password" type={`${showPassword ? 'text' : 'password'}`}
                               name="password"
                               className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               required
                               value={password}
                               disabled={isLoading}
                               onChange={(e: any) => setPassword(e.target.value)}
                        />

                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center rounded-full p-2.5">

                            <svg
                                className={`${showPassword ? 'h-6 w-6 text-dark-blue hidden ' : 'h-6 w-6 text-dark-blue  block '}`}
                                onClick={() => setShowPassword(!showPassword)} fill="none"
                                viewBox="0 0 23.14 14.52" xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" stroke="#1d47b1" strokeMiterlimit="10" strokeWidth="1.5">
                                    <path d="m.63 6.74a13 13 0 0 1 21.88 0"/>
                                    <circle cx="11.57" cy="9.68" r="4.1"/>
                                </g>
                            </svg>


                            <svg viewBox="0 0 23.14 18.32" xmlns="http://www.w3.org/2000/svg"
                                 className={`${showPassword ? 'h-6 w-6 text-dark-blue block ' : 'h-6 w-6 text-dark-blue hidden'}`}
                                 onClick={() => setShowPassword(!showPassword)}>
                                <g fill="none" stroke="#1d47b1" strokeMiterlimit="10" strokeWidth="1.5">
                                    <path d="m.63 7.68a13 13 0 0 1 21.88 0"/>
                                    <circle cx="11.57" cy="10.62" r="4.1"/>
                                    <path d="m4.06 17.79 17.26-17.26"/>
                                </g>
                            </svg>

                        </div>


                    </div>
                    {errorMessage?.type === ErrorEnum.Password && (
                        <div className="text-start w-full my-1">
                            <span className="text-red-600 text-sm">* {errorMessage.message}</span>
                        </div>
                    )}

                    <button onClick={(e: any) => handelLoginUser(e)} disabled={isLoading}
                            className={isLoading ? 'w-full  mt-5 py-2.5 rounded-full cursor-not-allowed bg-[#1D47B1]/60 text-white font-semibold flex justify-center items-center' : 'w-full  mt-5 py-2.5 rounded-full bg-[#1D47B1] text-white font-semibold flex justify-center items-center'}>
                        {isLoading ?   <CgSpinner className="animate-spin w-6 h-6 text-white"/> : <span>Log in</span> }
                    </button>

                    <div className="flex flex-row items-center space-x-2 ">
                        <p className="text-gray-700">You own a community?,</p>
                        <button className="font-semibold" onClick={()=> navigate('/')}>Create an account now</button>
                    </div>

                    <section className="hidden">
                        <p className="text-[#353535]/50">LOG IN WITH</p>

                        <div className="flex flex-row justify-center items-center space-x-5">

                            <button onClick={() => alert("Google Login BTN")}>
                                <Google/>
                            </button>

                            <button onClick={() => alert("Facebook Login BTN")}>
                                <Facebook/>
                            </button>

                            <button onClick={() => alert("Linkedin Login BTN")}>
                                <Linkedin/>
                            </button>

                        </div>

                    </section>

                </div>

            </div>

            <div className="relative -top-24 hidden lg:block">

                <picture className="relative flex items-center justify-center">

                    <img src={Home2Illustration} className="w-auto h-auto" alt=""/>

                </picture>
            </div>

            <div className="px-20 relative -top-44 flex-row justify-between items-center hidden lg:flex">

                <picture>
                    <img src={HomePlant} className="w-auto h-32" alt=""/>
                </picture>

                <h2 className="text-[#A8A8A8] text-start font-semibold  text-3xl">Create and order your <br/><span
                    className="text-[#FE8F30]">templates and groups</span></h2>

                <picture>
                    <img src={HomeTemplate} className="w-auto h-32" alt=""/>
                </picture>

            </div>

            <div className="relative -top-36 hidden lg:block">
                <picture className="relative">

                    <img src={Home3Illustration} className="w-auto h-auto" alt=""/>

                </picture>
            </div>

            <div className="hidden lg:block px-8">
                <Footer/>
            </div>

        </div>
    );
}

export default OwnerLoginPage;