import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {createReactEditorJS} from "react-editor-js";
import {deleteDocumentAPI, getDocumentAPI, updateDocumentAPI} from "../../../../api/budgetController";
import {extractFilenamesFromJson} from "../../../../utils/JsonUtils";
import logo from "../../../../assets/icons/logo_colored.svg";
import {EDITOR_JS_TOOLS} from "../../../../utils/editor-tools/editorTools";

function RequestDetails() {

    const location = useLocation()
    const navigate = useNavigate()

    const editorCore = React.useRef(null)

    const [showMenu, setShowMenu] = useState<boolean>(false)
    const menuRef = useRef<any>(null);

    const [documentTitle, setDocumentTitle] = useState<string>('')
    const [documentData, setDocumentData] = useState<object>()

    const ReactEditorJS = createReactEditorJS()

    useEffect(() => {
        getDocumentData()
    }, [])

    useEffect(() => {
        // only add the event listener when the dropdown is opened
        if (!showMenu) return;

        function handleClick(event: any) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false)
            }
        }

        window.addEventListener("click", handleClick);
        // clean up
        return () => window.removeEventListener("click", handleClick);
    }, [showMenu]);

    //TODO: INITIALIZE EDITOR
    const handleInitialize = React.useCallback((instance: any) => {
        editorCore.current = instance
    }, [])

    //TODO: SAVE CURRENT DATA FROM EDITOR
    const getCurrentDocumentData = React.useCallback(async () => {
        // @ts-ignore
        return await editorCore.current.save()

    }, [])

    const handleShowMenu = () => {
        setShowMenu(!showMenu)
    }

    const getDocumentData = async () => {

        try {

            const res = await getDocumentAPI(location.state.documentID)

            setDocumentTitle(res.dataJSON.title)
            setDocumentData(res.dataJSON.data_json)

            // @ts-ignore
            const loadDataOnEditor = await editorCore.current.render(res.dataJSON.data_json);

        } catch (e: any) {
            console.log(e)
        }
    }

    const updateDocumentFn = async () => {

        const editorData = await getCurrentDocumentData()

        const filenamesArray = extractFilenamesFromJson(editorData.blocks);
        const documentID = location.state.documentID

        try {

            const res = await updateDocumentAPI(
                documentID,
                documentTitle,
                editorData,
                filenamesArray.toString()
            )

            console.log(res,documentID)

            if (res.ok === true) {
                handleBack()
            }

        } catch (e: any) {
            console.error(e)
        }

    }

    const deleteDocumentFn = async () => {
        try {

            const res = await deleteDocumentAPI(location.state.documentID)

            if (res.ok){
                handleBack()
            }

        }catch (e:any){
            console.error(e)
        }
    }

    const handleBack = () => {

        const month = location.state.monthName

        if (month !== undefined) {
            navigate('/requests/month/' + month, {
                state: {
                    monthName: month,
                }
            })
        } else {
            navigate('/requests')
        }

    }

    return (
        <div>
            <div className="px-5 py-5">

                {/*TOOLBAR*/}
                <div className="bg-white flex flex-row justify-between items-center">

                    <button onClick={() =>  handleBack() } className="text-[#A8A8A8] font-semibold">
                        Cancel
                    </button>

                    <img src={logo} className="w-32" alt="OwnersTally"/>

                    <div>
                        <button onClick={() => handleShowMenu()} ref={menuRef} className="px-3 py-2.5 hover:bg-gray-50 rounded-full flex space-x-0.5 items-center">

                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                            </svg>

                        </button>

                        {showMenu &&
                            <div className="relative -bottom-2 right-44">

                                <div className="absolute z-50 w-56 flex flex-col bg-white shadow-lg border rounded-lg py-3">

                                    <button onClick={()=> updateDocumentFn()} className="text-sm py-2.5 hover:bg-gray-50 w-full text-start px-5 flex flex-row items-center space-x-2">

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg>

                                        <span>Update</span>

                                    </button>

                                    <button onClick={()=> deleteDocumentFn() } className="text-sm py-2.5 hover:bg-gray-50 w-full text-start px-5 flex flex-row items-center space-x-2">

                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                        </svg>

                                        <span>Delete</span>

                                    </button>


                                </div>

                            </div>
                        }
                    </div>


                </div>

                <div className="mt-5 text-start">
                    <div className="w-full lg:w-1/2 mx-auto md:px-16 lg:px-2 mb-5">
                        <input type="text"
                               className="outline-none font-medium text-lg placeholder:text-lg placeholder:font-medium w-full"
                               placeholder="Document title"
                               value={documentTitle}
                               onChange={(e: any) => setDocumentTitle(e.target.value)}
                               maxLength={60}/>
                    </div>

                    <ReactEditorJS onInitialize={handleInitialize}
                                   tools={EDITOR_JS_TOOLS}
                                   onReady={getDocumentData}
                    />
                </div>

            </div>
        </div>
    );
}

export default RequestDetails;