// @ts-ignore
import Embed from '@editorjs/embed'

// @ts-ignore
import Table from '@editorjs/table'

// @ts-ignore
import Paragraph from '@editorjs/paragraph'

// @ts-ignore
import List from '@editorjs/list'

// @ts-ignore
import AttachesTool from '@editorjs/attaches';

// @ts-ignore
import Warning from '@editorjs/warning'

// @ts-ignore
import Code from '@editorjs/code'

// @ts-ignore
import LinkTool from '@editorjs/link'

// @ts-ignore
import Image from '@editorjs/image'

// @ts-ignore
import Raw from '@editorjs/raw'

import Header from '@editorjs/header'

// @ts-ignore
import Quote from '@editorjs/quote'

// @ts-ignore
import Marker from '@editorjs/marker'

// @ts-ignore
import CheckList from '@editorjs/checklist'

// @ts-ignore
import Delimiter from '@editorjs/delimiter'

// @ts-ignore
import InlineCode from '@editorjs/inline-code'

// @ts-ignore
import SimpleImage from '@editorjs/simple-image'
import axios from "axios";
import {uploadFile} from "../ConstStrings";
import jwt_decode from "jwt-decode";
import {TokenDataType} from "../../types/TokenDataType";

export const EDITOR_JS_TOOLS = {
    // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
    paragraph: {
        class:Paragraph,
        config:{
            placeholder:"Press 'Tab' for commands..."
        }
    },
    embed: Embed,
    table: Table,
    list: List,
    warning: Warning,
    code: Code,
    attaches: {
        class: AttachesTool,
        config: {
            uploader: {
                async uploadByFile(file: any) {

                    try {

                        const token = localStorage.getItem('authToken')

                        if (!token) return

                        const formData = new FormData();
                        formData.append('file', file);

                        const headers = {
                            'Content-Type': 'multipart/form-data',
                            't-ownerstally': token ?? '',
                        }

                        const res = await axios.post(uploadFile , formData, {headers})

                        return {
                            success: 1,
                            file: {
                                title: res.data.user.name_file,
                                name:res.data.user.name_file,
                                url: res.data.user.url,
                            }
                        }

                    } catch (e: any) {
                        console.error(e)
                    }

                }
            }
        }
    },
    linkTool: {
        class: LinkTool,
        config: {
            endpoint: 'http://localhost:8008/fetchUrl', // Your backend endpoint for url data fetching,
        },
    },
    image: {
        class: Image,
        inlineToolbar: true,
        config: {
            uploader: {
                async uploadByFile(file: any) {

                    try {

                        const token = localStorage.getItem('authToken')

                        if (!token) return

                        const formData = new FormData();
                        formData.append('file', file);

                        const headers = {
                            'Content-Type': 'multipart/form-data',
                            't-ownerstally': token ?? '',
                        }

                        const res = await axios.post(uploadFile , formData, {headers})

                        return {
                            success: 1,
                            file: {
                                url: res.data.user.url
                            }
                        }

                    } catch (e: any) {
                        console.error(e)
                    }

                }
            }
        }
    },
    raw: Raw,
    header: Header,
    quote: Quote,
    marker: Marker,
    checklist: CheckList,
    delimiter: Delimiter,
    inlineCode: InlineCode,
    simpleImage: SimpleImage,
}