import React, {useRef, useState} from 'react';
import OwnersTallyLogo from '../../../../assets/icons/logo_colored.svg'
import {useNavigate} from "react-router-dom";
import {Editor} from "@tinymce/tinymce-react";
import logo from "../../../../assets/icons/logo_colored.svg";
import {createReactEditorJS} from "react-editor-js";
import {createNewDocumentAPI} from "../../../../api/budgetController";
import {MyCommunityCategories} from "../../../../utils/MyCommunityCategories";
import {EDITOR_JS_TOOLS} from "../../../../utils/editor-tools/editorTools";


function CommunicationNewTemplate() {

    const navigate = useNavigate()
    const ReactEditorJS = createReactEditorJS()

    const [documentTitle, setDocumentTitle] = useState<string>('')
    const editorCore = React.useRef(null)

    //TODO: INITIALIZE EDITOR
    const handleInitialize = React.useCallback((instance: any) => {
        editorCore.current = instance
    }, [])

    //TODO: SAVE CURRENT DATA FROM EDITOR
    const getCurrentDocumentData = React.useCallback(async () => {
        // @ts-ignore
        return await editorCore.current.save()

    }, [])

    const createNewDocumentFn = async () => {

        if (documentTitle.length <= 3) {
            console.warn('Document title is empty')
            return
        }

        const editorData = await getCurrentDocumentData()

        try {

            const res = await createNewDocumentAPI(documentTitle, MyCommunityCategories.Communication, editorData)

            if (res.ok === true){
                handleBack()
            }

        } catch (e: any) {
            console.error(e)
        }

    }

    const handleBack = ()=>{
        navigate("/communication")
    }

    return (
        <div>
            <div className="px-5 py-5">

                {/*TOOLBAR*/}
                <div className="bg-white flex flex-row justify-between items-center">

                    <button onClick={() => handleBack()} className="text-[#A8A8A8] font-semibold">
                        Cancel
                    </button>

                    <img src={logo} className="w-32" alt="OwnersTally"/>

                    <button onClick={() => createNewDocumentFn()} className="text-[#1D47B1] font-semibold">
                        Save
                    </button>

                </div>

                <div className="mt-5 text-start">
                    <div className="w-full lg:w-1/2 mx-auto md:px-16 lg:px-2 mb-5">
                        <input type="text"
                               className="outline-none font-medium text-lg placeholder:text-lg placeholder:font-medium w-full"
                               placeholder="Document title"
                               value={documentTitle}
                               onChange={(e: any) => setDocumentTitle(e.target.value)}
                               maxLength={60}/>
                    </div>
                    <ReactEditorJS onInitialize={handleInitialize}
                                   tools={EDITOR_JS_TOOLS}
                                   defaultValue={{
                                       time: 1635603431943,
                                       blocks: [
                                           {
                                               id: "IpKh1dMyC6",
                                               type: "paragraph",
                                               data: {
                                                   text: "",
                                               }
                                           },

                                       ]
                                   }}
                    />
                </div>

            </div>
        </div>
    );
}

export default CommunicationNewTemplate;