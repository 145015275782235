import React from 'react';

function ItemHomePressed() {
    return (
        <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36.01 36"
        >
            <path
                d="M24.23 7.88a3.375 3.375 0 0 0-4.54 0l-2.52 2.32-.85-.78c-1.27-1.17-3.27-1.17-4.55 0l-8.71 8.02a.8.8 0 0 0-.05 1.12.8.8 0 0 0 1.12.05l.63-.58v9.17c0 1 .81 1.8 1.8 1.8h14.98c.9 0 1.65-.67 1.78-1.54h6.13c1 0 1.8-.81 1.8-1.8v-9.17l.63.58c.15.14.34.21.53.21.21 0 .43-.09.58-.25.3-.32.27-.82-.05-1.12l-8.71-8.03ZM12.1 27.42l.07-6.45h3.76l.08.08v6.36H12.1Zm9.67-.23c0 .12-.1.23-.23.23h-3.95v-6.36c0-.91-.74-1.66-1.66-1.66h-3.76c-.91 0-1.66.74-1.66 1.66v6.36H6.56c-.12 0-.23-.1-.23-.23V16.57l6.51-6c.67-.62 1.73-.62 2.41 0l6.51 6v10.62Zm7.91-1.54c0 .12-.1.23-.23.23h-6.11v-7.86l.63.58c.15.14.34.21.53.21.21 0 .43-.09.58-.25.3-.32.27-.82-.05-1.12l-6.7-6.17 2.42-2.23c.68-.62 1.73-.62 2.41 0l6.51 5.99v10.62Z"
                style={{
                    fill: "#1D47B1",
                }}
            />
        </svg>
    );
}

export default ItemHomePressed;