import validator from 'validator'
import moment from "moment";

export const validateEmail = (value: string): Boolean => {
    return validator.isEmail(value);
}

export function formatDocumentDate(inputDate: string): string {
    return moment(inputDate).format('DD.MM.YYYY');
}

export function checkIfTokenIsExpired (tokenExpireTimestamp: number):Boolean {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return currentTimestamp > tokenExpireTimestamp;
}