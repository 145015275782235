import React, {useState} from 'react';
import logo from "../../assets/icons/logo_colored.svg";
import GoBack from "../../components/bottoms/goBack";
import {useNavigate} from "react-router-dom";
import InviteIllustration from "../../assets/svg/invite/invite.svg";
import {CgSpinner} from "react-icons/cg";
import GoogleMapReact from "google-map-react";
import {HiOutlineMapPin, HiOutlineUser, HiOutlineUserGroup} from "react-icons/hi2";


const options = {
    clickableIcons: false,
    draggable: false,
    scrollwheel: false,
    zoomControl: false,
    gestureHandling: "cooperative",
    maxZoom: 14,
    minZoom: 14,
}

function InviteCommunityPage() {

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState<boolean>(false)

    //Community
    const [communityName, setCommunityName] = useState<string>('Invitation community name')
    const [latitude, setLatitude] = useState<number>(23.113592)
    const [longitude, setLongitude] = useState<number>(-82.366592)

    const renderMarkers = (map: any, maps: any) => {

        const lat = latitude
        const lng = longitude

        // Crea un círculo alrededor de las coordenadas
        const circle = new maps.Circle({
            strokeColor: '#1D47B1',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#09309a',
            fillOpacity: 0.15,
            map,
            center: {lat, lng},
            radius: 1000, // Radio en metros
        });

        // Agrega un texto en el centro del círculo
        const text = new maps.Marker({
            position: {lat, lng},
            map,
            label: {
                text: communityName,
                color: '#1D47B1',
                fontSize: '14px',
                fontWeight: 'bold',
            },
            icon: {
                url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAACCAIAAADwyuo0AAAAEklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==',
                size: new maps.Size(1, 1),
                origin: new maps.Point(0, 0),
                anchor: new maps.Point(0, 0),
            },
        });

        // Opcional: Centra el mapa en el círculo
        map.setCenter({lat, lng});
    };

    const onMapLoaded = (map: any, maps: any) => {
        renderMarkers(map, maps);
    };

    if (isLoading) {
        return (
            <div className="w-full h-screen flex justify-center items-center">
                <CgSpinner className="animate-spin w-6 h-6 text-dark-blue"/>
            </div>
        )
    }

    return (
        <div className="w-full 2xl:container mx-auto">

            <div className="relative w-full h-screen hidden lg:block ">

                <div style={{height: '100vh', width: '100%'}} className="w-full h-screen">

                    <GoogleMapReact
                        bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_KEY!!}}
                        defaultCenter={{
                            lat: latitude,
                            lng: longitude,
                        }}
                        defaultZoom={14}
                        onGoogleApiLoaded={({map, maps}) => onMapLoaded(map, maps)}
                        options={options}
                        yesIWantToUseGoogleMapApiInternals={true}
                    >

                    </GoogleMapReact>


                </div>

                <div className="w-full h-screen bg-black/20 absolute top-0"/>

                <div className="absolute top-0 w-full bg-dark-blue py-3">
                    <p className="text-white">{communityName}</p>
                </div>

                <div className="absolute w-full lg:w-1/3 top-0 right-3 py-3 px-5 bg-white rounded-lg shadow-lg  translate-y-1/2">

                    <h4 className="font-semibold text-lg">Invitation Details</h4>

                    <div className="mt-5 text-start flex flex-col">

                        <p className="text-sm font-semibold">Invited by: Lorem ipsum</p>

                        <div className="flex flex-grow h-[1px] bg-gray-300 my-3"/>

                        <div>
                            <p className="text-sm">Community Owner</p>

                            <div className="mt-1 flex flex-row space-x-2 items-center">
                                <HiOutlineUser size={19}/>
                                <p className="text-sm text-gray-600">Pablo Antonio Lorem</p>
                            </div>
                        </div>


                        <div className="mt-3">
                            <p className="text-sm">Members in community</p>

                            <div className="mt-1 flex flex-row space-x-2 items-center">
                                <HiOutlineUserGroup size={19}/>
                                <p className="text-sm text-gray-600">20 members</p>
                            </div>
                        </div>

                        <div className="mt-3">
                            <p className="text-sm">Community Address</p>

                            <div className="mt-1 flex flex-row space-x-2 items-center">
                                <HiOutlineMapPin size={19}/>
                                <p className="text-sm text-gray-600">Lorem ipsum dolor sit</p>
                            </div>
                        </div>

                        <button className="mt-5 bg-accent hover:bg-accent/80 rounded-lg text-white font-semibold w-full py-2.5 text-sm">Continue</button>

                    </div>


                </div>


            </div>

            <div className="mt-3 block lg:hidden w-full py-3 px-5">

                <h4 className="font-semibold text-lg">Invitation Details</h4>

                <div className="mt-5 text-start flex flex-col">

                    <p className="text-sm font-semibold">Invited by: Lorem ipsum</p>

                    <div className="flex flex-grow h-[1px] bg-gray-300 my-3"/>

                    <div>
                        <p className="text-sm">Community Owner</p>

                        <div className="mt-1 flex flex-row space-x-2 items-center">
                            <HiOutlineUser size={19}/>
                            <p className="text-sm text-gray-600">Pablo Antonio Lorem</p>
                        </div>
                    </div>


                    <div className="mt-3">
                        <p className="text-sm">Members in community</p>

                        <div className="mt-1 flex flex-row space-x-2 items-center">
                            <HiOutlineUserGroup size={19}/>
                            <p className="text-sm text-gray-600">20 members</p>
                        </div>
                    </div>

                    <div className="mt-3">
                        <p className="text-sm">Community Address</p>

                        <div className="mt-1 flex flex-row space-x-2 items-center">
                            <HiOutlineMapPin size={19}/>
                            <p className="text-sm text-gray-600">Lorem ipsum dolor sit</p>
                        </div>
                    </div>

                    <button className="mt-5 bg-accent hover:bg-accent/80 rounded-lg text-white font-semibold w-full py-2.5 text-sm">Continue</button>

                </div>
            </div>

        </div>
    );
}

export default InviteCommunityPage;