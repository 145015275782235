import React, {createContext, ReactNode, useEffect, useState} from "react";
import jwt_decode from "jwt-decode";
import {UserRolType} from "../types/UserRolType";
import axios from "axios";
import {userData} from "../utils/ConstStrings";
import {TokenDataType} from "../types/TokenDataType";
import {checkIfTokenIsExpired} from "../utils/TextUtils";

const Context = createContext({})

interface Props {
    children: ReactNode
}

export interface AuthUser {
    isAuth: boolean,
    id: number,
    uuid: string,
    name: string,
    avatar: string,
    roles: UserRolType[],
}


export interface UserContextType {
    auth: AuthUser
    setAuth: (value: AuthUser) => void
}


export function AuthContextProvider({children}: Props) {

    const MINUTE_MS = 600000;

    const [auth, setAuth] = useState<AuthUser>()

    const userToken = localStorage.getItem("authToken")

    const headers = {
        'Authorization': 'Bearer ' + userToken,
    };

    useEffect(() => {

        if (userToken !== null) {

            const decoded: TokenDataType = jwt_decode(userToken);

            // Check if token is expired
            const isExpired = checkIfTokenIsExpired(decoded.exp)

            if (isExpired) {
                localStorage.removeItem("authToken")
                window.location.href = "/signin"
            }

            getUserData(decoded.uid)

        } else {

            const userData: AuthUser = {
                isAuth: false,
                id: 0,
                uuid: 'undefined',
                name: "undefined",
                avatar: "undefined",
                roles: [],
            }

            setAuth(userData)
        }

    }, [])

    useEffect(() => {

        if (auth?.isAuth) return

        if (!userToken) return

        const decoded: TokenDataType = jwt_decode(userToken);

        // Check if token is expired
        const isExpired = checkIfTokenIsExpired(decoded.exp)

        if (isExpired) {
            localStorage.removeItem("authToken")
            window.location.href = "/signin"
        }

        const interval = setInterval(() => {

            if (userToken !== null) {

                try {

                    getUserData(decoded.uid)

                } catch (error: any) {

                    //TODO:// REMOVE LOG LATER ITS ONLY FOR DEV TESTING
                    console.error(error)

                }
            }

        }, MINUTE_MS);

        return () => clearInterval(interval);

    }, [])

    const getUserData = async (uuid: string) => {

        try {

            const res = await axios.get(userData + uuid, {headers})

            const {user} = res.data

            if (user) {

                const userData: AuthUser = {
                    isAuth: true,
                    id: 0,
                    uuid: user.uuid,
                    name: user.firstname + ' ' + user.lastname,
                    avatar: user.avatar ?? 'https://sbcf.fr/wp-content/uploads/2018/03/sbcf-default-avatar.png',
                    roles: user.userRolModel.rolModels,
                }

                setAuth(userData)

                //TODO: Remove later its only for testing
                console.log(res.data)

            } else {

                const userData: AuthUser = {
                    isAuth: false,
                    id: 0,
                    uuid: 'undefined',
                    name: "undefined",
                    avatar: "undefined",
                    roles: [],
                }

                setAuth(userData)

                //TODO: REMOVE LATER ITS ONLY FOR DEV TESTING
                console.error(res.data)
            }

        } catch (error: any) {

            //TODO: TO TESTING REMOVE IT LATER
            console.error(error)

            if (error.status === 401) {
                localStorage.clear()
                window.location.href = "/signin"
            }
        }
    }

    return (
        <Context.Provider value={{auth, setAuth}}>
            {children}
        </Context.Provider>
    );
}

export default Context;