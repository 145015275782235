import React, {useState} from 'react';
import BackArrow from "../../../components/svg/backArrow";

function ChangePassword() {

    const [showPassword, setShowPassword] = useState<boolean>(false)

    const handleUpdatePassword = (e: any) => {
        e.preventDefault()

        console.log("Validate and update user password")
    }

    return (
        <div className="w-full">

            {/* MOBILE */}
            <div className="block lg:hidden  px-5 py-4">

                {/* TOOLBAR */}
                <div className="flex justify-between ">
                    <button>
                        <BackArrow/>
                    </button>

                    <h2 className="font-semibold">Change password</h2>

                    <button className="font-semibold text-[#1D47B1]">
                        Save
                    </button>
                </div>

                <div className="mt-5 flex justify-center items-center">
                    <span className="text-sm text-[#707070]">Create a password that has al least 8 characters</span>
                </div>

                <form className="flex flex-col space-y-5 mt-8 text-start"
                      onSubmit={(e: any) => handleUpdatePassword(e)}>

                    <div>
                        <label className="uppercase text-xs text-[#3C3C43]">enter current password</label>
                        <input type="password" name="current_password"
                               className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="Current password"/>

                    </div>

                    <div>
                        <label className="uppercase text-xs text-[#3C3C43]">write your new password</label>
                        <input type="password" name="new_password"
                               className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                               placeholder="New password"/>
                    </div>

                    <div>
                        <label className="uppercase text-xs text-[#3C3C43]">Confirm your new password </label>
                        <div className="relative">

                            <label>
                                <input placeholder="Confirm new password" type={`${showPassword ? 'text' : 'password'}`}
                                       name="confirm_new_password"
                                       className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                                       required/>
                            </label>

                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center rounded-full p-2.5">

                                <svg
                                    className={`${showPassword ? 'h-6 w-6 text-dark-blue hidden ' : 'h-6 w-6 text-dark-blue  block '}`}
                                    onClick={() => setShowPassword(!showPassword)} fill="none"
                                    viewBox="0 0 23.14 14.52" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none" stroke="#1d47b1" strokeMiterlimit="10" strokeWidth="1.5">
                                        <path d="m.63 6.74a13 13 0 0 1 21.88 0"/>
                                        <circle cx="11.57" cy="9.68" r="4.1"/>
                                    </g>
                                </svg>


                                <svg viewBox="0 0 23.14 18.32" xmlns="http://www.w3.org/2000/svg"
                                     className={`${showPassword ? 'h-6 w-6 text-dark-blue block ' : 'h-6 w-6 text-dark-blue hidden'}`}
                                     onClick={() => setShowPassword(!showPassword)}>
                                    <g fill="none" stroke="#1d47b1" strokeMiterlimit="10" strokeWidth="1.5">
                                        <path d="m.63 7.68a13 13 0 0 1 21.88 0"/>
                                        <circle cx="11.57" cy="10.62" r="4.1"/>
                                        <path d="m4.06 17.79 17.26-17.26"/>
                                    </g>
                                </svg>

                            </div>
                        </div>
                    </div>

                </form>

                <div className="mt-5 flex justify-center items-center">
                    <button className="text-[#1D47B1]">Have you forgotten your password?</button>
                </div>

            </div>

            {/* DESKTOP */}
            <div className="2xl:container hidden lg:block lg:pt-24 w-full lg:w-1/2 mx-auto">
                <div className="bg-white h-auto lg:border rounded-lg pb-6">

                    <div>

                        <div className="flex justify-between items-center bg-white  rounded-t-lg pt-5 pb-3 px-10">

                            <h2 className="text-start text-[#3C3C43] font-semibold text-2xl">Change Password</h2>

                            <div className="flex space-x-5 justify-center items-center flex-row">
                                <button className="py-2.5 uppercase font-semibold text-[#A8A8A8] text-sm">Cancel</button>
                                <button className="py-2.5 text-[#1D47B1] font-semibold uppercase text-sm">Save</button>
                            </div>

                        </div>

                        <div className="h-0.5 bg-gray-200 flex-grow mx-10"/>

                        <div className="px-10 mx-auto">
                            <form className="flex flex-col space-y-5 mt-8 text-start"
                                  onSubmit={(e: any) => handleUpdatePassword(e)}>

                                <div>
                                    <label className="uppercase text-xs text-[#3C3C43]">enter current password</label>
                                    <input type="password" name="current_password"
                                           className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                                           placeholder="Current password"/>

                                </div>

                                <div>
                                    <label className="uppercase text-xs text-[#3C3C43]">write your new password</label>
                                    <input type="password" name="new_password"
                                           className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                                           placeholder="New password"/>
                                </div>

                                <div>
                                    <label className="uppercase text-xs text-[#3C3C43]">Confirm your new
                                        password </label>
                                    <div className="relative">

                                        <label>
                                            <input placeholder="Confirm new password"
                                                   type={`${showPassword ? 'text' : 'password'}`}
                                                   name="confirm_new_password"
                                                   className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                                                   required/>
                                        </label>

                                        <div
                                            className="absolute inset-y-0 right-0 pr-3 flex items-center rounded-full p-2.5">

                                            <svg
                                                className={`${showPassword ? 'h-6 w-6 text-dark-blue hidden ' : 'h-6 w-6 text-dark-blue  block '}`}
                                                onClick={() => setShowPassword(!showPassword)} fill="none"
                                                viewBox="0 0 23.14 14.52" xmlns="http://www.w3.org/2000/svg">
                                                <g fill="none" stroke="#1d47b1" strokeMiterlimit="10" strokeWidth="1.5">
                                                    <path d="m.63 6.74a13 13 0 0 1 21.88 0"/>
                                                    <circle cx="11.57" cy="9.68" r="4.1"/>
                                                </g>
                                            </svg>


                                            <svg viewBox="0 0 23.14 18.32" xmlns="http://www.w3.org/2000/svg"
                                                 className={`${showPassword ? 'h-6 w-6 text-dark-blue block ' : 'h-6 w-6 text-dark-blue hidden'}`}
                                                 onClick={() => setShowPassword(!showPassword)}>
                                                <g fill="none" stroke="#1d47b1" strokeMiterlimit="10" strokeWidth="1.5">
                                                    <path d="m.63 7.68a13 13 0 0 1 21.88 0"/>
                                                    <circle cx="11.57" cy="10.62" r="4.1"/>
                                                    <path d="m4.06 17.79 17.26-17.26"/>
                                                </g>
                                            </svg>

                                        </div>
                                    </div>
                                </div>

                            </form>

                            <div className="mt-8 flex justify-center items-center">
                                <button className="text-[#1D47B1]">Have you forgotten your password?</button>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    );

}

export default ChangePassword;