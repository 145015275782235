import React from 'react';

function FileAttachment() {
    return (
        <button className="flex flex-row space-x-3 items-center bg-[#F3F6FF]  rounded-lg p-1">

            <div className="bg-white w-12 h-12 rounded-lg flex justify-center items-center">

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7 text-[#1D47B1]">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>

            </div>

            <div className="flex flex-col text-start px-1">
                <h5 className="text-sm font-semibold text-[#3C3C43]">Name of document</h5>
                <span className="text-xs text-[#3C3C43]">.pdf</span>
            </div>

        </button>
    );
}

export default FileAttachment;