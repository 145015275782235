import React from 'react';
import BackArrow from "../../../../components/svg/backArrow";

function GroupUserDetails() {
    return (
        <div>

            {/*MOBILE*/}
            <div className="block lg:hidden">

                {/*TOOLBAR*/}
                <div className="bg-white h-14 flex flex-row justify-between items-center px-5">

                    <button className="text-[#A8A8A8] font-semibold">
                        <BackArrow/>
                    </button>

                </div>

                {/*CONTENT*/}
                <div>
                    <div className="flex justify-center items-center">
                        <img
                            className="w-24 h-24 rounded-full"
                            src="https://pps.whatsapp.net/v/t61.24694-24/328167575_1892722051085683_2774774184522776646_n.jpg?ccb=11-4&oh=01_AdT-KRIC-Aq_JZUDWhmB32CNjake8WTmqFPbvBVNEldNfw&oe=641352D2"
                            alt="User avatar"/>
                    </div>

                    <div className="text-start px-5 my-5 flex flex-col space-y-3">
                       <div>
                           <h5 className="text-[#3C3C43] font-semibold">Name</h5>
                           <span className="text-[#3C3C43] ">Liset Seara</span>
                       </div>

                        <div>
                            <h5 className="text-[#3C3C43] font-semibold">House number</h5>
                            <span className="text-[#3C3C43]">1234</span>
                        </div>

                        <div>
                            <h5 className="text-[#3C3C43] font-semibold">Email</h5>
                            <span className="text-[#3C3C43]">lisetseara@ownerstally.com</span>
                        </div>

                        <div>
                            <h5 className="text-[#3C3C43] font-semibold">Cellphone number</h5>
                            <span className="text-[#3C3C43] ">5123123123123</span>
                        </div>

                        <div>
                            <h5 className="text-[#3C3C43] font-semibold">Category</h5>
                            <span className="text-[#3C3C43]">Owner</span>
                        </div>

                        <div>
                            <h5 className="text-[#3C3C43] font-semibold">HOA Position</h5>
                            <span className="text-[#3C3C43]">None</span>
                        </div>

                    </div>
                </div>

            </div>

            {/*DESKTOP*/}
            <div className="2xl:container mx-auto hidden lg:block">
               <div className="w-1/2 mx-auto my-5 border rounded-lg">
                   {/*CONTENT*/}
                   <div>
                       <div className="mt-5 flex justify-center items-center">
                           <img
                               className="w-24 h-24 rounded-full"
                               src="https://pps.whatsapp.net/v/t61.24694-24/341020284_240842455271489_5524469025419759516_n.jpg?ccb=11-4&oh=01_AdRD1hg7n-XBUMc4hd5bdIsOsTGhzR1AWYwdAdZhzKqCTQ&oe=644A9A32"
                               alt="User avatar"/>
                       </div>

                       <div className="text-start px-5 my-5 flex flex-col space-y-3">
                           <div>
                               <h5 className="text-[#3C3C43] font-semibold">Name</h5>
                               <span className="text-[#3C3C43] ">Liset Seara</span>
                           </div>

                           <div>
                               <h5 className="text-[#3C3C43] font-semibold">House number</h5>
                               <span className="text-[#3C3C43]">1234</span>
                           </div>

                           <div>
                               <h5 className="text-[#3C3C43] font-semibold">Email</h5>
                               <span className="text-[#3C3C43]">lisetseara@ownerstally.com</span>
                           </div>

                           <div>
                               <h5 className="text-[#3C3C43] font-semibold">Cellphone number</h5>
                               <span className="text-[#3C3C43] ">5123123123123</span>
                           </div>

                           <div>
                               <h5 className="text-[#3C3C43] font-semibold">Category</h5>
                               <span className="text-[#3C3C43]">Owner</span>
                           </div>

                           <div>
                               <h5 className="text-[#3C3C43] font-semibold">HOA Position</h5>
                               <span className="text-[#3C3C43]">None</span>
                           </div>

                       </div>
                   </div>
               </div>
            </div>

        </div>
    );
}

export default GroupUserDetails;