import React from 'react';

function ItemMyCommunity() {
    return (

        <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 35.98 36"
        >
            <path
                d="m27.85 14.59-7.11-6.51a4.041 4.041 0 0 0-5.5 0l-7.11 6.51-2.1 1.93a.806.806 0 1 0 1.09 1.19l.78-.72v10.1c0 1.06.86 1.91 1.91 1.91h16.36c1.05 0 1.91-.86 1.91-1.91v-10.1l.78.72a.799.799 0 0 0 1.13-.05c.3-.33.28-.84-.05-1.14l-2.1-1.93Zm-1.37 12.5a.3.3 0 0 1-.3.3h-5.57c2.56-1.91 3.93-4.15 3.63-6.16-.09-.76-.44-2.14-1.7-2.91-1.01-.6-2.24-.64-3.52-.07-.42.21-.76.46-1.02.72a3.94 3.94 0 0 0-1.07-.74c-1.23-.54-2.46-.51-3.47.1-1.26.76-1.6 2.15-1.7 2.89-.3 2.03 1.06 4.27 3.62 6.18H9.81a.31.31 0 0 1-.31-.3V15.52l6.82-6.25c.95-.87 2.38-.87 3.32 0l6.82 6.25v11.57Zm-8.49.1c-3.05-1.73-4.91-4-4.64-5.76.05-.38.23-1.3.93-1.73.3-.18.62-.25.93-.25.4 0 .77.12 1.06.24.57.28.89.73 1.03.97.14.25.41.41.7.41s.56-.16.7-.41c.14-.24.46-.69 1.02-.97.52-.23 1.29-.42 1.99 0 .71.43.89 1.35.94 1.75.26 1.74-1.59 4.01-4.65 5.74Z"
                style={{
                    fill: "#a8a8a8",
                }}
            />
        </svg>
    );
}

export default ItemMyCommunity;