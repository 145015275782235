import React from 'react';
import BackArrow from "../../../components/svg/backArrow";
import logo from "../../../assets/icons/logo_colored.svg";

function ViolationDetails() {
    return (
        <div>
            {/*MOBILE*/}
            <div className="px-5 block lg:hidden">

                <div className="flex justify-between items-center my-4">

                    <button className="text-[#A8A8A8] font-semibold">
                        Cancel
                    </button>

                    <img src={logo} className="w-32" alt="OwnersTally"/>

                    <button className="text-[#1D47B1] font-semibold">
                        Send
                    </button>

                </div>

                <h4 className="font-semibold text-lg text-[#3D3C44]">Complaint Template</h4>
                <span className="text-sm text-[#707070]">Written on 22 Oct 2023 </span>


                <div className="text-start flex flex-col space-y-3 py-5">
                    <div>
                        <h5 className="font-semibold text-[#3C3C43]">Written by</h5>
                        <p>Frank Thompson</p>
                    </div>

                    <div>
                        <h5 className="font-semibold text-[#3C3C43]">Name of person involved</h5>
                        <p>Elena Saldivar</p>
                    </div>

                    <div>
                        <h5 className="font-semibold text-[#3C3C43]">Number´s house</h5>
                        <p>345</p>
                    </div>

                    <div>
                        <h5 className="font-semibold  text-[#3C3C43]">Reference title</h5>
                        <p>pintura de puerta fuera de los parametros </p>
                    </div>

                    <div>
                        <h5 className="font-semibold  text-[#3C3C43]">Description</h5>
                        <p className="text-sm ">Lorem ipsum dolor sit amet, consectetur adipiscing.
                            Elitsed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                            veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui it in Lorem ipsum dolor sit
                            amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Ut enim ad minim veniam, quis nostrud ujij ojojo njn iijoi onjnk exercitation
                            ullamco laboris nisi ut aliqui it in Lorem ipsum dolor, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>

                    <img
                        src="https://images.unsplash.com/photo-1625602812206-5ec545ca1231?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YW1lcmljYW4lMjBob3VzZXN8ZW58MHx8MHx8&w=1000&q=80"
                        className="w-full rounded-lg"
                        alt=""/>

                </div>


            </div>

            {/*DESKTOP*/}
            <div className="2xl:container hidden lg:block w-1/2 mx-auto  mt-5">

                <div className="hidden lg:flex justify-start items-start mt-5">
                    <h2 className="font-semibold text-2xl text-[#1D47B1]">Violation</h2>
                </div>

                <div className="border rounded-lg mt-5 p-5">

                    <div className="flex justify-between items-center">

                        <div>

                            <img src={logo} className="w-32 h-auto" alt="Ownerstally Logo"/>

                            <div className="mt-5 w-full flex flex-col justify-start items-start">
                                <h5 className="font-semibold mt-5">Written by</h5>
                                <p>Frank Thompson</p>

                                <h5 className="font-semibold mt-5">Name of person involved</h5>
                                <p>Elena Saldivar</p>

                            </div>

                        </div>

                        <div className="flex flex-col items-start">
                            <h3 className="text-[#3C3C43] font-semibold">Complaint Template</h3>
                            <p className="text-[#707070] text-sm">written on 22 Oct 2023</p>

                            <div className="mt-5 w-full flex flex-col justify-start items-start">
                                <h5 className="font-semibold">Number´s house</h5>
                                <p>345</p>

                                <h5 className="font-semibold mt-5">Reference title</h5>
                                <p>pintura de puerta fuera de los parametros</p>
                            </div>
                        </div>

                    </div>

                    <div className="text-start">
                        <h5 className="font-semibold mt-5">Description</h5>
                        <p className="mt-1">
                            Lorem ipsum dolor sit amet, consectetur adipiscing.
                            Elitsed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                            veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui it in Lorem ipsum dolor sit
                            amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Ut enim ad minim veniam, quis nostrud ujij ojojo njn iijoi onjnk exercitation
                            ullamco laboris nisi ut aliqui it in Lorem ipsum dolor, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor incididunt.
                        </p>
                    </div>

                    <div className="grid grid-cols-2 gap-4 mt-5 ">
                        <img
                            className="rounded-lg"
                            src="https://images.unsplash.com/photo-1625602812206-5ec545ca1231?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YW1lcmljYW4lMjBob3VzZXN8ZW58MHx8MHx8&w=1000&q=80"
                            alt=""
                        />

                        <img
                            className="rounded-lg"
                            src="https://images.unsplash.com/photo-1625602812206-5ec545ca1231?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YW1lcmljYW4lMjBob3VzZXN8ZW58MHx8MHx8&w=1000&q=80"
                            alt=""
                        />

                    </div>


                </div>

            </div>

        </div>
    );
}

export default ViolationDetails;