export const baseUrl = "https://api.ownerstally.com/"
export const ApiV1 = "api/v1"

export const communities = baseUrl + ApiV1 + "/community/"

export const communityAddress = baseUrl + ApiV1 + '/address/'

export const createUser = baseUrl + ApiV1 + '/user/add'

export const userData =  baseUrl + ApiV1 + '/user/'

export const createNewDocument = baseUrl + ApiV1 + '/documents'

export const updateDocument = baseUrl + ApiV1 + '/documents/'
export const deleteDocument = baseUrl + ApiV1 + '/documents/'

export const getDocumentByMonth = baseUrl + ApiV1 + '/documents/data?month='

export const getCategoryDocuments = baseUrl + ApiV1 + '/documents?category='

export const getDocument = baseUrl + ApiV1 + '/documents/'


export const uploadFile = baseUrl + ApiV1 + '/file/new'

export const userLogin= baseUrl + ApiV1 + '/login'

export const userRoles = baseUrl + ApiV1 + "/rol/"

export const GET_USER_IMAGE = baseUrl + 'storage/user/'