import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import GoBack from "../../components/bottoms/goBack";
import OTLogoColored from "../../assets/icons/logo_colored.svg"
import GoogleMapReact from 'google-map-react';
import {useLocation} from 'react-router-dom';
import {fetchCommunityCoordinates} from "../../utils/MapBoxGeocoding";
import {CgSpinner} from "react-icons/cg";
import {fetchCommunityAddress, updateCommunityCoordinates} from "../../api/communitiesController";
import {CommunityAddressType} from "../../types/CommunityAddressType";
import AuthContextProvider,{ UserContextType} from "../../providers/AuthContextProvider";

const options = {
    clickableIcons: false,
    draggable: false,
    scrollwheel: true,
    zoomControl: true,
    gestureHandling: "cooperative",
    maxZoom: 15,
    minZoom: 14
}

function RegisterMapPage() {

    const navigate = useNavigate();
    const [communityAddress, setCommunityAddress] = useState<CommunityAddressType>()
    const [latitude, setLatitude] = useState<number>(0)
    const [longitude, setLongitude] = useState<number>(0)

    const [isLoading, setIsLoading] = useState<boolean>(true)

    const location = useLocation();

    const {communityID, addressID, communityName} = location.state;

    const {auth} = useContext(AuthContextProvider) as UserContextType

    useEffect(()=>{
        if (!auth) return

        if (auth.isAuth)
            navigate('/main/announcements')
    },[])

    useEffect(() => {

        setIsLoading(false)

        getCommunityAddressData()
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });

    }, []);

    const handleGoSignUpUser = () => {
        navigate('/signup-user', {
            state: {
                "communityID": communityID,
            }
        });
    }

    const getCommunityAddressData = async () => {

        const result: CommunityAddressType = await fetchCommunityAddress(addressID)

        if (result) {

            setCommunityAddress(result)

            if (result.latitude !== "" && result.longitude !== "") {

                setLatitude(parseFloat(result.latitude))
                setLongitude(parseFloat(result.longitude))

            } else {

                //Get coordinates from address using mapbox geolocation
                const res = await fetchCommunityCoordinates(result.address)

                if (res) {

                    const addressLatitude = res.data.features[0]?.geometry?.coordinates[1];
                    const addressLongitude = res.data.features[0]?.geometry?.coordinates[0];

                    if (typeof addressLatitude !== 'number' || typeof addressLongitude !== 'number') {
                        console.error('Invalid latitude or longitude value:', addressLatitude, addressLongitude);
                        // Handle the error appropriately (e.g., show an error message to the user)
                        return setIsLoading(false)
                    }

                    // UPDATE DB COMMUNITY COORDINATES

                    const communityAddressIsUpdated = await updateCommunityCoordinates(addressID, addressLatitude, addressLongitude)

                    setLatitude(addressLatitude)
                    setLongitude(addressLongitude)

                } else {
                    // console.error('Error fetching ManageCommunity coordinates');
                    return navigate("/")
                }

            }

        } else {
            // console.error(result)
        }

    }


    const renderMarkers = (map: any, maps: any) => {

        const lat = latitude
        const lng = longitude

        // Crea un círculo alrededor de las coordenadas
        const circle = new maps.Circle({
            strokeColor: '#1D47B1',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#09309a',
            fillOpacity: 0.15,
            map,
            center: {lat, lng},
            radius: 1000, // Radio en metros
        });

        // Agrega un texto en el centro del círculo
        const text = new maps.Marker({
            position: {lat, lng},
            map,
            label: {
                text: communityName,
                color: '#1D47B1',
                fontSize: '14px',
                fontWeight: 'bold',
            },
            icon: {
                url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAACCAIAAADwyuo0AAAAEklEQVR4nGMAAQAABQABDQottAAAAABJRU5ErkJggg==',
                size: new maps.Size(1, 1),
                origin: new maps.Point(0, 0),
                anchor: new maps.Point(0, 0),
            },
        });

        // Opcional: Centra el mapa en el círculo
        map.setCenter({lat, lng});
    };

    const onMapLoaded = (map: any, maps: any) => {
        renderMarkers(map, maps);
    };


    if (isLoading || communityAddress === null || latitude === 0 || latitude === 0) {
        return (
            <>
                <div
                    className="hidden lg:flex fixed inset-x-0 z-40 bg-white h-16 w-full  justify-between items-center px-20">

                    <img src={OTLogoColored} className="w-44 h-auto" alt="Owners Tally Logo"/>

                    <h3 className="text-lg font-semibold text-[#707070]">{communityName}</h3>

                    <div className="flex flex-row space-x-8 items-center">
                        <p className="text-[#1D47B1]">It’s wrong? <button onClick={() => navigate(-1)}><span
                            className="underline font-semibold">Go Back</span></button></p>

                        <button onClick={() => handleGoSignUpUser()}
                                className="bg-[#1D47B1] text-white font-semibold px-8 py-2.5 rounded-full">NEXT
                        </button>
                    </div>

                </div>

                <div className="w-full h-screen flex justify-center items-center">
                    <CgSpinner className="animate-spin w-6 h-6 text-dark-blue"/>
                </div>
            </>
        )
    }

    return (
        <div className="w-full h-screen">

            <div
                className="hidden lg:flex fixed inset-x-0 z-40 bg-white h-16 w-full  justify-between items-center px-20">

                <img src={OTLogoColored} className="w-44 h-auto" alt="Owners Tally Logo"/>

                <h3 className="text-lg font-semibold text-[#707070]">{communityName}</h3>

                <div className="flex flex-row space-x-8 items-center">
                    <p className="text-[#1D47B1]">It’s wrong? <button onClick={() => navigate(-1)}><span
                        className="underline font-semibold">Go Back</span></button></p>

                    <button onClick={() => handleGoSignUpUser()}
                            className="bg-[#1D47B1] text-white font-semibold px-8 py-2.5 rounded-full">NEXT
                    </button>
                </div>

            </div>

            <div className="relative">

                <div className="absolute z-50 w-full block lg:hidden">

                    <div className="px-5 py-8 bg-white flex justify-between items-center">

                        <button onClick={() => navigate(-1)}
                                className="px-5 py-2 rounded-full text-[#1D47B1] font-semibold">
                            <GoBack/>
                        </button>

                        <button onClick={() => handleGoSignUpUser()}
                                className="bg-[#1D47B1] px-5 py-2 rounded-full text-white text-sm font-semibold">NEXT
                        </button>
                    </div>
                </div>

                <div className="absolute top-24 w-full block lg:hidden">
                    <div className="flex justify-start items-start bg-[#173FA5] px-5 py-2 ">
                        <p className="text-white">{communityName}</p>
                    </div>
                </div>

                {/*MAP HERE*/}
                <div style={{height: '100vh', width: '100%'}} className="w-full h-full absolute">

                    <GoogleMapReact
                        bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_KEY!!}}
                        defaultCenter={{
                            lat: latitude,
                            lng: longitude,
                        }}
                        defaultZoom={14}
                        onGoogleApiLoaded={({map, maps}) => onMapLoaded(map, maps)}
                        options={options}
                        yesIWantToUseGoogleMapApiInternals={true}
                    >

                    </GoogleMapReact>
                </div>

            </div>
        </div>
    );
}

export default RegisterMapPage;