import React from 'react';
import CommunityIcon from '../../assets/svg/committees/committees.svg'
import CommunitySelectorItem from "../../components/community/CommunitySelectorItem";
function SelectCurrentCommunity() {
    return (
        <div className="container w-full lg:w-1/2 mx-auto py-5 px-5">

            <h1 className="mt-5 text-2xl font-semibold">Hi, Username</h1>
            <h1 className="text-3xl font-semibold">Select a community to continue</h1>

            <div className="mt-10 grid grid-cols-2 md:grid-cols-3 gap-8">
                <CommunitySelectorItem/>
                <CommunitySelectorItem/>
                <CommunitySelectorItem/>
                <CommunitySelectorItem/>
                <CommunitySelectorItem/>
                <CommunitySelectorItem/>
            </div>
        </div>
    );
}

export default SelectCurrentCommunity;