import React, {useEffect, useRef, useState} from 'react';
import {
    HiChevronDown,
    HiOutlineArrowDownOnSquare,
    HiOutlineEye, HiOutlineLink,
    HiOutlinePlusCircle,
    HiOutlineTrash, HiOutlineUserPlus
} from "react-icons/hi2";
import {useNavigate} from "react-router-dom";

function ManageCommunityMembersPage() {

    const navigate = useNavigate()

    const [showMenu, setShowMenu] = useState<boolean>(false)
    const menuRef = useRef<any>(null);

    useEffect(() => {
        // only add the event listener when the dropdown is opened
        if (!showMenu) return;

        function handleClick(event: any) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(!showMenu)
            }
        }

        window.addEventListener("click", handleClick);
        // clean up
        return () => window.removeEventListener("click", handleClick);
    }, [showMenu]);

    const handleInviteCommunityMember = () => {
        setShowMenu(!showMenu)
    }

    return (
        <>
            <div className="mt-6 flex justify-between items-center">

                <h1 className="text-2xl font-semibold">Community Members</h1>

                <div className="relative">
                    <button ref={menuRef} onClick={() => handleInviteCommunityMember()}
                            className="flex flex-row items-center space-x-2 text-sm text-white bg-accent hover:accent/30 px-3 py-2.5 rounded-lg transition">
                        <span>Invite new member</span>
                        <HiChevronDown className="" size={18}/>
                    </button>


                    {showMenu && <div
                        className="absolute top-12  w-full bg-white rounded-lg shadow text-sm py-3 justify-start items-start flex flex-col divide-y">

                        <button onClick={() => navigate('/invite/members/csv')}
                            className="py-2 hover:bg-accent transition w-full flex flex-row space-x-2 px-3 hover:text-white">
                            <HiOutlineArrowDownOnSquare className="" size={18}/>
                            <span>Import from csv</span>
                        </button>

                        <button onClick={() => navigate('/invite/members/excel')}
                            className="py-2 hover:bg-accent transition w-full flex flex-row space-x-2 px-3 hover:text-white">
                            <HiOutlineArrowDownOnSquare className="" size={18}/>
                            <span>Import from excel</span>
                        </button>

                        <button
                            className="hidden py-2 hover:bg-accent transition w-full flex flex-row space-x-2 px-3 hover:text-white">
                            <HiOutlineUserPlus className="" size={18}/>
                            <span>Add member</span>
                        </button>

                        <button
                            className="py-2 hover:bg-accent transition w-full flex flex-row space-x-2 px-3 hover:text-white">
                            <HiOutlineLink className="" size={18}/>
                            <span>Invite link</span>
                        </button>

                    </div>}
                </div>
            </div>


            <div className="mt-5">
                <table className="bg-dark-blue rounded-t-lg w-full whitespace-nowrap">
                    <thead>
                    <tr className="h-10 w-full text-sm leading-none text-white">
                        <th className="font-normal text-start px-5">Name</th>
                        <th className="font-normal  px-5">Role</th>
                        <th className="font-normal  px-5">Member since</th>
                        <th className="font-normal  px-5 text-right">Actions</th>
                    </tr>
                    </thead>
                    <tbody className="w-full bg-white">
                    <tr className="h-20 text-sm leading-none">
                        <td className="pl-0 lg:pl-4">
                            <div className="flex items-center">
                                <div className="w-15 h-14 hidden lg:block">
                                    <img className="w-full h-full object-cover object-center rounded"
                                         src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8YXZhdGFyfGVufDB8MHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60"
                                         alt=""/>
                                </div>
                                <div className="pl-4 text-start">
                                    <p className="font-medium">Samantha Ipsum</p>
                                    <p className="text-xs leading-3 text-gray-600 pt-2 line-clamp-1">Extra member
                                        data</p>
                                </div>
                            </div>
                        </td>
                        <td className="px-5 text-center">
                            <p className="text-sm leading-none text-gray-600">Owner</p>
                        </td>

                        <td className="px-5 text-center text-gray-600">
                            20 oct, 2023
                        </td>

                        <td className="px-5">
                            <div className="flex flex-row space-x-3 items-center justify-end">

                                <button
                                    className="flex justify-center items-center p-2 rounded bg-accent hover:bg-accent/50">
                                    <HiOutlineEye className="text-white" size={20}/>
                                </button>


                                <button
                                    className="flex justify-center items-center p-2 rounded bg-accent hover:bg-accent/50">
                                    <HiOutlineTrash className="text-white" size={20}/>
                                </button>

                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default ManageCommunityMembersPage;