import React from 'react';
import {useNavigate} from "react-router-dom";

function NewFamilyMember() {

    const navigate = useNavigate()

    const handleOnSaveClick = () => {
        console.log("Save new member")
    }

    return (
        <div>

            {/*MOBILE*/}
            <div className="block lg:hidden px-5 mt-5">

                {/*TOOLBAR*/}
                <div className="flex justify-between items-center">
                    <button onClick={() => navigate(-1)} className="font-medium text-[#A8A8A8]">Cancel</button>
                    <h2 className="font-semibold">new family member</h2>
                    <button onClick={ ()=> handleOnSaveClick() } className="text-[#1D47B1] font-medium">Save</button>
                </div>

                {/*CONTENT*/}
                <div className="text-start">
                    <div className=" mt-5 flex flex-col space-y-2">
                        <label className="text-sm">Name</label>

                        <input type="text"
                               className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                               placeholder="Name"/>
                    </div>

                    <div className="mt-5 flex flex-col space-y-2">
                        <label className="text-sm">Age</label>

                        <input type="number"
                               className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                               placeholder="Age"/>
                    </div>

                    <div className="mt-5 flex flex-col space-y-2">
                        <label className="text-sm">Gender</label>

                        <select id="hoa_position" className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg px-3">
                            <option defaultChecked>Select member gender</option>
                            <option value="FEMALE">Female</option>
                            <option value="MALE">Male</option>
                            <option value="MALE">Another one</option>
                        </select>
                    </div>

                </div>

            </div>

            {/*DESKTOP*/}
            <div className="hidden lg:block 2xl:container mx-auto">
                <div className="w-1/2 mx-auto border rounded-lg p-10 my-5">
                    {/*CONTENT*/}
                    <div className="text-start">
                        <div className="flex flex-col space-y-2">
                            <label className="text-sm">Name</label>

                            <input type="text"
                                   className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                   placeholder="Name"/>
                        </div>

                        <div className="mt-5 flex flex-col space-y-2">
                            <label className="text-sm">Age</label>

                            <input type="number"
                                   className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                   placeholder="Age"/>
                        </div>

                        <div className="mt-5 flex flex-col space-y-2">
                            <label className="text-sm">Gender</label>

                            <select id="hoa_position" className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg px-3">
                                <option defaultChecked>Select member gender</option>
                                <option value="FEMALE">Female</option>
                                <option value="MALE">Male</option>
                                <option value="MALE">Another one</option>
                            </select>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default NewFamilyMember;