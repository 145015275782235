import React from 'react';
import logo from "../../../../assets/icons/logo_colored.svg";
import BackArrow from "../../../../components/svg/backArrow";
import {useNavigate} from "react-router-dom";
import Slider from "react-slick";
import MeetingTemplateItem from "../../../../components/meetings/MeetingTemplateItem";

function Violations() {

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 8,
        initialSlide: 0,
        arrows: true,
        className: "slider variable-width",
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                    infinite: false,
                    dots: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: false,
                    dots: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: false,
                    arrows: false,
                }
            }
        ]
    };

    const navigate = useNavigate()


    return (
        <div className="2xl:container px-5 mx-auto">


            <div className="bg-white h-14 flex flex-row justify-between items-center">

                <button onClick={() => navigate(-1)} className="text-[#1D47B1] block lg:hidden">
                    <BackArrow/>
                </button>

                <div className='flex flex-row justify-center items-center'>
                    <button onClick={() => navigate("/main/my-ManageCommunity")}
                            className="hidden border rounded-full p-2 h-8 w-8 mr-2 lg:flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
                        </svg>
                    </button>

                    <h2 className="font-semibold lg:text-2xl text-[#1D47B1]">Violation</h2>

                </div>
            </div>

            <div className="w-full flex flex-row items-start pt-5">
                <h4 className="font-semibold text-lg">Templates</h4>
            </div>

            <div className="mt-2">
                <Slider {...settings}>
                    <MeetingTemplateItem route={""}/>
                </Slider>
            </div>

            <div className="w-full flex flex-row items-start pt-5">
                <h4 className="font-semibold text-lg">Recent Files</h4>
            </div>

            <div className="mt-2 flex flex-col space-y-3 whitespace-nowrap">

                {/*ITEMS HERE*/}

            </div>

            <div className="w-full flex flex-row items-start pt-5">
                <h4 className="font-semibold text-lg">Saved documents</h4>
            </div>

            <div className="flex flex-col space-y-3">

                <div>

                    <div className="flex flex-col items-start justify-start ">
                        <div className="flex w-full justify-between">

                            <h5 className=" font-semibold text-sm text-[#3C3C43]">January</h5>

                            <button>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                                </svg>
                            </button>

                        </div>
                    </div>

                    <div className="mt-2 text-start">

                        {/*ITEMS HERE*/}

                    </div>
                </div>

            </div>

        </div>
    );
}

export default Violations;