import React, {useState} from 'react';
import MobileBottomBar from "../../components/bottom-bar/MobileBottomBar";
import Navbar from "../../components/navbar/Navbar";
import {Route, Routes, useNavigate} from "react-router-dom";
import AnnouncementsPage from "./pages/AnnouncementsPage";
import MyCommunityPage from "./pages/MyCommunityPage";
import MyHomePage from "./pages/MyHomePage";
import MailPage from "./pages/MailPage";

function MainPage() {

    const [currentPage, setCurrentPage] = useState<number>(1)
    const navigate = useNavigate()

    const openCurrentPage = (currentPage: number) => {

        setCurrentPage(currentPage)

        switch (currentPage) {
            case 1:
                navigate("announcements")
                break

            case 2:
                navigate("my-community")
                break

            case 3:
                navigate("my-home")
                break

            case 4:
                navigate("mail")
                break
        }
    }

    return (
        <div className="lg:bg-[#00113D] 2xl:bg-white h-[200px]">

            <Navbar/>

            <div className="w-full min-h-screen">
                <Routes>
                    <Route path="announcements" element={<AnnouncementsPage/>}/>
                    <Route path="my-community" element={<MyCommunityPage/>}/>
                    <Route path="my-home" element={<MyHomePage/>}/>
                    <Route path="mail" element={<MailPage/>}/>
                </Routes>
            </div>

            <MobileBottomBar handleCurrentPage={openCurrentPage}/>

        </div>
    );
}

export default MainPage;