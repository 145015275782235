import React, {useContext, useEffect} from 'react';
import logo from "../../assets/icons/logo_colored.svg";
import BackArrow from "../../components/svg/backArrow";
import {useNavigate} from "react-router-dom";
import AuthContextProvider, {UserContextType} from "../../providers/AuthContextProvider";

function EmailVerificationPage() {

    const navigate = useNavigate()

    const {auth} = useContext(AuthContextProvider) as UserContextType


    useEffect(() => {
        if (!auth) return

        if (auth.isAuth)
            navigate('/main/announcements')
    }, [])

    const goBack = () => {
        navigate(-1)
    }

    const handleVerificationCodeSubmit = (e: any) => {
        e.preventDefault()
        navigate("/invite")
    }

    return (
        <div className="w-full lg:w-1/2 2xl:container mx-auto">

            <picture className="block lg:hidden flex justify-center items-center pt-10">
                <img src={logo} className="w-44" alt="OwnersTally"/>
            </picture>

            <div className="pt-10 lg:h-screen lg:flex lg:justify-center lg:items-center">

                <div
                    className="bg-[#EAEDF5] rounded-t-[20px] h-screen lg:h-auto lg:rounded-[20px] px-5 py-2 lg:px-20 lg:py-10">

                    <div className="pt-5 block lg:hidden flex flex-row justify-start items-start">
                        <button onClick={() => goBack()}><BackArrow/></button>
                    </div>

                    <div className="pt-5 flex flex-col justify-center items-center">
                        <h3 className="text-3xl font-semibold">Email Verification</h3>
                        <p>We have sent code to you email</p>
                        <p className="mt-2">sai*********@gmail</p>

                        <form className="pt-6 flex flex-col" onSubmit={(e: any) => handleVerificationCodeSubmit(e)}>

                            <div className="flex flex-row justify-center items-center">

                                <div className="flex flex-row space-x-2">
                                    <input type="text" maxLength={1}
                                           className="h-16 w-10 md:h-20 md:w-14 text-center font-semibold bg-white rounded-[18px]"/>
                                    <input type="text" maxLength={1}
                                           className="h-16 w-10 md:h-20 md:w-14 text-center font-semibold bg-white rounded-[18px]"/>
                                    <input type="text" maxLength={1}
                                           className="h-16 w-10 md:h-20 md:w-14 text-center font-semibold bg-white rounded-[18px]"/>
                                    <input type="text" maxLength={1}
                                           className="h-16 w-10 md:h-20 md:w-14 text-center font-semibold bg-white rounded-[18px]"/>
                                </div>

                            </div>

                            <p className="pt-10">Didn’t receive code? <span onClick={() => alert("Resend Code")}
                                                                            className="hover:cursor-pointer font-semibold text-[#1D47B1]">Resend</span>
                            </p>

                            <div className="px-10">
                                <button type="submit" className="mt-5 w-full py-2 rounded-full bg-[#1D47B1] text-white">
                                    Verify acount
                                </button>
                            </div>

                        </form>
                    </div>
                </div>

            </div>
        </div>

    );
}

export default EmailVerificationPage;