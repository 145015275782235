import React, {useEffect, useState} from 'react';
import BackArrow from "../../../../components/svg/backArrow";
import logo from "../../../../assets/icons/logo_colored.svg";

function NewCommitteesPost() {

    const [rows, setRows] = useState(10);
    const [title, setTitle] = useState("");
    const [announcements, setAnnouncements] = useState("")

    useEffect(() => {

        const rowLength = announcements.split("\n");

        if (rowLength.length > 10) {
            setRows(rowLength.length);
        } else {
            setRows(10);
        }
    }, [announcements]);

    return (
        <div>

            {/*MOBILE*/}
            <div className="block lg:hidden">

                {/*TOOLBAR*/}
                <div className="bg-white h-14 flex flex-row justify-between items-center px-5">

                    <button className="text-[#1D47B1] font-semibold">
                        Cancel
                    </button>

                    <img src={logo} className="w-32" alt="OwnersTally"/>

                    <button className="text-[#1D47B1] font-semibold">
                        Post
                    </button>
                </div>

                {/*CONTENT*/}
                <div className="mt-2">

                    <div className="flex px-5 items-center justify-between">

                        <div className="flex space-x-3 items-center">

                            <img
                                className="rounded-full w-10 h-10 object-center object-cover"
                                src="https://placehold.jp/50/3d4070/ffffff/150x150.png?text=P"
                                alt="User Avatar"/>

                            <h4>Frank Thomson</h4>

                        </div>

                        <button>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6 text-[#1D47B1]">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"/>
                            </svg>
                        </button>

                    </div>

                    <div className='flex px-5 flex-grow bg-gray-100 h-0.5 my-2 mt-5'/>

                    <div>
                        <input type="text" className="w-full px-5 py-1 outline-none  text-[#3C3C43]"
                               value={title}
                               onChange={(e: any) => setTitle(e.target.value)}
                               placeholder="Write a title for your announcement"
                               maxLength={65}/>
                    </div>

                    <div className='flex flex-grow bg-gray-100 h-0.5 my-2'/>

                    <textarea
                        className="mt-3 px-5 py-1 w-full outline-none font-normal text-[#3C3C43] resize-none h-full"
                        value={announcements}
                        placeholder="Write an announcement"
                        onChange={(e: any) => setAnnouncements(e.target.value)}
                        rows={rows}
                    ></textarea>

                </div>

            </div>

            {/*MOBILE*/}
            <div className="2xl:container mx-auto hidden lg:block">
                <div className="mx-auto w-1/2 rounded-lg border  my-5">
                    {/*CONTENT*/}
                    <div className="mt-5">

                        <div className="flex px-5 items-center justify-between">

                            <div className="flex space-x-3 items-center">

                                <img
                                    className="rounded-full w-10 h-10 object-center object-cover"
                                    src="https://placehold.jp/50/3d4070/ffffff/150x150.png?text=P"
                                    alt="User Avatar"/>

                                <h4>Frank Thomson</h4>

                            </div>

                            <button>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                     stroke="currentColor" className="w-6 h-6 text-[#1D47B1]">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"/>
                                </svg>
                            </button>

                        </div>

                        <div className='flex px-5 flex-grow bg-gray-100 h-0.5 my-2 mt-5'/>

                        <div>
                            <input type="text" className="w-full px-5 py-1 outline-none  text-[#3C3C43]"
                                   value={title}
                                   onChange={(e: any) => setTitle(e.target.value)}
                                   placeholder="Write a title for your announcement"
                                   maxLength={65}/>
                        </div>

                        <div className='flex flex-grow bg-gray-100 h-0.5 my-2'/>

                        <textarea
                            className="mt-3 px-5 py-1 w-full outline-none font-normal text-[#3C3C43] resize-none h-full"
                            value={announcements}
                            placeholder="Write an announcement"
                            onChange={(e: any) => setAnnouncements(e.target.value)}
                            rows={rows}
                        ></textarea>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default NewCommitteesPost;