import React from 'react';

function Facebook() {
    return (
        <>
            <svg width="52" height="52" className="rounded-lg" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_847_3384)">
                    <path d="M0 0V52.39H21.25V38.37H14.78V30.33H21.25V26.16C21.25 20.61 25.75 16.11 31.3 16.11H37.07V24.34H30.9C29.55 24.34 28.46 25.43 28.46 26.78V30.3H35.66L34.04 38.37H28.46V52.39H52.41V0H0Z" fill="#D9D9D9"/>
                    <path d="M0 0V52.39H21.25V38.37H14.78V30.33H21.25V26.16C21.25 20.61 25.75 16.11 31.3 16.11H37.07V24.34H30.9C29.55 24.34 28.46 25.43 28.46 26.78V30.3H35.66L34.04 38.37H28.46V52.39H52.41V0H0Z" fill="#1D47B1"/>
                </g>
                <defs>
                    <clipPath id="clip0_847_3384">
                        <rect width="52.39" height="52.39" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

        </>
    );
}

export default Facebook;