import React, {FC, useContext} from 'react';
import {Navigate} from 'react-router-dom';
import {UserRolType} from "../types/UserRolType";
import {userRoles} from "../utils/ConstStrings";
import AuthContextProvider ,{ UserContextType} from "./AuthContextProvider";

interface PropType {
    isAuthenticated: boolean,
    requiredRol: string,
    component: React.FC;
}



const PrivateRoute: FC<PropType> = ({isAuthenticated = false, requiredRol, component: Component}) => {

    const {auth} = useContext(AuthContextProvider) as UserContextType

    
    const checkIfUserHaveRequiredRole = (rol: string) => {
        return auth.roles.some((userRol) => userRol.description === rol);
    }

    if (isAuthenticated && checkIfUserHaveRequiredRole(requiredRol)) return <Component/>;
    return <Navigate to='/signin'/>;
};

export default PrivateRoute;