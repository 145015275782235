import React, {useContext} from 'react';
import './App.css';

import {Route, BrowserRouter, Routes} from 'react-router-dom'

import NotFound from "./pages/errors/NotFound";
import WelcomePage from "./pages/welcome/WelcomePage";
import OwnerLoginPage from "./pages/owner-login/OwnerLoginPage";
import RegisterMapPage from "./pages/register/RegisterMapPage";
import RegisterPage from "./pages/register/RegisterPage";
import EmailVerificationPage from "./pages/register/EmailVerificationPage";
import MainPage from "./pages/main/MainPage";
import InviteCommunityPage from "./pages/invite/InviteCommunityPage";
import EditProfile from "./pages/main/profile/EditProfile";
import NewAnnouncement from "./pages/main/Announcement/NewAnnouncement";
import AnnoucenemtDetails from "./pages/main/Announcement/AnnouncementDetails";
import Meetings from "./pages/main/MyCommunity/Meetings/Meetings";
import NewMeeting from "./pages/main/MyCommunity/Meetings/NewMeeting";
import ChangePassword from "./pages/main/profile/ChangePassword";
import MeetingAnnouncementDetails from "./pages/main/MyCommunity/Meetings/MeetingDetails";
import Violations from "./pages/main/MyCommunity/Violations/Violations";
import NewViolation from "./pages/main/MyCommunity/Violations/NewViolation";
import ViolationDetails from "./pages/main/Announcement/ViolationDetails";
import UpdateAvatar from "./pages/main/profile/UpdateAvatar";
import Committees from "./pages/main/MyCommunity/Committees/Committees";
import CreateGroup from "./pages/main/MyCommunity/Committees/CreateGroup";
import EditGroup from "./pages/main/MyCommunity/Committees/EditGroup";
import GroupUserDetails from "./pages/main/MyCommunity/Committees/GroupUserDetails";
import NewCommitteesPost from "./pages/main/MyCommunity/Committees/NewCommitteesPost";
import NewFamilyMember from "./pages/main/MyHome/NewFamilyMember";
import NewVehicle from "./pages/main/MyHome/NewVehicle";
import NewPet from "./pages/main/MyHome/NewPet";
import NewAccessCode from "./pages/main/MyHome/NewAccessCode";
import NewRules from "./pages/main/MyCommunity/Rules/NewRules";
import Budget from "./pages/main/MyCommunity/Budget/Budget";
import Communication from "./pages/main/MyCommunity/Communication/Communication";
import CommunicationNewTemplate from "./pages/main/MyCommunity/Communication/CommunicationNewTemplate";
import Requests from "./pages/main/MyCommunity/Request/Requests";
import RequestNewTemplate from "./pages/main/MyCommunity/Request/RequestNewTemplate";
import NewBudget from "./pages/main/MyCommunity/Budget/NewBudget";
import CallToMeeting from "./pages/main/MyCommunity/Meetings/CallToMeeting";
import AuthContextProvider, {UserContextType} from './providers/AuthContextProvider';
import ProtectedRoute from "./providers/ProtectedRoute";
import {UserRol} from "./types/UserRolType";
import BudgetDetails from './pages/main/MyCommunity/Budget/BudgetDetails';
import BudgetMonthDocuments from "./pages/main/MyCommunity/Budget/BudgetMonthDocuments";
import MeetingMonthDocumets from "./pages/main/MyCommunity/Meetings/MeetingMonthDocumets";
import CommunicationDetails from "./pages/main/MyCommunity/Communication/CommunicationDetails";
import CommunicationMonthDocuments from "./pages/main/MyCommunity/Communication/CommunicationMonthDocuments";
import RequestDetails from "./pages/main/MyCommunity/Request/RequestDetails";
import RequestMonthDocuments from "./pages/main/MyCommunity/Request/RequestMonthDocuments";
import CommunityManagePage from "./pages/ManageCommunity/manage/CommunityManagePage";
import ImportMemberFromCSV from "./pages/ManageCommunity/manage/members/ImportMemberFromCSV";
import ImportMemberFromExcel from "./pages/ManageCommunity/manage/members/ImportMemberFromExcel";
import SelectCurrentCommunity from "./pages/main/SelectCurrentCommunity";
import CreateCommunityPage from "./pages/ManageCommunity/create/CreateCommunityPage";

function App() {

    const {auth} = useContext(AuthContextProvider) as UserContextType

    if (auth === undefined) {
        return (
            <div className="min-h-screen dark:bg-dark-bg flex justify-center items-center">
                <img className="w-24 h-24" src="/logo_small_ot.svg" alt="Ownerstally"/>
            </div>
        );
    }

    return (

        <div className="App">

            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<WelcomePage/>}/>
                    <Route path="/signin" element={<OwnerLoginPage/>}/>
                    <Route path="/signup-community" element={<RegisterMapPage/>}/>
                    <Route path="/signup-user" element={<RegisterPage/>}/>
                    <Route path="/email-verification" element={<EmailVerificationPage/>}/>

                    <Route path="/invite/:query"
                           element={<InviteCommunityPage/>}
                    />

                    <Route path="/community/create"
                           element={<CreateCommunityPage/>}
                    />

                    <Route path="/community/choose"
                           element={<ProtectedRoute isAuthenticated={auth.isAuth} requiredRol={UserRol.OWNER}
                                                    component={SelectCurrentCommunity}/>}
                    />

                    <Route path="/main/*"
                           element={<ProtectedRoute isAuthenticated={auth.isAuth} requiredRol={UserRol.OWNER}
                                                    component={MainPage}/>}/>
                    <Route path="/new-announcement"
                           element={<ProtectedRoute isAuthenticated={auth.isAuth} requiredRol={UserRol.OWNER}
                                                    component={NewAnnouncement}/>}/>
                    <Route path="/announcement/:slug"
                           element={<ProtectedRoute isAuthenticated={auth.isAuth} requiredRol={UserRol.OWNER}
                                                    component={AnnoucenemtDetails}/>}/>
                    <Route path="/meeting/:slug" element={
                        <ProtectedRoute
                            isAuthenticated={auth.isAuth}
                            component={MeetingAnnouncementDetails}
                            requiredRol={UserRol.OWNER}
                        />}/>

                    <Route path="/meetings/month/:slug" element={
                        <ProtectedRoute
                            isAuthenticated={auth.isAuth}
                            component={MeetingMonthDocumets}
                            requiredRol={UserRol.OWNER}
                        />}/>

                    <Route path="/call-to-meeting/:slug"
                           element={<ProtectedRoute isAuthenticated={auth.isAuth} requiredRol={UserRol.OWNER}
                                                    component={CallToMeeting}/>}/>

                    <Route path="/edit-profile/:slug"
                           element={<ProtectedRoute isAuthenticated={auth.isAuth} requiredRol={UserRol.OWNER}
                                                    component={EditProfile}/>}/>
                    <Route path="/update-avatar" element={
                        <ProtectedRoute
                            isAuthenticated={auth.isAuth}
                            requiredRol={UserRol.OWNER}
                            component={UpdateAvatar}/>}/> {/*FALTA*/}
                    <Route path="/update-password"
                           element={<ProtectedRoute
                               isAuthenticated={auth.isAuth}
                               requiredRol={UserRol.OWNER}
                               component={ChangePassword}/>}
                    />
                    <Route path="/meetings"
                           element={<ProtectedRoute
                               isAuthenticated={auth.isAuth}
                               requiredRol={UserRol.OWNER}
                               component={Meetings}/>}/>
                    <Route path="/new-meeting"
                           element={<ProtectedRoute
                               isAuthenticated={auth.isAuth}
                               requiredRol={UserRol.OWNER}
                               component={NewMeeting}/>}
                    />
                    <Route path="/violation"
                           element={
                               <ProtectedRoute
                                   isAuthenticated={auth.isAuth}
                                   requiredRol={UserRol.OWNER}
                                   component={Violations}/>}
                    />
                    <Route path="/violation/:slug"
                           element={
                               <ProtectedRoute
                                   isAuthenticated={auth.isAuth}
                                   requiredRol={UserRol.OWNER}
                                   component={ViolationDetails}/>}
                    />
                    <Route path="/new-violation"
                           element={
                               <ProtectedRoute
                                   isAuthenticated={auth.isAuth}
                                   requiredRol={UserRol.OWNER}
                                   component={NewViolation}/>
                           }
                    />

                    <Route path="/committees"
                           element={
                               <ProtectedRoute
                                   isAuthenticated={auth.isAuth}
                                   component={Committees}
                                   requiredRol={UserRol.OWNER}/>
                           }
                    />
                    <Route path="/communication"
                           element={
                               <ProtectedRoute
                                   isAuthenticated={auth.isAuth}
                                   component={Communication}
                                   requiredRol={UserRol.OWNER}
                               />
                           }
                    />

                    <Route path="/communication/:slug"
                           element={
                               <ProtectedRoute
                                   isAuthenticated={auth.isAuth}
                                   component={CommunicationDetails}
                                   requiredRol={UserRol.OWNER}
                               />
                           }
                    />

                    <Route path="/communication/month/:slug"
                           element={
                               <ProtectedRoute
                                   isAuthenticated={auth.isAuth}
                                   component={CommunicationMonthDocuments}
                                   requiredRol={UserRol.OWNER}
                               />
                           }
                    />

                    <Route path="/new-communication" element={
                        <ProtectedRoute
                            isAuthenticated={auth.isAuth}
                            requiredRol={UserRol.OWNER}
                            component={CommunicationNewTemplate}/>
                    }
                    />

                    <Route path="/create-committees-group"
                           element={<ProtectedRoute
                               isAuthenticated={auth.isAuth}
                               component={CreateGroup}
                               requiredRol={UserRol.OWNER}
                           />
                           }
                    />
                    <Route path="/budget" element={
                        <ProtectedRoute
                            isAuthenticated={auth.isAuth}
                            requiredRol={UserRol.OWNER}
                            component={Budget}
                        />
                    }
                    />
                    <Route path="/new-budget" element={
                        <ProtectedRoute
                            isAuthenticated={auth.isAuth}
                            requiredRol={UserRol.OWNER}
                            component={NewBudget}/>
                    }
                    />
                    <Route path="/budget/:slug"
                           element={<ProtectedRoute
                               isAuthenticated={auth.isAuth}
                               component={BudgetDetails}
                               requiredRol={UserRol.OWNER}
                           />
                           }
                    />

                    <Route path="/budget/month/:slug"
                           element={<ProtectedRoute
                               isAuthenticated={auth.isAuth}
                               component={BudgetMonthDocuments}
                               requiredRol={UserRol.OWNER}
                           />
                           }
                    />

                    <Route path="/new-committees-post"
                           element={<ProtectedRoute
                               isAuthenticated={auth.isAuth}
                               requiredRol={UserRol.OWNER}
                               component={NewCommitteesPost}/>
                           }/>

                    <Route path="/edit-committees-group"
                           element={<ProtectedRoute
                               isAuthenticated={auth.isAuth}
                               requiredRol={UserRol.OWNER}
                               component={EditGroup}/>
                           }
                    />

                    <Route path="/group-user-details"
                           element={
                               <ProtectedRoute
                                   isAuthenticated={auth.isAuth}
                                   component={GroupUserDetails}
                                   requiredRol={UserRol.OWNER}
                               />
                           }
                    />

                    <Route path="/add-rules"
                           element={<ProtectedRoute
                               isAuthenticated={auth.isAuth}
                               component={NewRules}
                               requiredRol={UserRol.OWNER}
                           />}
                    /> {/*FALTA*/}
                    <Route path="/add-family-member"
                           element={<ProtectedRoute
                               isAuthenticated={auth.isAuth}
                               requiredRol={UserRol.OWNER}
                               component={NewFamilyMember}/>
                           }
                    />
                    <Route path="/add-access-code"
                           element={<ProtectedRoute
                               isAuthenticated={auth.isAuth}
                               component={NewAccessCode}
                               requiredRol={UserRol.OWNER}
                           />}/>
                    <Route path="/add-vehicle"
                           element={<ProtectedRoute
                               isAuthenticated={auth.isAuth}
                               requiredRol={UserRol.OWNER}
                               component={NewVehicle}/>
                           }
                    />
                    <Route path="/add-pet"
                           element={
                               <ProtectedRoute
                                   isAuthenticated={auth.isAuth}
                                   requiredRol={UserRol.OWNER}
                                   component={NewPet}/>
                           }/>
                    <Route path="/requests"
                           element={
                               <ProtectedRoute
                                   isAuthenticated={auth.isAuth}
                                   component={Requests}
                                   requiredRol={UserRol.OWNER}
                               />}
                    />

                    <Route path="/request/:slug"
                           element={
                               <ProtectedRoute
                                   isAuthenticated={auth.isAuth}
                                   component={RequestDetails}
                                   requiredRol={UserRol.OWNER}
                               />}
                    />

                    <Route path="/requests/month/:slug"
                           element={
                               <ProtectedRoute
                                   isAuthenticated={auth.isAuth}
                                   component={RequestMonthDocuments}
                                   requiredRol={UserRol.OWNER}
                               />}
                    />

                    <Route path="/new-request"
                           element={
                               <ProtectedRoute
                                   isAuthenticated={auth.isAuth}
                                   requiredRol={UserRol.OWNER}
                                   component={RequestNewTemplate}/>
                           }
                    />

                    <Route path="/community-manage/*"
                           element={
                               <ProtectedRoute
                                   isAuthenticated={auth.isAuth}
                                   requiredRol={UserRol.OWNER}
                                   component={CommunityManagePage}/>
                           }
                    />

                    <Route path="/invite/members/csv"
                           element={
                               <ProtectedRoute
                                   isAuthenticated={auth.isAuth}
                                   requiredRol={UserRol.OWNER}
                                   component={ImportMemberFromCSV}/>
                           }
                    />

                    <Route path="/invite/members/excel"
                           element={
                               <ProtectedRoute
                                   isAuthenticated={auth.isAuth}
                                   requiredRol={UserRol.OWNER}
                                   component={ImportMemberFromExcel}/>
                           }
                    />

                    <Route path="*" element={<NotFound/>}/>

                </Routes>
            </BrowserRouter>

        </div>
    );
}

export default App;
