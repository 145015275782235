import React from 'react';
import logo from "../../../assets/icons/logo_colored.svg";
import BackArrow from "../../../components/svg/backArrow";

function UpdateAvatar() {
    return (
        <div>
            {/*MOBIL*/}
            <div className="block lg:hidden ">

                <div className="bg-white h-14 flex flex-row justify-between items-center px-5">

                    <button className="text-[#A8A8A8] font-semibold">
                        <BackArrow/>
                    </button>

                    <h2 className="font-semibold">Change picture</h2>

                    <button className="text-[#1D47B1] font-semibold">
                        Edit
                    </button>

                </div>

                <div className="flex items-center justify-center mt-5">
                    <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
                         className="w-full h-full object-center object-cover"
                         alt=""/>
                </div>

                <div className="relative h-full flex justify-center items-center mx-5">

                    <div className="absolute bottom-3 w-full">
                        <button className="text-lg text-[#FC913A] py-2 bg-white w-full rounded-t-[20px]">Delete picture</button>
                        <button className="text-lg py-2 bg-white w-full">Take picture</button>
                        <button className="text-lg py-2 bg-white w-full rounded-b-[20px]">Select picture</button>

                        <button className="mt-5 text-lg font-semibold py-2 bg-white w-full rounded-[20px]">Cancel</button>

                    </div>

                </div>

            </div>

            {/*DESKTOP*/}
            <div className="hidden lg:block">

            </div>

        </div>
    );
}

export default UpdateAvatar;