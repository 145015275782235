import React from 'react';
import {useNavigate} from "react-router-dom";

function NewAccessCode() {

    const navigate = useNavigate()

    const handleOnSaveClick = () => {
        console.log("onSaveClick")
    }

    return (
        <div>

            {/*MOBILE*/}
            <div className="block lg:hidden px-5 mt-5">

                {/*TOOLBAR*/}
                <div className="flex justify-between items-center">
                    <button onClick={() => navigate(-1)} className="font-medium text-[#A8A8A8]">Cancel</button>
                    <h2 className="font-semibold">access code</h2>
                    <button onClick={() => handleOnSaveClick()} className="text-[#1D47B1] font-medium">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z"/>
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z"/>
                        </svg>
                    </button>
                </div>

                {/*CONTENT*/}
                <div className="text-start">
                    <div className=" mt-5 flex flex-col space-y-2">
                        <label className="text-sm">Name</label>

                        <input type="text"
                               className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                               placeholder="Write name"/>
                    </div>

                    <div className="mt-5 flex flex-col space-y-2">
                        <label className="text-sm">ID number</label>

                        <input type="text"
                               className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                               placeholder="ID number"/>
                    </div>

                    <div className="mt-5 flex flex-col space-y-2">
                        <label className="text-sm">Type of guest</label>

                        <select id="type_of_guest"
                                className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg px-3">
                            <option defaultChecked>Select type of guest</option>
                            <option value="TEMPORARY">Temporary</option>
                            <option value="PERMANENT">Recurring</option>
                            <option value="PERMANENT">Permanent</option>
                        </select>
                    </div>

                    <div className="mt-5 flex flex-col space-y-2">
                        <label className="text-sm">Description</label>

                        <input type="text"
                               className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                               placeholder=""/>
                    </div>

                    <div className="mt-5 flex flex-col space-y-2">
                        <label className="text-sm">Time</label>

                        <select id="time"
                                className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg px-3">
                            <option defaultChecked>Select time for code</option>
                            <option value="1_WEEK">1 Week</option>
                            <option value="15_DAYS">15 Days</option>
                            <option value="1_MONTH">1 Month</option>
                            <option value="3_MONTHS">3 Months</option>
                            <option value="6_MONTHS">6 Months</option>
                            <option value="1_YEAR">1 Year</option>
                        </select>
                    </div>

                    <div className="mt-5 flex flex-col space-y-2">
                        <label className="text-sm">Frecuency</label>

                        <select id="frequency"
                                className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg px-3">
                            <option defaultChecked>Select time for code</option>
                            <option value="1_WEEK">Every monday</option>
                            <option value="15_DAYS">Every tuesday</option>
                            <option value="1_MONTH">Every Wednesday</option>
                            <option value="3_MONTHS">Every Thursday</option>
                            <option value="6_MONTHS">Each Friday</option>
                            <option value="1_YEAR">Each 15 days</option>
                            <option value="1_YEAR">Each month</option>
                        </select>
                    </div>

                </div>

            </div>

            {/*DESKTOP*/}
            <div className="hidden lg:block 2xl:container mx-auto">

                <div className="w-1/2 mx-auto my-5 border rounded-lg p-5 px-10 ">
                    {/*CONTENT*/}
                    <div className="text-start">
                        <div className=" mt-5 flex flex-col space-y-2">
                            <label className="text-sm">Name</label>

                            <input type="text"
                                   className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                   placeholder="Write name"/>
                        </div>

                        <div className="mt-5 flex flex-col space-y-2">
                            <label className="text-sm">ID number</label>

                            <input type="text"
                                   className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                   placeholder="ID number"/>
                        </div>

                        <div className="mt-5 flex flex-col space-y-2">
                            <label className="text-sm">Type of guest</label>

                            <select id="type_of_guest"
                                    className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg px-3">
                                <option defaultChecked>Select type of guest</option>
                                <option value="TEMPORARY">Temporary</option>
                                <option value="PERMANENT">Recurring</option>
                                <option value="PERMANENT">Permanent</option>
                            </select>
                        </div>

                        <div className="mt-5 flex flex-col space-y-2">
                            <label className="text-sm">Description</label>

                            <input type="text"
                                   className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                   placeholder=""/>
                        </div>

                        <div className="mt-5 flex flex-col space-y-2">
                            <label className="text-sm">Time</label>

                            <select id="time"
                                    className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg px-3">
                                <option defaultChecked>Select time for code</option>
                                <option value="1_WEEK">1 Week</option>
                                <option value="15_DAYS">15 Days</option>
                                <option value="1_MONTH">1 Month</option>
                                <option value="3_MONTHS">3 Months</option>
                                <option value="6_MONTHS">6 Months</option>
                                <option value="1_YEAR">1 Year</option>
                            </select>
                        </div>

                        <div className="mt-5 flex flex-col space-y-2">
                            <label className="text-sm">Frecuency</label>

                            <select id="frequency"
                                    className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg px-3">
                                <option defaultChecked>Select time for code</option>
                                <option value="1_WEEK">Every monday</option>
                                <option value="15_DAYS">Every tuesday</option>
                                <option value="1_MONTH">Every Wednesday</option>
                                <option value="3_MONTHS">Every Thursday</option>
                                <option value="6_MONTHS">Each Friday</option>
                                <option value="1_YEAR">Each 15 days</option>
                                <option value="1_YEAR">Each month</option>
                            </select>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default NewAccessCode;