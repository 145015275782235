import React from 'react';
import {useNavigate} from "react-router-dom";

export interface MyCommunityItem {
    title: string,
    icon: any
}

function MyCommunityItem(props: MyCommunityItem) {

    const navigate = useNavigate()

    const handleOnItemClick = () => {

        switch (props.title.toLowerCase().trim()) {

            case "budget":
                navigate("/budget")
                break

            case "committees":
                navigate("/committees")
                break

            case "meetings":
                navigate("/meetings")
                break

            case "violation":
                navigate("/violation")
                break

            case "communication":
                navigate("/communication")
                break

            case "requests":
                navigate("/requests")
                break

            case "manage":
                    navigate('community-manage/members')
                break

        }
    }

    return (
        <button onClick={() => handleOnItemClick()}
                className="text-start flex flex-row items-center justify-between bg-white lg:rounded-[25px] hover:cursor-pointer lg:hover:bg-gray-300 lg:bg-none active:bg-gray-100 px-5 py-2 outline-none">

            <div className="flex space-x-5 flex-row items-center">
                <img src={props.icon} className="w-12 h-12" alt="BudgetDetails Icon"/>
                <h3 className="font-semibold text-[#3C3C43]">{props.title}</h3>
            </div>

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                 stroke="currentColor" className="w-6 h-6 block lg:hidden text-gray-400">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5"/>
            </svg>


        </button>
    );
}

export default MyCommunityItem;