import React from 'react';
import CommunityIcon from "../../assets/svg/committees/committees.svg";

function CommunitySelectorItem() {
    return (
        <button className="flex flex-col justify-center items-center">
            <div className="w-28 h-28 rounded-full bg-gray-100 hover:scale-95 hover:border-2 transition flex justify-center items-center">
                <img src={CommunityIcon} className="w-16 h-16" alt="Community Icon"/>
            </div>
            <p className="font-semibold text-sm mt-2 text-gray-600">Community name</p>
        </button>
    );
}

export default CommunitySelectorItem;