import React from 'react';

function DocumentItemPlaceholder() {
    return (
        <div className="w-full border rounded-lg space-x-3 flex flex-row  items-center text-start animate-pulse">

            <div className="border rounded-lg p-5">

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-gray-300">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>

            </div>

            <div className="w-full flex flex-col space-y-2 ">
                <div className="w-1/2  h-4 rounded-lg bg-gray-100"/>
                <div className="w-16 h-4 rounded-lg bg-gray-100 "/>
            </div>
        </div>
    );
}

export default DocumentItemPlaceholder;