import React from 'react';

function BackArrow() {
    return (
        <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_668_2050)">
                <path d="M30.3952 10.1499H0.633301" stroke="#1D47B1" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M11.8734 0.588562L1.31932 9.47252C1.16051 9.61118 1.07178 9.79629 1.07178 9.9889C1.07178 10.1815 1.16051 10.3666 1.31932 10.5053L11.8734 19.3892" stroke="#1D47B1" strokeWidth="2" strokeMiterlimit="10"/>
            </g>
            <defs>
                <clipPath id="clip0_668_2050">
                    <rect width="25" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
}

export default BackArrow;