import React from 'react';

type NewUserFormProps = {
    id: number,
    firstname: string,
    lastname: string,
    email_address: string,
    community_name?: string,
    property_address?: string,
    property_address_alt?: string,
    property_city?: string,
    property_state?: string,
    property_zip?: string
    updateField: (id: number, fieldName: string, newValue: string) => void;
}

function InviteNewUserForm(props: NewUserFormProps) {

    const handleOnUpdateNewMemberField = (
        fieldName: string,
        newValue: string
    ) => {
        props.updateField(props.id, fieldName, newValue);
    };

    return (
        <div className="grid grid-cols-1 gap-4 bg-white rounded-lg border p-5">

            <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0">
                <input type="text"
                       className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                       value={props.firstname}
                       onChange={(e: any) => handleOnUpdateNewMemberField("firstname", e.target.value)}
                       placeholder="firstname"/>
                <input type="text"
                       className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                       value={props.lastname}
                       onChange={(e: any) => handleOnUpdateNewMemberField("lastname", e.target.value)}
                       placeholder="lastname"/>
            </div>

            <input type="email"
                   className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                   value={props.email_address}
                   onChange={(e: any) => handleOnUpdateNewMemberField("email", e.target.value)}
                   placeholder="email"/>

            <input type="text"
                   className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                   value={props.community_name}
                   onChange={(e: any) => handleOnUpdateNewMemberField( "community_name", e.target.value)}
                   placeholder="community name"/>

            <input type="text"
                   className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                   value={props.property_address}
                   onChange={(e: any) => handleOnUpdateNewMemberField("property_address", e.target.value)}
                   placeholder="property address"/>

            <input type="text"
                   className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                   value={props.property_address_alt}
                   onChange={(e: any) => handleOnUpdateNewMemberField("property_address_alt", e.target.value)}
                   placeholder="property address alt"/>

            <input type="text"
                   className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                   value={props.property_city}
                   onChange={(e: any) => handleOnUpdateNewMemberField("property_city", e.target.value)}
                   placeholder="property city"/>

            <input type="text"
                   className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                   value={props.property_state}
                   onChange={(e: any) => handleOnUpdateNewMemberField("property_state", e.target.value)}
                   placeholder="property state"/>

            <input type="text"
                   className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                   value={props.property_zip}
                   onChange={(e: any) => handleOnUpdateNewMemberField("property_zip", e.target.value)}
                   placeholder="property zip"/>

        </div>
    );
}

export default InviteNewUserForm;