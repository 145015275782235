import React from 'react';
import {useNavigate} from "react-router-dom";

function NewPet() {

    const navigate = useNavigate()

    const handleOnSaveClick = () => {
        console.log("handleOnSaveClick")
    }

    return (
        <div>

            {/*MOBILE*/}
            <div className="block lg:hidden px-5 mt-5">

                {/*TOOLBAR*/}
                <div className="flex justify-between items-center">
                    <button onClick={() => navigate(-1)} className="font-medium text-[#A8A8A8]">Cancel</button>
                    <h2 className="font-semibold">new pet</h2>
                    <button onClick={ ()=> handleOnSaveClick() } className="text-[#1D47B1] font-medium">Save</button>
                </div>

                {/*CONTENT*/}
                <div className="text-start">
                    <div className=" mt-5 flex flex-col space-y-2">
                        <label className="text-sm">Name</label>

                        <input type="text"
                               className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                               placeholder="Pet name"/>

                    </div>

                    <div className="mt-5 flex flex-col space-y-2">
                        <label className="text-sm">Species</label>

                        <input type="text"
                               className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                               placeholder="Pet species"/>
                    </div>

                    <div className="mt-5 flex flex-col space-y-2">
                        <label className="text-sm">Race</label>

                        <input type="text"
                               className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                               placeholder="Pet race"/>
                    </div>


                    <div className="mt-5 flex flex-col space-y-2">
                        <label className="text-sm ">Gender</label>

                        <select id="hoa_position" className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg px-3">
                            <option defaultChecked>Select pet gender</option>
                            <option value="FEMALE">Female</option>
                            <option value="MALE">Male</option>
                        </select>
                    </div>

                    <div className="mt-5 flex flex-col space-y-2">

                        <label className="text-sm">Age</label>

                        <input type="text"
                               className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                               placeholder="Pet age"/>
                    </div>

                </div>

            </div>

            {/*DESKTOP*/}
            <div className="hidden lg:block 2xl:container mx-auto">
              <div className="w-1/2 mx-auto p-10 my-5 border rounded-lg">
                  {/*CONTENT*/}
                  <div className="text-start">
                      <div className="flex flex-col space-y-2">
                          <label className="text-sm">Name</label>

                          <input type="text"
                                 className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                 placeholder="Pet name"/>

                      </div>

                      <div className="mt-5 flex flex-col space-y-2">
                          <label className="text-sm">Species</label>

                          <input type="text"
                                 className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                 placeholder="Pet species"/>
                      </div>

                      <div className="mt-5 flex flex-col space-y-2">
                          <label className="text-sm">Race</label>

                          <input type="text"
                                 className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                 placeholder="Pet race"/>
                      </div>


                      <div className="mt-5 flex flex-col space-y-2">
                          <label className="text-sm ">Gender</label>

                          <select id="hoa_position" className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg px-3">
                              <option defaultChecked>Select pet gender</option>
                              <option value="FEMALE">Female</option>
                              <option value="MALE">Male</option>
                          </select>
                      </div>

                      <div className="mt-5 flex flex-col space-y-2">

                          <label className="text-sm">Age</label>

                          <input type="text"
                                 className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                 placeholder="Pet age"/>
                      </div>

                  </div>
              </div>
            </div>

        </div>
    );
}

export default NewPet;