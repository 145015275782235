import React, {useRef, useState} from 'react';
import BackArrow from "../../../../components/svg/backArrow";
import logo from "../../../../assets/icons/logo_colored.svg";
import {Editor} from "@tinymce/tinymce-react";

function NewViolation() {

    const [editorContent, setEditorContent] = useState("");
    const editor = useRef(null);

    const onButtonClick = () => {
        // @ts-ignore
        setEditorContent(editor.current.props.value);
    };

    const handleEditorChange = (content: any) => {
        setEditorContent(content);
    };

    return (
        <div>
            {/*MOBILE*/}
            <div className="block lg:hidden px-5 py-5">

                <div className="flex justify-between items-center">

                    <button>
                        <BackArrow/>
                    </button>

                    <img src={logo} className="w-32" alt="OwnersTally"/>

                    <button className="text-[#1D47B1] font-semibold">
                        Post
                    </button>

                </div>

                <h4 className="font-semibold text-lg text-[#3D3C44]">Complaint Template</h4>
                <span className="text-sm text-[#707070]">Written on 22 Oct 2023 </span>

                <div>
                    <form className="mt-3 flex flex-col space-y-3">

                        <div className="text-start">
                            <label>Written by</label>
                            <input type="text" className="mt-1 border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                                   placeholder="write here..."/>
                        </div>

                        <div className="text-start">
                            <label>Name of person involved</label>
                            <input type="text" className="mt-1 border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                                   placeholder="write here..."/>
                        </div>

                        <div className="text-start">
                            <label>Number's house</label>
                            <input type="text" className="mt-1 border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                                   placeholder="write here..."/>
                        </div>

                        <div className="text-start">
                            <label>Reference title</label>
                            <input type="text" className="mt-1 border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"
                                   placeholder="write here..."/>
                        </div>

                        <label className="text-start">Description</label>

                        <div className="mt-2">

                            <Editor
                                ref={editor}
                                apiKey={process.env.REACT_APP_TINYMCEKEY}
                                value={editorContent}
                                init={{
                                    plugins: 'link image code',
                                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                                }}
                                onEditorChange={handleEditorChange}
                            />

                        </div>

                    </form>
                </div>

            </div>

            {/*DESKTOP*/}
            <div className="2xl:container mx-auto hidden lg:block px-5 py-5">

                <div className="w-1/2 mx-auto">

                    <div className="hidden lg:flex justify-between items-start mt-5">
                        <h2 className="font-semibold text-2xl text-[#1D47B1]">Violation</h2>

                        <div className="flex space-x-6">
                            <button className="text-[#A8A8A8]">Cancel</button>
                            <button className="text-[#1D47B1]">View</button>
                        </div>
                    </div>

                    <div className="border rounded-lg mt-5 p-5">


                        <div className="flex flex-col justify-center items-center mt-2 mb-6">
                            <div>
                                <img src={logo} className="w-32 h-auto" alt="OwnersTally Logo"/>
                            </div>

                            <div className="flex flex-col justify-center items-center ">
                                <h3 className="font-bold text-2xl text-[#3C3C43]">Complaint Template</h3>
                                <p className="text-[#707070] text-sm">written on 22 Oct 2023</p>
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-4">

                            <div className="flex flex-col justify-start space-y-1 items-start ">
                                <label>Written by</label>
                                <input type="text" className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg" placeholder="write here"/>
                            </div>

                            <div className="flex flex-col justify-start space-y-1 items-start ">
                                <label>Hause Number</label>
                                <input type="text" className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg" placeholder="write here"/>
                            </div>

                            <div className="flex flex-col justify-start space-y-1 items-start ">
                                <label>Name of person involved</label>
                                <input type="text" className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg" placeholder="write here"/>
                            </div>

                            <div className="flex flex-col justify-start space-y-1 items-start ">
                                <label>Reference title</label>
                                <input type="text" className="border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg" placeholder="write here"/>
                            </div>

                        </div>

                        <div className="mt-5 flex flex-col justify-start items-start">

                            <label>Description</label>

                            <div className="w-full mt-1">
                                <Editor
                                    ref={editor}
                                    apiKey={process.env.REACT_APP_TINYMCEKEY}
                                    value={editorContent}
                                    init={{
                                        plugins: 'link image code',
                                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                                    }}
                                    onEditorChange={handleEditorChange}
                                />
                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </div>
    );
}

export default NewViolation;