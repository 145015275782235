import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";

function AnnouncementItem() {

    const navigate = useNavigate()

    const [checkBox, setCheckbox] = useState<boolean>(false)

    const handleOnAnnouncementClick = () => {
        navigate("/announcement/asd")
    }

    return (
        <button onClick={ () => handleOnAnnouncementClick() } className="hidden lg:block w-full hover:bg-gray-100 px-2 hover:cursor-pointer flex flex-row items-center space-x-4 py-2">

            <div className="flex flex-row w-full justify-between">

                <div className="flex flex-row space-x-10">
                    <div className="bg-white border rounded-sm border-gray-400 w-5 h-5 flex flex-shrink-0 justify-center items-center relative">
                        <input onChange={() => setCheckbox(!checkBox)}
                               type="checkbox"
                               className="checkbox focus:outline-none opacity-0 focus:opacity-100 focus:ring-2 focus:ring-offset-2 focus:ring-primary absolute cursor-pointer w-full h-full"/>
                        <div className={checkBox ? "check-icon bg-[#1D47B1] text-white rounded-sm" : "check-icon hidden bg-white text-white rounded-sm"}>
                            <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/checkbox_large-svg1.svg"
                                 alt="tick"/>
                        </div>
                    </div>

                    <h5 className="text-sm font-light">John Doe</h5>
                </div>

                <p className="text-sm font-light">Subject of the email</p>
                <p className="text-[#707070] text-sm font-light">Lorem ipsum dolor sit amet, consr elit...</p>
                <span className="text-sm font-light">6:30 a.m</span>
            </div>



        </button>
    );
}

export default AnnouncementItem;