import React from 'react';
import LogoSmall from "../../../assets/icons/logo_small.svg";
import {useNavigate} from "react-router-dom";
import FamilyMemberSVG from '../../../assets/svg/my_home/family_member.svg'
import AccessCodeSVG from '../../../assets/svg/my_home/access_code.svg'
import PetsSVG from '../../../assets/svg/my_home/pets.svg'
import VehiclesSVG from '../../../assets/svg/my_home/vehicles.svg'
import BgLinesSVG from '../../../assets/svg/my_home/bg_lines.svg'
import Toolbar from "../../../components/toolbar/Toolbar";


function MyHomePage() {

    const navigate = useNavigate()

    const handleOnNewMemberButtonClick = () => {
        navigate("/add-family-member")
    }

    const handleOnNewAccessCodeButtonClick = () => {
        navigate("/add-access-code")
    }

    const handleOnNewVehicleButtonClick = () => {
        navigate("/add-vehicle")
    }

    const handleOnNewPetButtonClick = () => {
        navigate("/add-pet")
    }

    return (
        <div>

            {/*MOBILE*/}
            <div className="block lg:hidden">

                {/*TOOLBAR*/}
                <Toolbar title={"Home"}/>

                {/*CONTENT*/}
                <div>
                    <div className="mt-20 px-5">

                        <div className="flex justify-between items-center">

                            <h4 className="font-semibold">Family members</h4>

                            <button onClick={() => handleOnNewMemberButtonClick()}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2}
                                     stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                            </button>

                        </div>

                        <div className="grid grid-cols-1 gap-4 mt-3">

                            {/*ITEM*/}
                            <div className="border py-2 px-5 flex justify-between items-center rounded-lg ">

                                <h5>Frank Thompson</h5>

                                <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15"/>
                                    </svg>
                                </button>

                            </div>

                            {/*ITEM*/}
                            <div className="border py-2 px-5 flex justify-between items-center rounded-lg ">

                                <h5>Frank Thompson</h5>

                                <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15"/>
                                    </svg>
                                </button>

                            </div>


                        </div>

                        <div className="mt-5 flex justify-between items-center">

                            <h4 className="font-semibold">Access code</h4>

                            <button onClick={() => handleOnNewAccessCodeButtonClick()}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2}
                                     stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                            </button>

                        </div>

                        <div className="grid grid-cols-1 gap-4 mt-3">

                            {/*ITEM*/}
                            <div className="border py-2 px-5 flex flex-col rounded-lg ">

                                <div className="w-full flex justify-between items-center">
                                    <h5 className="font-medium">Frank Thompson</h5>

                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15"/>
                                        </svg>
                                    </button>

                                </div>

                                <div className="flex justify-between items-center">
                                    <span className="text-sm">family</span>
                                    <span className="text-sm text-gray-400">temporary</span>
                                </div>


                            </div>

                        </div>


                        <div className="mt-5 flex justify-between items-center">

                            <h4 className="font-semibold">Vehicle</h4>

                            <button onClick={() => handleOnNewVehicleButtonClick()}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2}
                                     stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                            </button>

                        </div>

                        <div className="grid grid-cols-1 gap-4 mt-3">

                            {/*ITEM*/}
                            <div className="border py-2 px-5 flex flex-col rounded-lg ">

                                <div className="w-full flex justify-between items-center">
                                    <h5 className="font-medium">1234 BCDE</h5>

                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15"/>
                                        </svg>
                                    </button>

                                </div>

                                <div className="flex justify-between items-center">
                                    <span className="text-sm">Lamborghini Urus</span>
                                    <span className="text-sm text-gray-400">white</span>
                                </div>


                            </div>

                        </div>


                        <div className="mt-5 flex justify-between items-center">

                            <h4 className="font-semibold">Pet</h4>

                            <button onClick={() => handleOnNewPetButtonClick()}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2}
                                     stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                </svg>
                            </button>

                        </div>

                        <div className="grid grid-cols-1 gap-4 mt-3">

                            {/*ITEM*/}
                            <div className="border py-2 px-5 flex flex-col rounded-lg ">

                                <div className="w-full flex justify-between items-center">
                                    <h5 className="font-medium">Kody</h5>

                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15"/>
                                        </svg>
                                    </button>

                                </div>

                                <div className="flex justify-between items-center">
                                    <span className="text-sm">dog</span>
                                    <span className="text-sm text-gray-400">female</span>
                                </div>


                            </div>

                        </div>


                    </div>
                </div>

            </div>

            {/*DESKTOP*/}
            <div className="hidden lg:block bg-[#00113D] w-full min-h-screen">

                <img src={BgLinesSVG} className="w-full h-auto absolute -top-[16rem]" alt=""/>

                <div className="2xl:container mx-auto">
                    <div className="relative">

                        <h1 className="my-5 text-white text-2xl font-semibold">Home</h1>


                        <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 mx-auto pb-10 px-10">

                            <div className="p-5 bg-white rounded-lg">
                                <div className='flex justify-between items-center'>
                                    <h4 className="font-semibold text-[#3C3C43]">Family Members</h4>
                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                    </button>
                                </div>

                                <div className="p-5">
                                    <img src={FamilyMemberSVG}
                                         className="w-full h-[18rem]"
                                         alt="Family member illustration"/>
                                </div>

                                <div className="grid grid-cols-1 gap-4">
                                    <div className="p-3 text-start border rounded-lg">
                                        <div className="flex justify-between items-center">
                                            <h5 className="font-semibold text-sm">Frank Thompson</h5>
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <span className="text-sm">65 year old</span>
                                    </div>

                                    <div className="p-3 text-start border rounded-lg">
                                        <div className="flex justify-between items-center">
                                            <h5 className="font-semibold text-sm">Frank Thompson</h5>
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <span className="text-sm">65 year old</span>
                                    </div>

                                    <button className="border py-2.5 w-full rounded-lg text-sm font-semibold">See all member
                                        (20)
                                    </button>
                                </div>

                            </div>

                            <div className="p-5 bg-white rounded-lg">
                                <div className='flex justify-between items-center'>
                                    <h4 className="font-semibold text-[#3C3C43]">Access Code</h4>
                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                    </button>
                                </div>

                                <div className="p-5">
                                    <img src={AccessCodeSVG}
                                         className="w-full h-[18rem]"
                                         alt="Access code illustration"/>
                                </div>

                                <div className="grid grid-cols-1 gap-4">

                                    <div className="p-3 text-start border rounded-lg">
                                        <div className="flex justify-between items-center">
                                            <h5 className="font-semibold text-sm">Frank Thompson</h5>
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <span className="text-sm">family</span>
                                            <span className="text-sm text-gray-600">temporary</span>
                                        </div>
                                    </div>

                                    <div className="p-3 text-start border rounded-lg">
                                        <div className="flex justify-between items-center">
                                            <h5 className="font-semibold text-sm">Frank Thompson</h5>
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <span className="text-sm">family</span>
                                            <span className="text-sm text-gray-600">temporary</span>
                                        </div>
                                    </div>


                                    <button className="border py-2.5 w-full rounded-lg text-sm font-semibold">See all access
                                        codes (20)
                                    </button>
                                </div>

                            </div>

                            <div className="p-5 bg-white rounded-lg">
                                <div className='flex justify-between items-center'>
                                    <h4 className="font-semibold text-[#3C3C43]">Pet</h4>
                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                    </button>
                                </div>

                                <div className="p-5">
                                    <img src={PetsSVG}
                                         className="w-full h-[18rem]"
                                         alt="Access code illustration"/>
                                </div>

                                <div className="grid grid-cols-1 gap-4">

                                    <div className="p-3 text-start border rounded-lg">
                                        <div className="flex justify-between items-center">
                                            <h5 className="font-semibold text-sm">Kody</h5>
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <span className="text-sm">dog</span>
                                            <span className="text-sm text-gray-600">female</span>
                                        </div>
                                    </div>

                                    <div className="p-3 text-start border rounded-lg">
                                        <div className="flex justify-between items-center">
                                            <h5 className="font-semibold text-sm">Kody</h5>
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <span className="text-sm">dog</span>
                                            <span className="text-sm text-gray-600">female</span>
                                        </div>
                                    </div>

                                    <button className="border py-2.5 w-full rounded-lg text-sm font-semibold">See all pets
                                        (20)
                                    </button>

                                </div>

                            </div>

                            <div className="p-5 bg-white rounded-lg">

                                <div className='flex justify-between items-center'>

                                    <h4 className="font-semibold text-[#3C3C43]">Vehicle</h4>

                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                        </svg>
                                    </button>

                                </div>

                                <div className="p-5">
                                    <img src={VehiclesSVG}
                                         className="w-full h-[18rem]"
                                         alt="Access code illustration"/>
                                </div>

                                <div className="grid grid-cols-1 gap-4">

                                    <div className="p-3 text-start border rounded-lg">
                                        <div className="flex justify-between items-center">
                                            <h5 className="font-semibold text-sm">1234 BCDE</h5>
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <span className="text-sm">Ferrari  296 GTB</span>
                                            <span className="text-sm text-gray-600">red</span>
                                        </div>
                                    </div>

                                    <div className="p-3 text-start border rounded-lg">
                                        <div className="flex justify-between items-center">
                                            <h5 className="font-semibold text-sm">1234 BCDE</h5>
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <span className="text-sm">Ferrari  296 GTB</span>
                                            <span className="text-sm text-gray-600">red</span>
                                        </div>
                                    </div>

                                    <button className="border py-2.5 w-full rounded-lg text-sm font-semibold">See all
                                        vehicles (20)
                                    </button>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default MyHomePage;