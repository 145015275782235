import axios from "axios";
import {userRoles} from "../utils/ConstStrings";

export const fetchRoles = async () => {
    try {
        const response = await axios.get(userRoles);
        return response.data.rows;
    } catch (error) {
        throw new Error('Error fetching roles');
    }

}