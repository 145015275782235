import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import ItemAnnouncements from "./items/ItemAnnouncements";
import ItemAnnouncementsPressed from "./items/ItemAnnouncementsPressed";
import ItemHome from "./items/ItemHome";
import ItemHomePressed from "./items/ItemHomePressed";
import ItemMyCommunity from "./items/ItemMyCommunity";
import ItemMyCommunityPressed from "./items/ItemMyCommunityPressed";
import ItemMailPressed from "./items/ItemMailPressed";
import ItemMail from "./items/ItemMail";


import type { RootState } from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import { selectCurrentIndex } from '../../redux/slices/BottomBarMenuSlice'

function MobileBottomBar(props: any) {

    const menuIndex = useSelector((state: RootState) => state.bottomBarMenuIndex.value)
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const changeCurrentScreen = (index: number) => {
        dispatch(selectCurrentIndex(index))
        props.handleCurrentPage(index)
    }

    const handleNewAnnouncement = () => {
        navigate("/new-announcement")
    }

    return (
        <div className="lg:hidden fixed inset-x-0 z-40 bottom-0 w-full">


            <div className="absolute bottom-[5rem] fixed inset-x-0 z-40 px-5 py-3 flex justify-end items-end">

                <button onClick={() => handleNewAnnouncement() } className="h-14 w-14 rounded-full bg-[#1D47B1] flex items-center justify-center text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                </button>

            </div>

            <div className="bg-white border-t dark:bg-dark-secondary">

                <nav className="container mx-auto py-5">

                    <div className="flex flex-row justify-around items-center">


                        <button onClick={() => changeCurrentScreen(1)} className="relative h-10 w-10">
                            <div
                                className={menuIndex === 1 ? "absolute -top-5 w-10  border-t-4 border-t-amber-600 rounded-b-[4px]" : "absolute -top-6 w-10  border-t-4  border-t-transparent"}/>
                            {menuIndex === 1 ? <ItemAnnouncementsPressed/> : <ItemAnnouncements/>}
                        </button>


                        <button onClick={() => changeCurrentScreen(3)} className="h-10 w-10">
                            <div
                                className={menuIndex === 3 ? "absolute top-0 w-10  border-t-4 border-t-amber-600 rounded-b-[4px]" : "absolute -top-6 w-10  border-t-4  border-t-transparent"}/>
                            {menuIndex === 3 ? <ItemMyCommunityPressed/> : <ItemMyCommunity/>}
                        </button>


                        <button onClick={() => changeCurrentScreen(2)} className="relative h-10 w-10">
                            <div
                                className={menuIndex === 2 ? "absolute -top-5 w-10  border-t-4 border-t-amber-600 rounded-b-[4px]" : "absolute -top-6 w-10  border-t-4  border-t-transparent"}/>
                            {menuIndex === 2 ? <ItemHomePressed/> : <ItemHome/>}
                        </button>



                        <button onClick={() => changeCurrentScreen(4)} className="h-10 w-10">
                            <div
                                className={menuIndex === 4 ? "absolute top-0 w-10  border-t-4 border-t-amber-600 rounded-b-[4px]" : "absolute -top-6 w-10  border-t-4  border-t-transparent"}/>
                            {menuIndex === 4 ? <ItemMailPressed/> : <ItemMail/>}
                        </button>

                    </div>
                </nav>
            </div>
        </div>
    );
}

export default MobileBottomBar;