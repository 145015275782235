import React from 'react';

function CallToMeeting() {
    return (
        <div>

            {/*MOBILE*/}
            <div className="block lg:hidden px-5 py-5">

                <h1 className="text-start font-semibold text-2xl">Call to meeting</h1>

                <div className="mt-5 text-start">

                    <p><span className="font-semibold">Alfredo Hernandez</span> calls a meetzing on  <span className="font-semibold">22.2.2023</span> at <span className="font-semibold">2:30 pm</span> in <span className="font-semibold">the lobby of the building.</span></p>

                    <h5 className="my-5 font-semibold">Main reason</h5>
                    <p className="mt-2">Any reason nknzxcas aksdakd sjdajsdjl dnm cand jdjd sjndqjdj sdjnsdjasdjasdnjnsdkj.</p>

                    <h5 className="my-5 font-semibold">Order of the day</h5>

                    <ul className="list-decimal pl-5 flex flex-col space-y-3">
                        <li>asdajsndja asndjads asbdjansd jhsdhcb sdabjqhsd hgasdkas dkj.</li>
                        <li>asdajsndja asndjads asbdjansd jhsdhcb sdabjqhsd hgasd  kasdkj.</li>
                        <li>asdajsndja asndjads asbdjansd jhsdhcb sdabjqhsd hgasd kasdkj.</li>
                        <li>asdajsndja asndjads asbdjansd jhsdhcb sdabjqhsdhga sdkas dkj.</li>
                        <li>asdajsndja asndjads asbdjansd jhsdhcb sdabjqh  sdhgasdka sdkj.</li>
                    </ul>

                </div>
            </div>

            {/*DESKTOP*/}
            <div className="hidden lg:block w-1/2 2xl:container mx-auto px-5 py-5">

                <h1 className="text-start font-semibold text-2xl">Call to meeting</h1>

                <div className="mt-5 border rounded-lg p-5 text-start">

                    <p><span className="font-semibold">Alfredo Hernandez</span> calls a meetzing on  <span className="font-semibold">22.2.2023</span> at <span className="font-semibold">2:30 pm</span> in <span className="font-semibold">the lobby of the building.</span></p>

                    <h5 className="my-5 font-semibold">Main reason</h5>
                    <p className="mt-2">Any reason nknzxcas aksdakd sjdajsdjl dnm cand jdjd sjndqjdj sdjnsdjasdjasdnjnsdkj.</p>

                    <h5 className="my-5 font-semibold">Order of the day</h5>

                    <ul className="list-decimal px-5 flex flex-col space-y-3">
                        <li>asdajsndja asndjads asbdjansd jhsdhcb sdabjqhsdhgasdkasdkj.</li>
                        <li>asdajsndja asndjads asbdjansd jhsdhcb sdabjqhsdhgasdkasdkj.</li>
                        <li>asdajsndja asndjads asbdjansd jhsdhcb sdabjqhsdhgasdkasdkj.</li>
                        <li>asdajsndja asndjads asbdjansd jhsdhcb sdabjqhsdhgasdkasdkj.</li>
                        <li>asdajsndja asndjads asbdjansd jhsdhcb sdabjqhsdhgasdkasdkj.</li>
                    </ul>

                </div>


            </div>

        </div>
    );
}

export default CallToMeeting;