import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {createReactEditorJS} from 'react-editor-js'
import {EDITOR_JS_TOOLS} from '../../../../utils/editor-tools/editorTools';


function NewRules() {

    const navigate = useNavigate()

    const [editorData, setEditorData] = useState<any>();
    const editorCore = React.useRef(null)

    const ReactEditorJS = createReactEditorJS()

    //TODO: INITIALIZE EDITOR
    const handleInitialize = React.useCallback((instance: any) => {
        editorCore.current = instance

        const testBlocks = localStorage.getItem("test");
        const parsedData = JSON.parse(testBlocks || 'null'); // Manejo de valor nulo
        if (parsedData && !Array.isArray(parsedData)) {
            setEditorData(parsedData);
        }

    }, [])

    //TODO: SAVE CURRENT DATA FROM EDITOR
    const handleSave = React.useCallback(async () => {
        // @ts-ignore
        const savedData = await editorCore.current.save();
        localStorage.setItem("test", JSON.stringify(savedData))
    }, [])


    // TODO: LOAD DATA FROM API IN EDITOR
    const handleRender = React.useCallback(async () => {

        const data = localStorage.getItem("test")
        // @ts-ignore
        const savedData = await editorCore.current.render(JSON.parse(data));

    }, [])


    return (
        <div className="2xl:container">

            {/*MOBILE*/}
            <div className="px-5 mt-5">

                {/*TOOLBAR*/}
                <div className="flex justify-between items-center">
                    <button onClick={() => navigate(-1)}
                            className="font-medium text-[#A8A8A8]">Cancel
                    </button>
                    <h2 className="font-semibold">Rules</h2>
                    <button onClick={() => handleSave()} className="text-[#1D47B1] font-medium">Save</button>
                </div>

                {/*CONTENT*/}
                <div className="text-start mt-5">

                    <label>Write a new rules</label>

                    <div className="mt-2 w-full">

                        <ReactEditorJS onInitialize={handleInitialize}
                                       tools={EDITOR_JS_TOOLS}
                                       defaultValue={{
                                           time: 1635603431943,
                                           blocks: [
                                               {
                                                   id: "IpKh1dMyC6",
                                                   type: "paragraph",
                                                   data: {
                                                       text:
                                                           "We have been working on this project more than three years. Several large media projects help us to test and debug the Editor, to make it's core more stable. At the same time we significantly improved the API. Now, it can be used to create any plugin for any task. Hope you enjoy. 😏"
                                                   }
                                               },

                                           ]
                                       }}
                                       onReady={handleRender}
                                       onChange={(api, newData) => setEditorData(newData)}/>

                    </div>
                </div>

            </div>

        </div>
    );
}

export default NewRules;