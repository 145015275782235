import React from 'react';

function ItemAnnouncements() {
    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x={0}
            y={0}
            viewBox="0 0 36 36"
            xmlSpace="preserve"
        >
            <style>{".st2{fill:#1d47b1}"}</style>
            <path
                className="st2"
                d="M26.05 7H7.69c-.38 0-.69.31-.69.69v18.37C7 27.68 8.32 29 9.95 29h18.37c.38 0 .69-.31.69-.69V9.94A2.962 2.962 0 0 0 26.05 7zm1.57 20.62H9.95c-.87 0-1.57-.7-1.57-1.57V8.38h17.68c.87 0 1.57.7 1.57 1.57v17.67z"
            />
            <path
                className="st2"
                d="M14.69 11.04h-4.11c-.38 0-.69.31-.69.69v4.11c0 .38.31.69.69.69h4.11c.38 0 .69-.31.69-.69v-4.11c0-.39-.31-.69-.69-.69zm-.69 4.1h-2.73v-2.73H14v2.73zM14.69 19.44h-4.11c-.38 0-.69.31-.69.69v4.11c0 .38.31.69.69.69h4.11c.38 0 .69-.31.69-.69v-4.11c0-.38-.31-.69-.69-.69zm-.69 4.1h-2.73v-2.73H14v2.73zM25.39 11.83H18c-.38 0-.69.31-.69.69s.31.69.69.69h7.39c.38 0 .69-.31.69-.69s-.31-.69-.69-.69zM18 15.37h3.99c.38 0 .69-.31.69-.69 0-.38-.31-.69-.69-.69H18c-.38 0-.69.31-.69.69 0 .38.31.69.69.69zM25.39 19.44h-7.33c-.38 0-.69.31-.69.69 0 .38.31.69.69.69h7.33c.38 0 .69-.31.69-.69 0-.38-.31-.69-.69-.69zM22.02 21.49H18c-.38 0-.69.31-.69.69s.31.69.69.69h4.02c.38 0 .69-.31.69-.69s-.31-.69-.69-.69z"
            />
        </svg>
    );
}

export default ItemAnnouncements;