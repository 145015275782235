import React, {useEffect, useState} from 'react';
import BackArrow from "../../../../components/svg/backArrow";
import Slider from "react-slick";
import MeetingTemplateItem from "../../../../components/meetings/MeetingTemplateItem";
import DocumentItemPlaceholder from "../../../../components/document/DocumentItemPlaceholder";
import {DocumentsType} from "../../../../types/DocumentsType";
import DocumentItem from "../../../../components/document/DocumentItem";
import {formatDocumentDate} from "../../../../utils/TextUtils";
import {useLocation, useNavigate} from "react-router-dom";
import {getDocumentsByMonth} from "../../../../api/budgetController";
import {CgSpinner} from "react-icons/cg";

function BudgetMonthDocuments() {

    const navigate = useNavigate()
    const location = useLocation()

    const [isLoading,setIsLoading] = useState<boolean>(false)
    const [isLoadingMore,setIsLoadingMore] = useState<boolean>(false)

    const [currentPage,setCurrentPage] = useState<number>(1)

    const [documentsCount,setDocumentsCount] = useState<number>(0)
    const [documents,setDocuments] = useState<DocumentsType[]>([])

    useEffect(() => {
        getMonthDocuments()
    }, [])


    useEffect(() => {

        function handleScrollEvent() {

            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {

                if (documents.length < documentsCount  - 1 && !isLoadingMore) {
                    setCurrentPage(currentPage + 1);
                    getNextPage(currentPage + 1)
                } else {
                    // console.log("No more pages to load")
                }
            }

        }

        window.addEventListener('scroll', handleScrollEvent)

        return () => {
            window.removeEventListener('scroll', handleScrollEvent);
        }
    }, [documents])

    const getMonthDocuments = async () => {

        const month = location.state.monthName
        setIsLoading(true)

        try {

            const res = await getDocumentsByMonth(month,1)

            setIsLoading(false)

            setDocuments(res.dataJSON.rows)
            setDocumentsCount(res.dataJSON.count)

        } catch (e: any) {
            setIsLoading(false)
            console.error(e)
        }
    }

    const getNextPage = async (page:number) =>{

        const month = location.state.monthName

        setIsLoadingMore(true)
        try {

            const res = await getDocumentsByMonth(month, page)

            setIsLoadingMore(false)
            setDocuments(documents.concat(res.dataJSON.rows))

        } catch (e: any) {
            setIsLoadingMore(false)
            console.error(e)
        }
    }

    const handleBack = () =>{
        navigate('/budget')
    }


    return (
        <div className="2xl:container w-full mx-auto pb-5 px-5 lg:px-10">

            <div className="bg-white h-14 flex flex-row justify-between items-center">

                <button onClick={() => handleBack() } className="text-[#1D47B1] block lg:hidden">
                    <BackArrow/>
                </button>

                <div className='flex flex-row'>
                    <button onClick={() => handleBack() }
                            className="hidden border rounded-full p-2 h-8 w-8 mr-2 lg:flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
                        </svg>
                    </button>

                    <h2 className="font-semibold lg:text-2xl text-[#1D47B1]">Budget</h2>

                </div>

            </div>

            <div className="flex flex-col items-start justify-start">
                <h4 className="font-semibold text-lg">Saved documents</h4>
                <h5 className=" font-semibold text-sm text-[#3C3C43]">{location.state.monthName}</h5>
            </div>

            <div className="mt-2 grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4">

                {isLoading ?
                    <>

                    <DocumentItemPlaceholder/>

                    <DocumentItemPlaceholder/>

                    <DocumentItemPlaceholder/>

                    <DocumentItemPlaceholder/>

                    <DocumentItemPlaceholder/>

                    </>
                    :
                    <>
                    {
                        documents.map((documentItem:DocumentsType,index:number)=>{
                            return(
                                <DocumentItem
                                    key={parseInt(documentItem.id_data)}
                                    id={parseInt(documentItem.id_data)}
                                    title={documentItem.title}
                                    created_at={formatDocumentDate(documentItem.createdAt)}
                                    route={"/budget/"}
                                    monthName={location.state.monthName}
                                />
                            )
                        })
                    }
                    </>
                }


            </div>

            {
                isLoadingMore &&
                <div className="w-full mt-10 flex justify-center items-center">
                    <CgSpinner className="animate-spin w-6 h-6 text-dark-blue"/>
                </div>
            }


        </div>
    );
}

export default BudgetMonthDocuments;