import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import logo from "../../assets/icons/logo_colored.svg";
import Facebook from "../../components/svg/facebook";
import Google from "../../components/svg/google";
import Linkedin from "../../components/svg/linkedin";
import {fetchRoles} from "../../api/userRolesController";
import {UserRolType} from "../../types/UserRolType";
import {validateEmail} from "../../utils/TextUtils";
import {CgSpinner} from "react-icons/cg";
import {registerUserWithData} from "../../api/userController";
import AuthContextProvider,{ UserContextType} from "../../providers/AuthContextProvider";

const ErrorEnum = {
    Name: 'name',
    LastName: 'lastname',
    Email: 'email',
    Password: 'password',
    Role: 'role'
};

interface ErrorType {
    type: string,
    message: string,
}

function RegisterPage() {

    const navigate = useNavigate();
    const location = useLocation();

    const [showPassword, setShowPassword] = useState<boolean>(false)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isRegisteringUser, setRegisteringUser] = useState<boolean>(false)

    const [roles, setRoles] = useState<UserRolType[]>([])

    const [name, setName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [role, setRole] = useState<string>('')

    const [errorMessage, setErrorMessage] = useState<ErrorType | null>(null);

    const {communityID} = location.state;

    const {auth} = useContext(AuthContextProvider) as UserContextType

    useEffect(()=>{
        if (!auth) return

        if (auth.isAuth)
            navigate('/main/announcements')
    },[])

    useEffect(() => {
        getDefaultRoles()
    }, [])

    useEffect(() => {
        setErrorMessage(null)
    }, [name, lastName, email, password, role])

    const getDefaultRoles = async () => {

        setIsLoading(true)
        const resRoles = await fetchRoles()

        if (resRoles.length <= 0) {
            throw Error("Don't have roles on db")
        }

        setRoles(resRoles)
        setIsLoading(false)
    }

    const handelSignUpUser = async (e: any) => {
        e.preventDefault()

        if (name.trim().length < 3) {
            setErrorMessage({type: ErrorEnum.Name, message: 'Name is required'});
            return
        }

        if (lastName.trim().length < 3) {
            setErrorMessage({type: ErrorEnum.LastName, message: 'Last name is required'});
            return
        }

        if (email.trim().length === 0) {
            setErrorMessage({type: ErrorEnum.Email, message: 'Email is required'});
            return
        }

        if (!validateEmail(email)) {
            setErrorMessage({type: ErrorEnum.Email, message: 'Email is invalid'});
            return
        }


        if (password.trim().length < 8) {
            setErrorMessage({type: ErrorEnum.Password, message: 'Password must be at least 8 characters'});
            return
        }

        if (role.trim() === '') {
            setErrorMessage({type: ErrorEnum.Role, message: 'You must select your ROLE in the ManageCommunity'});
            return
        }

        setRegisteringUser(true)

        try {
            const res = await registerUserWithData(name, lastName, email, password, role, communityID)

            if (res.msg === "Success user register"){
                navigate('/signin');
            }

            setRegisteringUser(false)
        } catch (e: any) {
            console.error(e)
            setRegisteringUser(false)
        }

    }

    if (isLoading) {
        return (
            <div className="w-full lg:w-1/3 2xl:container mx-auto">

                <div className="flex justify-center items-center pt-10">
                    <img src={logo} className="w-44" alt="OwnersTally"/>
                </div>

                <div className="w-full h-screen flex justify-center items-center">
                    <CgSpinner className="animate-spin w-6 h-6 text-dark-blue"/>
                </div>

            </div>
        )
    }

    return (
        <div className="w-full lg:w-1/3 2xl:container mx-auto">

            <div className="flex justify-center items-center pt-10">
                <img src={logo} className="w-44" alt="OwnersTally"/>
            </div>

            <div className=" pt-10">

                <section className="hidden">

                    <p className="text-[#353535]/50">SIGN UP WITH</p>

                    <div className="flex flex-row justify-center pt-3 items-center space-x-5">

                        <button onClick={() => alert("Google Login BTN")}>
                            <Google/>
                        </button>

                        <button onClick={() => alert("Facebook Login BTN")}>
                            <Facebook/>
                        </button>

                        <button onClick={() => alert("Linkedin Login BTN")}>
                            <Linkedin/>
                        </button>

                    </div>

                    <p className="pt-10 text-[#353535]/50">OR TYPE YOUR</p>

                </section>

                <div className="px-10 py-5">

                    <form className="flex flex-col space-y-5" onSubmit={(e: any) => handelSignUpUser(e)}>

                        <div>
                            <label className="sr-only">First Name</label>
                            <input type="text"
                                   className={errorMessage?.type == ErrorEnum.Name ? "border border-red-600 w-full px-5 py-2.5 rounded-lg" : "border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"}
                                   value={name}
                                   disabled={isLoading || isRegisteringUser}
                                   onChange={(e: any) => setName(e.target.value)}
                                   placeholder="First name"/>

                            {errorMessage?.type === ErrorEnum.Name && (
                                <div className="text-start w-full my-1">
                                    <span className="text-red-600 text-sm">* {errorMessage.message}</span>
                                </div>
                            )}
                        </div>

                        <div>
                            <label className="sr-only">Lastname</label>
                            <input type="text"
                                   className={errorMessage?.type == ErrorEnum.LastName ? "border border-red-600 w-full px-5 py-2.5 rounded-lg" : "border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"}
                                   value={lastName}
                                   disabled={isLoading || isRegisteringUser}
                                   onChange={(e: any) => setLastName(e.target.value)}
                                   placeholder="Lastname"/>

                            {errorMessage?.type === ErrorEnum.LastName && (
                                <div className="text-start w-full my-1">
                                    <span className="text-red-600 text-sm">* {errorMessage.message}</span>
                                </div>
                            )}

                        </div>

                        <div>
                            <label className="sr-only">Email</label>
                            <input type="email"
                                   className={errorMessage?.type == ErrorEnum.Email ? "border border-red-600 w-full px-5 py-2.5 rounded-lg" : "border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"}
                                   value={email}
                                   disabled={isLoading || isRegisteringUser}
                                   onChange={(e: any) => setEmail(e.target.value)}
                                   placeholder="Email Address"/>

                            {errorMessage?.type === ErrorEnum.Email && (
                                <div className="text-start w-full my-1">
                                    <span className="text-red-600 text-sm">* {errorMessage.message}</span>
                                </div>
                            )}

                        </div>


                        <div className="relative">
                            <label className="sr-only">Password</label>
                            <div>
                                <input placeholder="Password" type={`${showPassword ? 'text' : 'password'}`}
                                       name="password"
                                       value={password}
                                       disabled={isLoading || isRegisteringUser}
                                       onChange={(e: any) => setPassword(e.target.value)}
                                       className={errorMessage?.type == ErrorEnum.Password ? "border border-red-600 w-full px-5 py-2.5 rounded-lg" : "border border-[#EAEDF5] w-full px-5 py-2.5 rounded-lg"}
                                       required/>



                            </div>

                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center rounded-full p-2.5">

                                <button onClick={(e: any) => {
                                    e.preventDefault();
                                    setShowPassword(!showPassword)
                                }}
                                        className={`${showPassword ? 'hidden' : 'block'}`}>
                                    <svg className="h-6 w-6 text-dark-blue"
                                         fill="none"
                                         viewBox="0 0 23.14 14.52" xmlns="http://www.w3.org/2000/svg">
                                        <g fill="none" stroke="#1d47b1" strokeMiterlimit="10" strokeWidth="1.5">
                                            <path d="m.63 6.74a13 13 0 0 1 21.88 0"/>
                                            <circle cx="11.57" cy="9.68" r="4.1"/>
                                        </g>
                                    </svg>
                                </button>


                                <button onClick={(e: any) => {
                                    e.preventDefault();
                                    setShowPassword(!showPassword)
                                }}
                                        className={`${showPassword ? 'block' : 'hidden'}`}>
                                    <svg viewBox="0 0 23.14 18.32" xmlns="http://www.w3.org/2000/svg"
                                         className="h-6 w-6 text-dark-blue">
                                        <g fill="none" stroke="#1d47b1" strokeMiterlimit="10" strokeWidth="1.5">
                                            <path d="m.63 7.68a13 13 0 0 1 21.88 0"/>
                                            <circle cx="11.57" cy="10.62" r="4.1"/>
                                            <path d="m4.06 17.79 17.26-17.26"/>
                                        </g>
                                    </svg>
                                </button>

                            </div>
                        </div>

                        {errorMessage?.type === ErrorEnum.Password && (
                            <div className="text-start w-full my-1">
                                <span className="text-red-600 text-sm">* {errorMessage.message}</span>
                            </div>
                        )}

                        <div className="w-full">

                            <label className="sr-only">HOA Position</label>

                            <div className="w-full relative">

                                <select
                                    className={errorMessage?.type == ErrorEnum.Role ? "border border-red-600 w-full px-5 py-2.5 rounded-lg appearance-none" : "w-full border border-[#EAEDF5] px-5 py-2.5 rounded-lg appearance-none"}
                                    onChange={(e: any) => setRole(e.target.value)}
                                    value={role}
                                    required
                                    disabled={isLoading || isRegisteringUser}>

                                    <option defaultChecked={true} value={''}>HOA Position</option>

                                    {
                                        roles.map((rol: UserRolType, index: number) => {
                                            return (
                                                <option key={index} value={rol.id_rol!!}>{rol.description}</option>
                                            );
                                        })
                                    }
                                </select>

                                <div
                                    className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20">
                                        <path
                                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                    </svg>
                                </div>

                            </div>

                            {errorMessage?.type === ErrorEnum.Role && (
                                <div className="text-start w-full my-1">
                                    <span className="text-red-600 text-sm">* {errorMessage.message}</span>
                                </div>
                            )}

                        </div>

                        <button type="submit" disabled={isLoading || isRegisteringUser}
                                className={isLoading || isRegisteringUser ? "border w-full mt-5 py-2.5 rounded-full bg-[#1D47B1]/50 cursor-not-allowed text-white font-semibold" : "border w-full mt-5 py-2.5 rounded-full bg-[#1D47B1] text-white font-semibold"}>
                            {isLoading || isRegisteringUser ?
                                <div className="text-center flex justify-center items-center"><CgSpinner
                                    className="animate-spin w-6 h-6 text-white"/></div> :
                                <span>NEXT</span>}
                        </button>

                    </form>

                </div>

            </div>

        </div>
    );
}

export default RegisterPage;