import React from 'react';

function ItemAnnouncements() {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 36"
        >
            <defs>
                <style>{".cls-1{fill:#a8a8a8}"}</style>
            </defs>
            <path
                className="cls-1"
                d="M26.05 7H7.69c-.38 0-.69.31-.69.69v18.36C7 27.67 8.32 29 9.95 29h18.36c.38 0 .69-.31.69-.69V9.94c0-1.62-1.32-2.95-2.95-2.95Zm1.57 20.62H9.95c-.87 0-1.57-.7-1.57-1.57V8.38h17.67c.87 0 1.57.7 1.57 1.57v17.67Z"
            />
            <path
                className="cls-1"
                d="M14.69 11.04h-4.11c-.38 0-.69.31-.69.69v4.11c0 .38.31.69.69.69h4.11c.38 0 .69-.31.69-.69v-4.11c0-.38-.31-.69-.69-.69ZM14 15.15h-2.73v-2.73H14v2.73ZM14.69 19.44h-4.11c-.38 0-.69.31-.69.69v4.11c0 .38.31.69.69.69h4.11c.38 0 .69-.31.69-.69v-4.11c0-.38-.31-.69-.69-.69ZM14 23.55h-2.73v-2.73H14v2.73ZM25.39 11.83H18c-.38 0-.69.31-.69.69s.31.69.69.69h7.39c.38 0 .69-.31.69-.69s-.31-.69-.69-.69ZM18 15.37h3.99c.38 0 .69-.31.69-.69s-.31-.69-.69-.69H18c-.38 0-.69.31-.69.69s.31.69.69.69ZM25.39 19.44h-7.33c-.38 0-.69.31-.69.69s.31.69.69.69h7.33c.38 0 .69-.31.69-.69s-.31-.69-.69-.69ZM22.02 21.49H18c-.38 0-.69.31-.69.69s.31.69.69.69h4.02c.38 0 .69-.31.69-.69s-.31-.69-.69-.69Z"
            />
        </svg>
    );
}

export default ItemAnnouncements;