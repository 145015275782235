import React from 'react';
import EditProfilePlaceholder from "../../../components/edit-profile/EditProfilePlaceholder";
import EditProfileItem from "../../../components/edit-profile/EditProfileItem";
import {useNavigate} from "react-router-dom";

function EditProfile() {

    const navigate = useNavigate()

    const handleNavigateBack = () => {
        navigate(-1)
    }

    const handleNavigateToEditAvatar = () => {
        navigate("/update-avatar")
    }

    return (

        <div className="mx-auto 2xl:container">
            <div className="lg:pt-24 w-full lg:w-1/2 mx-auto ">

                <div className="bg-white min-h-screen lg:h-auto lg:border rounded-lg">

                   <div className="hidden lg:block">

                       <div className="hidden lg:flex justify-between items-center bg-white  rounded-t-lg pt-5 pb-3 px-10">

                           <h2 className="text-start text-[#3C3C43] font-semibold text-2xl">Edit Profile</h2>

                           <div className="flex space-x-5 justify-center items-center flex-row">
                               <button onClick={() => navigate(-1)} className="py-2.5 uppercase font-semibold text-[#A8A8A8] text-sm">Cancel</button>
                               <button className="py-2.5 text-[#1D47B1] font-semibold uppercase text-sm">Save</button>
                           </div>

                       </div>

                       <div className="h-0.5 bg-gray-200 flex-grow mx-10"/>

                       <div className="flex flex-row px-10 pt-8">

                           <div className="w-1/2">

                              <button onClick={ ()=> handleNavigateToEditAvatar() }> <img src="https://i.pravatar.cc/150?img=68"
                                            className="w-44 h-44 rounded-full "
                                            alt="asd"/>
                              </button>

                           </div>

                           <div className="w-full pl-5 grid grid-cols-1 gap-y-3 text-start">

                               <div className="flex flex-col space-y-2">
                                   <label className="text-[#A8A8A8] text-sm uppercase">Full name</label>

                                   <input type="text"
                                          className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                          placeholder="Full name"/>
                               </div>

                               <div className="flex flex-col space-y-2">
                                   <label className="text-[#A8A8A8] text-sm uppercase">House number</label>

                                   <input type="text"
                                          className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                          placeholder="House number"/>
                               </div>


                               <div className="flex flex-col space-y-2">
                                   <label className="text-[#A8A8A8] text-sm uppercase">E-mail</label>

                                   <input type="email"
                                          className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                          placeholder="Email"/>
                               </div>

                              <div className="flex flex-col space-y-2">
                                  <label className="text-[#A8A8A8] text-sm uppercase">Cellphone number</label>

                                  <input type="tel"
                                         className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                         placeholder="Cellphone number"/>
                              </div>


                               <div className="flex flex-col space-y-2">
                                   <label className="text-[#A8A8A8] text-sm uppercase">Category</label>

                                   <select id="category"
                                           className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg px-3">
                                       <option defaultChecked>None</option>
                                       <option value="OWNER">Owner</option>
                                       <option value="RESIDENT">Resident</option>
                                       <option value="NON_RESIDENT">Non-resident Owner</option>
                                   </select>
                               </div>

                               <div className="flex flex-col space-y-2">
                                   <label className="text-[#A8A8A8] text-sm uppercase">HOA Position</label>

                                   <select id="hoa_position" className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg px-3">
                                       <option defaultChecked>None</option>
                                       <option value="PRESIDENT">HOA President</option>
                                       <option value="VICE_PRESIDENT">HOA Vice President</option>
                                       <option value="TREASURER">HOA Treasurer</option>
                                       <option value="GENERAL_MANAGER">HOA General Manager</option>
                                   </select>
                               </div>

                               <div className="pt-5 w-full">

                                   <button onClick={() => navigate("/update-password")} className="bg-[#1D47B1] rounded-full py-2.5 text-white px-5 w-full flex justify-between items-center flex-row">

                                       <span className="uppercase text-sm">Change password</span>

                                       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                           <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                       </svg>

                                   </button>

                                   <div className="mt-10 h-0.5 bg-gray-200 w-full px-10"/>

                               </div>

                           </div>

                       </div>


                   </div>

                    <div className="block lg:hidden px-5">

                        <div className="bg-white flex flex-col justify-center items-center py-5">

                            <picture>
                                <img src="https://i.pravatar.cc/150?img=68" className="rounded-full w-32 h-32" alt=""/>
                            </picture>

                            <button onClick={()=> handleNavigateToEditAvatar()} className="text-[#1D47B1] mt-3">
                                Edit
                            </button>

                        </div>

                        <div className="w-full grid grid-cols-1 gap-y-3 text-start">

                            <div className="flex flex-col space-y-2">
                                <label className="text-[#A8A8A8] text-sm uppercase">Full name</label>

                                <input type="text"
                                       className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                       placeholder="Full name"/>
                            </div>

                            <div className="flex flex-col space-y-2">
                                <label className="text-[#A8A8A8] text-sm uppercase">House number</label>

                                <input type="text"
                                       className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                       placeholder="House number"/>
                            </div>


                            <div className="flex flex-col  space-y-2">
                                <label className="text-[#A8A8A8] text-sm uppercase">E-mail</label>

                                <input type="email"
                                       className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                       placeholder="Email"/>
                            </div>

                            <div className="flex flex-col space-y-2">
                                <label className="text-[#A8A8A8] text-sm uppercase">Cellphone number</label>

                                <input type="tel"
                                       className=" border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg"
                                       placeholder="Cellphone number"/>
                            </div>

                            <div className="flex flex-col space-y-2">
                                <label className="text-[#A8A8A8] text-sm uppercase">Category</label>

                                <select id="category"
                                        className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg px-3">
                                    <option defaultChecked>None</option>
                                    <option value="OWNER">Owner</option>
                                    <option value="RESIDENT">Resident</option>
                                    <option value="NON_RESIDENT">Non-resident Owner</option>
                                </select>
                            </div>

                            <div className="flex flex-col space-y-2">
                                <label className="text-[#A8A8A8] text-sm uppercase">HOA Position</label>

                                <select id="hoa_position" className="border border-[#E0E6F8] bg-white w-full px-5 py-2.5 rounded-lg px-3">
                                    <option defaultChecked>None</option>
                                    <option value="PRESIDENT">HOA President</option>
                                    <option value="VICE_PRESIDENT">HOA Vice President</option>
                                    <option value="TREASURER">HOA Treasurer</option>
                                    <option value="GENERAL_MANAGER">HOA General Manager</option>
                                </select>
                            </div>

                            <div className="pt-5 w-full">

                                <button onClick={() => navigate("/update-password")} className="bg-[#1D47B1] rounded-full py-2.5 text-white px-5 w-full flex justify-between items-center flex-row">

                                    <span className="uppercase text-sm">Change password</span>

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                    </svg>

                                </button>

                                <div className="mt-10 h-0.5 bg-gray-200 w-full px-10"/>

                                <div className="py-10 grid grid-cols-2 gap-x-5">
                                    <button onClick={() => navigate(-1)} className="bg-[#A8A8A8] rounded-full py-2.5 text-white px-10 uppercase text-sm">Cancel</button>
                                    <button className="bg-[#1D47B1] rounded-full py-2.5 text-white px-10 uppercase text-sm">Save</button>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>
    );
}

export default EditProfile;