import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import {store} from './redux/store'
import {Provider} from 'react-redux'
import {AuthContextProvider} from "./providers/AuthContextProvider";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient()

root.render(
    <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
            <Provider store={store}>
                <App/>
            </Provider>
        </AuthContextProvider>
    </QueryClientProvider>
);
