import axios from "axios";
import {createUser} from "../utils/ConstStrings";

export const registerUserWithData = async (name: string, lastName: string, email: string, password: string, role: string, communityID: number) => {

    const userRegisterData = {
        "firstname": name,
        "lastname": lastName,
        "password": password,
        "rol_id": role,
        "email": email,
        "id_community": communityID,
    }

    try {

        const res = await axios.post(createUser, userRegisterData)
        return res.data

    } catch (e: any) {
        throw Error(e.message)
    }
}